import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router";
import { 
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../Navigation/Navbar';
import Footer from '../Navigation/Footer';
import AppleButton from '../Assets/app-store.png';
import GooglePlay from '../Assets/playstore.png';
import WindowsStore from '../Assets/windows-store.png';
import '../../App.css';
import { withTheme } from '../Theme/Theme';

const useStyles = makeStyles((theme) => ({
    heading: {
            fontSize: '36px',
            fontStyle: 'normal',
            lineHeight: '44px',
            fontFamily: 'Jost, sans-serif',
            color: '#ffffff',
    },
    paragraphspace: {
        lineHeight: '1.295',
        textAlign: 'justify',
        marginTop: '0pt',
        marginBottom: '6pt',
    },
    title:{
        fontSize: '13pt',
        fontFamily: 'Century Gothic, sans-serif',
        color: '#ffffff',
        backgroundColor: 'transparent',
        fontWeight: 700,
        fontStyle: 'normal',
        fontVariant: 'normal',
        textDecoration: 'none',
        verticalAlign: 'baseline',
        whiteSpace: 'pre',
        whiteSpace: 'pre-wrap',
    },
    element: {
        fontSize: '11pt',
        fontFamily: 'Century Gothic, sans-serif',
        color: '#ffffff',
        backgroundColor: 'transparent',
        fontWeight: 400,
        fontStyle: 'normal',
        fontVariant: 'normal',
        textDecoration: 'none',
        verticalAlign: 'baseline',
        whiteSpace: 'pre',
        whiteSpace: 'pre-wrap',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
      },
    indent: {
        marginTop: '0',
        marginBottom: '0',
        paddingInlineStart: '48px',
    },
    list: {
        listStyleType: 'disc',
        fontSize: '11pt',
        fontFamily: 'Century Gothic, sans-serif',
        color: '#ffffff',
        backgroundColor: 'transparent',
        fontWeight: 400,
        fontStyle: 'normal',
        fontVariant: 'normal',
        textDecoration: 'none',
        verticalAlign: 'baseline',
        whiteSpace: 'pre',
        marginLeft: '36pt'
    },
    medium: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    },
    large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    },
    typograph: {
        color: "#fff"
    },
    cover: {
        objectFit: 'fill'
    }
  }));


function ContactUs() {
    const classes = useStyles();
  return (
      <>
    <div className='app'>
      <Navbar />
      <Container>
        <Box my={2}>
            <div>
            <h2 className={classes.heading}>
            Frequently Asked Questions - English
                </h2>
            </div>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
{"\n"}{"\n"}
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            How to change your Kallo password
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            If you find it difficult to sign-in to Kallo.ng, you can reset your password by your email or text message.  To reset, you will receive an OTP number through text message. In case you've added another phone number to your account, or you forget the email address or phone number you registered with, you may provide more information online to recover your account.
            </span>
            </p>

            <p className={classes.paragraphspace}>
            <span className={classes.title}>
{"\n"}{"\n"}
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            How to Sign-Up for Kallo.ng
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            Enjoy variety of uninterrupted movies, series, documentaries, and songs from Kallo.ng for your entertainment without a single advertisement.
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
                As a Kallo.ng subscriber, you have the options to pay for 7 days, 14 days or just pay once a month. You have the freedom to change your plan or cancel the subscription any time you may wish to.
            </span>
            </p>

            <p className={classes.paragraphspace}>
            <span className={classes.title}>
{"\n"}{"\n"}
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            SIGNING UP MADE EASY
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title} style={{textDecorationLine: 'underline'}}>
            Android
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            Download the kallo.ng app from Google Play store. Sign in with your google account or phone number. If you don’t have both, you can register with another email address. 
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
{"\n"}
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title} style={{textDecorationLine: 'underline'}}>
            iOS
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            Download the kallo.ng app from Apple Play store. Sign in with your existing account number and phone number. If you don’t have both, you can register with another email address.
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
{"\n"}
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title} style={{textDecorationLine: 'underline'}}>
            Website
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            To watch from your browser, visit www.Kallo.ng. log in or register and watch unlimited. You can watch the trailers from the website. Download the kallo.ng  App on your desktop to enjoy unlimited viewing. 
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
{"\n"}
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
{"\n"}
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            SUBSCRIPTION PACKAGE
            </span>
            </p>
            <ul className={classes.indent}>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                    7 Days Subscription Plan
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                14 Days Subscription Plan
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                30 Days Subscription Plan
                    </span>
                    </p>
                </li>
            </ul>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
{"\n"}
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            PAYMENT METHOD
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            We have provided payment options to make life easy for for you. You can use any of the following methods.
            </span>
            </p>
            <ul className={classes.indent}>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Using your transaction card (Debit/Credit card)
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Direct transfer to  Zenith Bank, Access Bank or using a payment gateway, Paystack. Follow the instructions carefully.
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                If you choose to pay using USSD, an OTP will be sent to your phone. Copy the number and type for confirmation.
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Enter your pin to confirm payment from your phone.
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Go back to your page and start enjoying your subscription
                    </span>
                    </p>
                </li>
            </ul>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
{"\n"}
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            CUSTOMER SERVICE
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            You can chat with our customer support team from Kallo.ng from the App or the website through the red bubble icon located from the bottom right of your screen. This is live chat/call is free. We can also be reached on Facebook, Instagram, LinkedIn and twitter viakallo.ng or WhatsApp number provided below. 
            </span>
            </p>
            <ul className={classes.indent}>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                You can also download movies from Kallo.ng and watch later 
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Direct transfer to  Zenith Bank, Access Bank or using a payment gateway, Paystack. Follow the instructions carefully.
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                If you choose to pay using USSD, an OTP will be sent to your phone. Copy the number and type for confirmation.
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Enter your pin to confirm payment from your phone.
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Go back to your page and start enjoying your subscription
                    </span>
                    </p>
                </li>
            </ul>



            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
                 
            </span>
            </p>

            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Download the Kallo.ng App
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            <a href='https://play.google.com/store/apps/details?id=com.ng.kallo' target="_blank" rel="noopener noreferrer">
                             <img
              //className={classes.title}
              src={AppleButton}
              alt="Kallo.ng Logo"
              style={{maxWidth: 120, height: 50, marginLeft: 2, objectFit: 'contain'}}
              href='/'
               />
               </a>
               <p className={classes.paragraphspace}>
            <span className={classes.title}>
{"\n"}
            </span>
            </p>
               <a href='https://play.google.com/store/apps/details?id=com.ng.kallo' target="_blank" rel="noopener noreferrer">
            <img
              //className={classes.title}
              src={GooglePlay}
              alt="Kallo.ng on Google Play"
              style={{maxWidth: 120, height: 50, marginLeft: -70, objectFit: 'contain'}}
              href='/'
               />
               </a>
               <p className={classes.paragraphspace}>
            <span className={classes.title}>
{"\n"}
            </span>
            </p>
               <a href='https://play.google.com/store/apps/details?id=com.ng.kallo' target="_blank" rel="noopener noreferrer">
            <img
              //className={classes.title}
              src={WindowsStore}
              alt="Kallo.ng on Windows Store"
              style={{maxWidth: 120, height: 50, marginLeft: -70, objectFit: 'contain'}}
              href='/'
               />
               </a>
            </span>
            </p>

            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
                 
            </span>
            </p>

            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
                You can also download movies from Kallo and watch later 
            </span>
            </p>

            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
                 
            </span>
            </p>

            <p className={classes.paragraphspace}> 
            <span className={classes.element}>

Live TV brings programs live from popular festivities, event and other programs
            </span>
            </p>

</Box>        
</Container>

        
         
         
         
        <footer>
        <Footer />
        </footer>
        </div>
        </>
  )
}

export default withTheme(ContactUs);