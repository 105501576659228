import React from 'react';
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { alpha, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import { Dialog,
  Paper,
 } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MusicVideoIcon from '@material-ui/icons/MusicVideo';
import TheatersRoundedIcon from '@material-ui/icons/TheatersRounded';
import TvIcon from '@material-ui/icons/Tv';
import LiveTvIcon from '@material-ui/icons/LiveTv';
import NoteIcon from '@material-ui/icons/Note';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SearchIcon from '@material-ui/icons/Search';
import AirplayIcon from '@material-ui/icons/Airplay';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import ShoppingBasketRoundedIcon from '@material-ui/icons/ShoppingBasketRounded';
import BorderColorRoundedIcon from '@material-ui/icons/BorderColorRounded';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
//import logo from "../Assets/tv_logo_white.png";
import logo from "../Assets/kallo.png";
import axios from '../../axios';
import Chat from '../Chat/chat';
import ChangePassword from '../../Sessions/ChangePassword';
import moment from 'moment';

import '../../App.css';
import ProfileUpdate from '../../Sessions/ProfileUpdate';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(12),
    right: theme.spacing(2),
    zIndex: 2,
  },
  grow: {
    flexGrow: 1,
    paddingRight: theme.spacing(2),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    color: 'white'
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '25ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

function ScrollTop(props) {
    const { children, window } = props;
    const classes = useStyles();
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 100
    });
  
    const handleClick = (event) => {
      const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
  
      if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    };
  
    return (
      <Zoom in={trigger}>
        <div onClick={handleClick} role="presentation" className={classes.root}>
          {children}
        </div>
      </Zoom>
    );
  }
  
  ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
  };

const API_KEY = "oczkk67p7pyykt9xlk1eytde";

//const fetchUrl = `/home_content_for_android?API-KEY=${API_KEY}`;

const fetchUrl = '/home_content';

export default function Navbar({props, getSearchValue, getGenreValue}) {
  const classes = useStyles();
  const history = useHistory();
  const [genreList, setGenreList] = React.useState([]);
  const [genreData, setGenreData] = React.useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElPage, setAnchorElPage] = React.useState(null);
  const [anchorElRelease, setAnchorElRelease] = React.useState(null);
  const [anchorElGenre, setAnchorElGenre] = React.useState(null);
  const [anchorElMusic, setAnchorElMusic] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState("");
  const [yearReleased, setYearReleased] = React.useState("");
  const year = (new Date('01-01-1970'));
  const yearRange = moment((new Date())).diff(year,'years');
  const years = Array.from(new Array(yearRange+1),( val, index) => index + year.getFullYear());

  const isMenuOpen = Boolean(anchorEl);
  const isMenuReleaseOpen = Boolean(anchorElRelease);
  const isMenuPageOpen = Boolean(anchorElPage);
  const isMenuGenreOpen = Boolean(anchorElGenre);
  const isMenuMusicOpen = Boolean(anchorElMusic);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [open, setOpen] = React.useState(false);
  const [openList, setOpenList] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    setOpenList(false);
  };


  React.useEffect(() => {
    //[] run once when the row loads and don't run again
    //[variable] run once when the row loads and everytime the variable changes 
    let axiosConfig = {
      headers: {
      'Content-Type': 'application/json',
      //"Authorization": `Bearer ${localStorage.getItem("token")}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": 'true',
      'Access-Control-Allow-Methods': '*'
      }
  };
    async function fetchData() {
        const request = await axios.get(fetchUrl, axiosConfig);
        console.log(request);
        setGenreList(request.data.all_genre);
        return request;
    } 
    fetchData();
  }, [])


  const handleSearch = () => {
    //pass searchvalue to parent Search component
    getSearchValue(searchValue);
    history.push('/search')
  }

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenProfile = () => {
    history.push('/user/profile')
  };

  const handleMySub = () => {
    history.push('/user/subscription')
  };

  const handleMyFavs = () => {
    history.push('/user/favorites')
  };
  
  const handleClassicMusic = () => {
    setAnchorElMusic(null);
    handleMobileMenuClose();
    history.push('/music/classical')
  };

  const handleContemporaryMusic = () => {
    setAnchorElMusic(null);
    handleMobileMenuClose();
    history.push('/music/contemporary')
  };

  const handleGeneralMusic = () => {
    setAnchorElMusic(null);
    handleMobileMenuClose();
    history.push('/music')
  };

  const handleTerms = () => {
    setAnchorElPage(null);
    handleMobileMenuClose();
    history.push('/pages/privacy-policy')
  };

  const handleFAQ = () => {
    setAnchorElPage(null);
    handleMobileMenuClose();
    history.push('/pages/faq')
  };
  const handleFAQHausa = () => {
    setAnchorElPage(null);
    handleMobileMenuClose();
    history.push('/pages/faq-hausa')
  };

  const handleContact = () => {
    setAnchorElPage(null);
    handleMobileMenuClose();
    history.push('/pages/contact-us')
  };

  const handleAbout = () => {
    setAnchorElPage(null);
    handleMobileMenuClose();
    history.push('/pages/about-us')
  };

  const handleUpdateProfile = () => {
    history.push('/user/profile-update')
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
  localStorage.clear();
  setAnchorEl(null);
  handleMobileMenuClose();
  history.push('/')
  //history.goBack()
  }
  
  const handleMobileMenuClose = () => {
      setMobileMoreAnchorEl(null);
    };
    
    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    
    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    
    const handleReleaseMenuOpen = (event) => {
      setAnchorElRelease(event.currentTarget);
    };

    const handleReleaseMenuClose = () => {
        setAnchorElRelease(null);
        handleMobileMenuClose();
  };

    const handlePageMenuOpen = (event) => {
      setAnchorElPage(event.currentTarget);
    };

    const handlePageMenuClose = () => {
        setAnchorElPage(null);
        handleMobileMenuClose();
  };

  const handleGenreMenuOpen = (event) => {
    setAnchorElGenre(event.currentTarget);
  };

  const handleGenreMenuClose = () => {
    setAnchorElGenre(null);
      handleMobileMenuClose();
};

  const handleGenreOption = () => {
    getGenreValue(genreData); // passed as navbar prop to MovieGenre component
    //history.push(`/movies/${genreData.slug}`);
    setAnchorElGenre(null);
      handleMobileMenuClose();
  };

  const handleMusicMenuOpen = (event) => {
    setAnchorElMusic(event.currentTarget);
  };

  const handleMusicMenuClose = () => {
    setAnchorElMusic(null);
      handleMobileMenuClose();
};


  const menuId = 'primary-search-account-menu';
  const renderMenu = (
      <>
    <Menu
    anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem 
      onClick={handleOpenProfile}
      >
      <AccountCircle />
       Profile</MenuItem>
      <MenuItem style={{color: '#555'}}onClick={handleMenuClose}> Wallet</MenuItem>
      <MenuItem onClick={handleMySub}> <AccountBalanceWalletIcon /> My Subscription</MenuItem>
      <MenuItem onClick={handleMyFavs}> <FavoriteBorderRoundedIcon /> My Favourite</MenuItem>
      <MenuItem onClick={handleMenuClose}> <ShoppingBasketRoundedIcon/> Wish List</MenuItem>
      <MenuItem onClick={() => {
        handleMenuClose();
      setOpenList(true)}
      }> <BorderColorRoundedIcon/> Update Profile</MenuItem>
      <MenuItem onClick={() => {
        handleMenuClose();
      setOpen(true)}
      }> <BorderColorRoundedIcon/> Change Password</MenuItem>
      <MenuItem onClick={handleLogout}> <ExitToAppRoundedIcon /> Logout</MenuItem>
    </Menu>
    {
        //***** Pages ***********/
    }
      <Menu
        anchorEl={anchorElPage}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuPageOpen}
        onClose={handlePageMenuClose}
      >
        <MenuItem onClick={handleAbout}>About Us</MenuItem>
        <MenuItem onClick={handleContact}>Contact Us</MenuItem>
        <MenuItem onClick={handleTerms}>Terms</MenuItem>
        <MenuItem onClick={handleFAQ}>FAQs</MenuItem>
        <MenuItem onClick={handleFAQHausa}> FAQ (Hausa)</MenuItem>
      </Menu>

      {
        //***** Music ***********/
    }
      <Menu
        anchorEl={anchorElMusic}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuMusicOpen}
        onClose={handleMusicMenuClose}
      >
        <MenuItem onClick={handleGeneralMusic}>General</MenuItem>
        <MenuItem onClick={handleClassicMusic}>Classic</MenuItem>
        <MenuItem onClick={handleContemporaryMusic}>Contemporary</MenuItem>
      </Menu>

        {
        //*****Handling Movie Genres ***********/
    }
        <Menu
          anchorEl={anchorElGenre}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          id={menuId}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMenuGenreOpen}
          onClose={handleGenreMenuClose}
        >
                {genreList?.map(option => {
                  return (
                    
                    <MenuItem key={option.value} value={option.name} 
                      onClick={() => {
                      setGenreData(option);
                      history.push(`/movies/${option.slug}`);
                      getGenreValue(option); // passed as navbar prop to MovieGenre component
                      handleGenreOption();
                      }}
                      >
                      {option.name}
                    </MenuItem>
                    
                  );
                })}
        </Menu>


        {
        //*****Handling Year of Release ***********/
    }
        <Menu
          anchorEl={anchorElRelease}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          id={menuId}
          keepMounted
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={isMenuReleaseOpen}
          onClose={handleReleaseMenuClose}
        >
                {(years?.reverse()).map((year, index) => {
                  return (
                    <MenuItem key={`year${index}`} value={year} onClick={() => {
                      setYearReleased(year);
                      history.push(`/release/${year}`)
                      handleReleaseMenuClose();
                      //handleGenreOption();
                      }} 
                      >
                      {year}
                    </MenuItem>
                  );
                })}
        </Menu>
        </>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleReleaseMenuOpen}>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <DateRangeIcon />
        </IconButton>
            Release
      </MenuItem>
      <MenuItem onClick={handleGenreMenuOpen}>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <TheatersRoundedIcon />
        </IconButton>
        Movies
      </MenuItem>
      <MenuItem onClick={handleMusicMenuOpen}>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <MusicVideoIcon />
        </IconButton>
        Music
      </MenuItem>
      {/* <Link to='/documentary' style={{textDecoration: 'none', color: 'inherit'}}>
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <AirplayIcon />
        </IconButton>
        Documentary
      </MenuItem>
      </Link> */}
      <Link to='/tv-series' style={{textDecoration: 'none', color: 'inherit'}}>
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <TvIcon />
        </IconButton>
        Series
      </MenuItem>
      </Link>
{/*       <Link 
      //to='/live-tv' 
      style={{textDecoration: 'none', color: 'inherit'}}>
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <LiveTvIcon />
        </IconButton>
        TV
      </MenuItem>
      </Link> */}
{/*       <Link to='/pages/contact-us' style={{textDecoration: 'none', color: 'inherit'}}>
      <MenuItem>
        <IconButton aria-label="show 4 new mails" color="inherit">
          <NoteIcon />
        </IconButton>
        Request
      </MenuItem>
      </Link> */}
      <MenuItem onClick={handlePageMenuOpen}>
        <IconButton aria-label="show 4 new mails" color="inherit">
        <NoteIcon />
        </IconButton>
        Pages
      </MenuItem>
      <Link to='/search' style={{textDecoration: 'none', color: 'inherit'}}>
      <MenuItem>
      <IconButton 
        aria-label="show 4 new mails" 
        color="inherit"
        >
          <SearchIcon />
          </IconButton>
          Search
      </MenuItem>
      </Link>
      { ("name" in localStorage) ? (

       <MenuItem onClick={handleProfileMenuOpen}>
       <IconButton aria-label="show 4 new mails" color="inherit">
       <AccountCircle />
        </IconButton>
        Profile
      </MenuItem>
      
      ): (
              <MenuItem>
              <IconButton 
                aria-label="show 4 new mails" 
                color="darkred"
                href='/sign-in'>
                <PersonAddIcon />
              </IconButton>
              Login || Shiga
            </MenuItem>
      )
}
    </Menu>
  );

  return (
    <div className='app'>
    <React.Fragment>
      <CssBaseline />
    
      <AppBar style={{
        //backgroundColor: "darkred", color:"white"
        backgroundColor: "white", color:"darkred"
        }}>
        <Toolbar >
          
        <img
          //    className={classes.title}
              src={logo}
              alt="Kallo.ng Logo"
              style={{maxWidth: 160, height: 50, marginLeft: 20, objectFit: 'contain'}}
              onClick={()=> history.push('/home')}
               />
          
{/*                <form >
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
           <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
//              inputProps={{ 'aria-label': 'search' }}
              onChange={(e) => {
                setSearchValue(e.target.value);
                getSearchValue(e.target.value);
//                history.push('/search')
                }}
                onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
//                  alert('enter pressed');
//                  ev.preventDefault();
                  history.push('/search');
                }
                  }}
            />
           </div>
          </form> */}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
          <IconButton onClick={handleReleaseMenuOpen}>
          <Typography style={{textDecoration: 'none', color: 'darkred', fontWeight: 'bolder'}} className={classes.title} variant="body1" noWrap>
            Release
          </Typography>
          </IconButton>
          <IconButton onClick={handleGenreMenuOpen}>
          <Typography style={{textDecoration: 'none', color: 'darkred', fontWeight: 'bolder'}} className={classes.title} variant="body1" noWrap>
            Movies
          </Typography>
          </IconButton>

          <IconButton onClick={handleMusicMenuOpen}>
          <Typography style={{textDecoration: 'none', color: 'darkred', fontWeight: 'bolder'}} className={classes.title} variant="body1" noWrap>
            Music
          </Typography>
          </IconButton>

{/*           <Link to='/documentary' style={{textDecoration: 'none', color: 'inherit'}}>
          <IconButton>
          <Typography style={{textDecoration: 'none', color: 'darkred'}} className={classes.title} variant="body1" noWrap>
            Documentary
          </Typography>
          </IconButton>
          </Link> */}
          <Link to='/tv-series' style={{textDecoration: 'none', color: 'inherit'}}>
          <IconButton>
          <Typography style={{textDecoration: 'none', color: 'darkred', fontWeight: 'bolder'}} className={classes.title} variant="body1" noWrap>
          Series
          </Typography>
          </IconButton>
          </Link>
{/*           <Link 
          //to='/live-tv' 
          style={{textDecoration: 'none', color: 'inherit'}}>
          <IconButton>
          <Typography style={{textDecoration: 'none', color: 'darkred'}} className={classes.title} variant="body1" noWrap>
            TV
          </Typography>
          </IconButton>
          </Link> */}
{/*           <Link to='/pages/contact-us' style={{textDecoration: 'none', color: 'inherit'}}>
          <IconButton>
          <Typography style={{textDecoration: 'none', color: 'darkred'}} className={classes.title} variant="body1" noWrap>
            Request
          </Typography>
          </IconButton>
          </Link> */}
          <IconButton onClick={handlePageMenuOpen} >
          <Typography style={{textDecoration: 'none', color: 'darkred', fontWeight: 'bolder'}} className={classes.title} variant="body1" noWrap>
            Pages
          </Typography>
          </IconButton>
          <Link to='/search' style={{textDecoration: 'none', color: 'inherit'}}>
          <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              //              onClick={handleProfileMenuOpen}
              color="inherit"
              >
          <SearchIcon />
          </IconButton>
                </Link>
          { ("name" in localStorage) ? (
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>): (          <IconButton 
            href='/sign-in'
            >
          <Typography className={classes.title} variant="body1" noWrap>
            Login || Shiga
          </Typography>
          </IconButton>)
}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    
    <Toolbar id="back-to-top-anchor" />
    <ScrollTop {...props}>
        <Fab style={{backgroundColor: "darkred", color:'#fff' }} size="large" aria-label="scroll back to top">
          <KeyboardArrowUpIcon fontSize='large'/>
        </Fab>
      </ScrollTop>
      { //("name" in localStorage) ? ( <Chat /> ) : null 
      }
    </React.Fragment>

    <div style={{backgroundColor: '#222'}} >
<Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        style={{borderRadius: "20px"}}
        PaperComponent={Paper}
//        style={{color: '#222'}}
      >

        <ChangePassword />
      </Dialog>
    </div>

    <div style={{backgroundColor: '#222'}} >
<Dialog
        fullWidth
        maxWidth='md'
        open={openList}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        style={{borderRadius: "20px"}}
        PaperComponent={Paper}
//        style={{color: '#222'}}
      >
        <ProfileUpdate />
      </Dialog>
    </div>
    </div>
  );
}