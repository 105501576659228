import React, { useEffect, useState } from 'react';
import logo from './components/Assets/tv_logo_white.png';
import MenuIcon from '@material-ui/icons/Menu';
import { 
    Button,
    ButtonGroup,
    Card,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    MenuItem,
    IconButton,
    InputAdornment,
    InputLabel,
    Select,
    Typography
    } from '@material-ui/core';
//import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from "@material-ui/core/styles";
import Login from './Sessions/Login.js';
import './Nav.css'

const styles = {
    root: {
      border: 0,
      borderRadius: 3,
      color: "white",
      height: 48,
      marginTop: '-.5rem',
    }
  };

function Nav({classes, ...props}) {
    const [show, handleShow] = useState(false);
    const [openRegister, setOpenRegister] = useState(false);
    const [openLogin, setOpenLogin] = useState(false);
    const { 
      getOpenRegister, getOpenLogin
    } = props;

    const handleCloseRegister = () => {
      setOpenRegister(false);
    };

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 100) {
                handleShow(true);
            } else handleShow(false);
        });
        return () => {
            window.removeEventListener("scroll");
        };

    }, [])

    
  return (
    <><div className="nav">
          <img
              className='nav__logo'
              src={logo}
              alt="Kallo.ng Logo"
              />
          {/*
            <MenuIcon
                className='nav__avatar'
                /> */}
{/*           <ButtonGroup variant="text" aria-label="text button group"
              className='nav__avatar'>
              <Button 
                //onClick={(e) => {setOpenRegister(true)}}
                className={classes.root}>
                  Register
              </Button>
              <Button className={classes.root}>
                  Sign In
              </Button>

          </ButtonGroup> */}
      </div>

      <Dialog
          open={openRegister}
          onClose={handleCloseRegister}
          aria-labelledby="form-dialog-title"
          //style={{backgroundColor: '#111'}}
          >
              <DialogContent>
                <Login />
{/* 
       *****  Register ******
        Fullname        
        Country
        Email
        Phone Number
        Password
        Repeat Password
*/}

              </DialogContent>
          </Dialog>
      </>
    )
}

export default withStyles(styles) (Nav);
