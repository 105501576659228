import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {InputAdornment, IconButton} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import { register } from "./RegistrationStyles";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

import {
  CircularProgress
} from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";
import CloseIcon from "@material-ui/icons/Close";
import { 
  exchange,
  decryptWithAES,
  encryptWithAES,
  home
} from '../requests';

//const exchange = 'http://api.kallo.ng:5000';
//const exchange = 'http://localhost:5000';
const useStyles = makeStyles(register);


export default function ChangePassword() {
    const classes = useStyles();

    const [password, setPassword] = React.useState('');
    const [newpassword, setNewPassword] = React.useState('');
    const [cnewpassword, setCNewPassword] = React.useState('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [showCPassword, setShowCPassword] = React.useState(false);
    const [message, setMessage] = React.useState(null);
    const [messageType, setMessageType] = React.useState('');
    const [openAlert, setOpenAlert] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    let [errors, setErrors] = React.useState({});
    let [isValid, setIsValid] = React.useState(true);

    const handleClickAlert = () => {
      setOpenAlert(true);
    };

    const handleCloseAlert = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenAlert(false);
      setCNewPassword('');
      setNewPassword('');
      setPassword('');
      setLoading(false);
    };
    
    const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
    };
  
  const handleClickShowCPassword = () => {
      setShowCPassword(!showCPassword);
    };
  
  const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

  const confirmPassword = () => {
  
      if (cnewpassword !== newpassword) 
      {
        setIsValid(false);
        errors["password"] = "Passwords don't match.";
//          toast.error("Passwords don't match.")
      }
      setErrors(errors);
  
      return isValid;
    }

      const handleChangePassword = (event) => {
        event.preventDefault();
        if (!loading) {
          setLoading(true);
        if(confirmPassword()){
        fetch(`${exchange}/api/v1/users/updatePassword`, {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${localStorage.getItem("token")}`,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": 'true',
            "Access-Control-Allow-Methods": "*"
                    },
          body: JSON.stringify({
            old_password: password,
            new_password: newpassword,
        }),
        redirect: 'follow'
        })
        .then(response => response.json())
        .then((data) => {
          if (data.message === "Password updated Successfully!")
          setMessage(data.message);
          setMessageType('success');
          handleClickAlert();
        })
      }
      else {
        //setMessage("Request Not Successful");
        setMessageType('warning');
        handleClickAlert();
      }
    }
      }; 

    return (
      <><Card style={{ width: 'auto', overflow: 'auto', padding: '10px', paddingBottom: '10px' }}>
        <CardHeader
          title="Change Password"
          subheader='' />

        <CardContent>
          <form
            className={classes.form}
            noValidate
            autoComplete="off"
            align='center'
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="outlined-basic"
                  label="Password"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="outlined-basic"
                  label="New Password"
                  variant="outlined"
                  value={newpassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  type={showCPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowCPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showCPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TextField
                  id="outlined-basic"
                  label="Confirm New Password"
                  variant="outlined"
                  value={cnewpassword}
                  onChange={(e) => setCNewPassword(e.target.value)}
                  type={showCPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      cnewpassword !== newpassword ?
                        (<InputAdornment position="end">

                          <CancelIcon
                            style={{ color: "#ff1203" }} />

                        </InputAdornment>) :
                        (
                          <InputAdornment position="end">
                            {(cnewpassword === "") ? (
                              <CancelIcon
                                style={{ color: "#ffffff" }} />
                            )
                              : (
                                <CheckCircleIcon
                                  style={{ color: "#12ff03" }} />
                              )}

                          </InputAdornment>
                        )
                    )
                  }} />
              </Grid>
            </Grid>
          </form>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            disabled={loading}
            //style={{ backgroundColor: '#9c27b0', color: '#FFFFFF', }}
            color='primary'
            size="small"
            onClick={handleChangePassword}
          >Change Password
          </Button>
          {loading && 
                        <CircularProgress
                           size={24}
                           className={classes.buttonProgress}
                        />
              }
        </CardActions>
      </Card>
      {message ? 
          (            
          <Snackbar
            variant='success'
            key={message}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={openAlert}
            onClose={handleCloseAlert}
            autoHideDuration={5000}
          >
            <SnackbarContent
              className={classes.success}
              message={
                <div>
                  <span style={{ marginRight: "8px" }}>
                    <ErrorIcon fontSize="large" color="success" />
                  </span>
                  <span> {message} </span>
                </div>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="close"
                  onClick={handleCloseAlert}
                >
                  <CloseIcon color="error" />
                </IconButton>
              ]}
            />
          </Snackbar>)
          :
          (
            <Snackbar
              variant='error'
              key={message}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={openAlert}
              onClose={handleCloseAlert}
              autoHideDuration={5000}
            >
              <SnackbarContent
                className={classes.error}
                message={
                  <div>
                    <span style={{ marginRight: "8px" }}>
                      <ErrorIcon fontSize="large" color="error" />
                    </span>
                    <span> Request Not Successful old password incorrect </span>
                  </div>
                }
                action={[
                  <IconButton
                    key="close"
                    aria-label="close"
                    onClick={handleCloseAlert}
                  >
                    <CloseIcon color="error" />
                  </IconButton>
                ]}
              />
            </Snackbar>
          )}
        </>
);
}