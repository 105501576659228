import React from 'react';
import { useHistory } from "react-router";
import Navbar from '../components/Navigation/Navbar';
import Footer from '../components/Navigation/Footer';
import { 
    Avatar,
    Box,
    Button,
    Container,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,  
    Typography,
    } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ImageIcon from '@material-ui/icons/Image';
import moment from 'moment';
import '../App.css';
import Subscribe from './Subscribe';
import { withTheme } from '../components/Theme/Theme';
import { 
  exchange,
  decryptWithAES,
  encryptWithAES,
  home
} from '../requests';

const useStyles = makeStyles((theme) => ({
    rut: {
        backgroundColor: '#111',
        color: '#fff',
    },
    root: {
    display: 'flex',
    '& > *': {
        margin: theme.spacing(1),
    },
    },
    small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    },
    medium: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    },
    large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    },
}));



function MySubscription() {
    const classes = useStyles();
    const history = useHistory();
    const [validSubscription, setValidSubscription] = React.useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = React.useState('');
    const [entrylist, setEntryList] = React.useState([]);                
    const [status, setStatus] = React.useState(null);
    const [packageTitle, setPackageTitle] = React.useState(null);
    const [expiryDate, setExpiryDate] = React.useState(null);                

        //once page loads, check for Active Subscription
        React.useEffect(() => {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
          myHeaders.append("Access-Control-Allow-Origin", "https://www.kallo.ng");
          myHeaders.append("Access-Control-Allow-Credentials", 'true');
          myHeaders.append("Access-Control-Allow-Methods", "*");
          
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
          
          fetch(`${exchange}/api/v1/users/checkUserSubscriptionStatus`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                setEntryList(result)
              if (result.message === 'No Susbcription Found!' || result.message === 'Unauthorized') {
                  setSubscriptionStatus(result.message);
                  setValidSubscription(false);
              }
              else {
                  setValidSubscription(true);
                  //setEntryList(result);
                  setStatus(result.status)
                  setExpiryDate(result.expire_date)
                  setPackageTitle(result.package_title)
                  if (Math.abs(moment((new Date())).diff(result.expire_date, 'days')) <= 3) {
                    alert(`Your subscription expires on ${moment(result?.expire_date).format('MMM Do YYYY')}`)
                  }}
              })
            .catch(error => {
                console.log('error', error);
                setValidSubscription(false);});
        }, [])

    

  return (
    <div className={classes.rut}>
        {("name" in localStorage) ? (
<>
        <Navbar/>
        <Container>
        <Box my={2}>
        
<div>
        <Typography className={classes.typograph} gutterBottom variant="h5" component="h2">
        {status === 'active' ? 'Active Subscription' : 'Inactive Subscription'}
      </Typography>

      <Typography className={classes.typograph} gutterBottom variant="h5" component="h2">
        {packageTitle}
      </Typography>

      <Typography className={classes.typograph} gutterBottom variant="h5" component="h2">
        {expiryDate}
      </Typography>
      </div>
      
          <Subscribe />
        </Box>
        </Container>
        <footer>
        <Footer />
        </footer>
        </>
        ) : (
            history.push("/")
            )
}

    </div>
  )
}

export default withTheme(MySubscription);