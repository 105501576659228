import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router";
import { 
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../Navigation/Navbar';
import Footer from '../Navigation/Footer';
import AppleButton from '../Assets/app-store.png';
import GooglePlay from '../Assets/playstore.png';
import '../../App.css';
import { withTheme } from '../Theme/Theme';

const useStyles = makeStyles((theme) => ({
    heading: {
            fontSize: '36px',
            fontStyle: 'normal',
            lineHeight: '44px',
            fontFamily: 'Jost, sans-serif',
            color: '#ffffff',
    },
    paragraphspace: {
        lineHeight: '1.295',
        textAlign: 'justify',
        marginTop: '0pt',
        marginBottom: '6pt',
    },
    title:{
        fontSize: '11pt',
        fontFamily: 'Century Gothic, sans-serif',
        color: '#ffffff',
        backgroundColor: 'transparent',
        fontWeight: 700,
        fontStyle: 'normal',
        fontVariant: 'normal',
        textDecoration: 'none',
        verticalAlign: 'baseline',
        whiteSpace: 'pre',
        whiteSpace: 'pre-wrap',
    },
    element: {
        fontSize: '11pt',
        fontFamily: 'Century Gothic, sans-serif',
        color: '#ffffff',
        backgroundColor: 'transparent',
        fontWeight: 400,
        fontStyle: 'normal',
        fontVariant: 'normal',
        textDecoration: 'none',
        verticalAlign: 'baseline',
        whiteSpace: 'pre',
        whiteSpace: 'pre-wrap',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
      },
    indent: {
        marginTop: '0',
        marginBottom: '0',
        paddingInlineStart: '48px',
    },
    list: {
        listStyleType: 'disc',
        fontSize: '11pt',
        fontFamily: 'Century Gothic, sans-serif',
        color: '#ffffff',
        backgroundColor: 'transparent',
        fontWeight: 400,
        fontStyle: 'normal',
        fontVariant: 'normal',
        textDecoration: 'none',
        verticalAlign: 'baseline',
        whiteSpace: 'pre',
        marginLeft: '36pt'
    },
    medium: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    },
    large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    },
    typograph: {
        color: "#fff"
    },
    cover: {
        objectFit: 'fill'
    }
  }));


function ContactUs() {
    const classes = useStyles();
  return (
      <>
    <div className='app'>
      <Navbar />
      <Container>
        <Box my={2}>
            <div>
            <h2 className={classes.heading}>
            Contact Us
                </h2>
            </div>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>

            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            You can chat with Kallo TV customer care from the App through the red bubble icon located from the bottom right of your screen. This chat/call is free.
            </span>
            </p>

            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
                 
            </span>
            </p>

            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            You can only watch kallo.ng content from your android phone, tabs and computers through our app
            <a href='https://play.google.com/store/apps/details?id=com.ng.kallo' target="_blank" rel="noopener noreferrer">
                             <img
              //className={classes.title}
              src={AppleButton}
              alt="Kallo.ng Logo"
              style={{maxWidth: 120, height: 50, marginLeft: 2, objectFit: 'contain'}}
              href='/'
               />
               </a>
               for apple store or 
               <a href='https://play.google.com/store/apps/details?id=com.ng.kallo' target="_blank" rel="noopener noreferrer">
            <img
              //className={classes.title}
              src={GooglePlay}
              alt="Kallo.ng on Google Play"
              style={{maxWidth: 120, height: 50, marginLeft: -70, objectFit: 'contain'}}
              href='/'
               />
               </a>
            </span>
            </p>

            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
                 
            </span>
            </p>

            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
                You can also download movies from Kallo and watch later 
            </span>
            </p>

            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
                 
            </span>
            </p>

            <p className={classes.paragraphspace}> 
            <span className={classes.element}>

Live TV brings programs live from popular festivities, event and other programs
            </span>
            </p>

</Box>        
</Container>

        
         
         
         
        <footer>
        <Footer />
        </footer>
        </div>
        </>
  )
}

export default withTheme(ContactUs);