import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router";
import { 
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../Navigation/Navbar';
import Footer from '../Navigation/Footer';
import '../../App.css';
import { withTheme } from '../Theme/Theme';

const useStyles = makeStyles((theme) => ({
    heading: {
            fontSize: '36px',
            fontStyle: 'normal',
            lineHeight: '44px',
            fontFamily: 'Jost, sans-serif',
            color: '#ffffff',
    },
    paragraphspace: {
        lineHeight: '1.295',
        textAlign: 'justify',
        marginTop: '0pt',
        marginBottom: '6pt',
    },
    title:{
        fontSize: '11pt',
        fontFamily: 'Century Gothic, sans-serif',
        color: '#ffffff',
        backgroundColor: 'transparent',
        fontWeight: 700,
        fontStyle: 'normal',
        fontVariant: 'normal',
        textDecoration: 'none',
        verticalAlign: 'baseline',
        whiteSpace: 'pre',
        whiteSpace: 'pre-wrap',
    },
    element: {
        fontSize: '11pt',
        fontFamily: 'Century Gothic, sans-serif',
        color: '#ffffff',
        backgroundColor: 'transparent',
        fontWeight: 400,
        fontStyle: 'normal',
        fontVariant: 'normal',
        textDecoration: 'none',
        verticalAlign: 'baseline',
        whiteSpace: 'pre',
        whiteSpace: 'pre-wrap',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
      },
    indent: {
        marginTop: '0',
        marginBottom: '0',
        paddingInlineStart: '48px',
    },
    list: {
        listStyleType: 'disc',
        fontSize: '11pt',
        fontFamily: 'Century Gothic, sans-serif',
        color: '#ffffff',
        backgroundColor: 'transparent',
        fontWeight: 400,
        fontStyle: 'normal',
        fontVariant: 'normal',
        textDecoration: 'none',
        verticalAlign: 'baseline',
        whiteSpace: 'pre',
        marginLeft: '36pt'
    },
    medium: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    },
    large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    },
    typograph: {
        color: "#fff"
    },
    cover: {
        objectFit: 'fill'
    }
  }));

function Terms() {
    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;


  return (
    <div className='app'>
      <Navbar />
      <Container>
        <Box my={2}>
            <div>
            <h2 className={classes.heading}>
                Privacy Policy</h2>
            </div>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Privacy Statement
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            This Privacy Statement explains our practices, including your choices, 
            regarding the collection, use, and disclosure of certain information, 
            including your personal information in connection with the Kallo TV entertainment and streaming Service.
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Introduction&nbsp;
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            Welcome to KalloTV (kallo.ng). The Platform is operated and controlled by Spacekraft Media Limited (“<span className={classes.title}>
                KalloTV</span>" "<span className={classes.title}>
                we</span>" or "<span className={classes.title}>
                us</span>"). This Privacy Policy Statement sets out how KalloTV and its holding, related and subsidiary companies, collect, store 
                and handle personal information for users on our Platform.&nbsp; We respect your privacy and we are committed to protecting your personal 
                information. To learn more, please read this Privacy Policy. 
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Consent&nbsp;
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            Under data protection laws, we are required to provide you with certain information about who we are, how we process your personal data and for what purposes, and your rights in relation to your personal data. This information is provided in this Privacy Policy Statement and it is important that you read that information.&nbsp;
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            By accepting this privacy policy, you have given us your consent to our processing of your personal data (including your name, contact details, financial and device information) as described in the policy. You also consent to our processing of your location Data including details of your current location disclosed by GPS technology so that location-enabled Services are activated. We are committed to protecting your personal data and respecting your privacy.
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            How you can withdraw consent
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            Once you provide consent, you may change your mind and withdraw consent at any time by contacting us at 
            </span>
            <span className={classes.element} style={{textDecoration: 'underline'}}>support@Kallo.ng</span>
            <span className={classes.element}>
             but that will not affect the lawfulness of any processing carried out before you withdraw your consent.&nbsp;
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Introduction&nbsp;
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            This policy&nbsp; applies to all users of KalloTV’s services anywhere in the world, including users of KalloTV Apps, Websites, Service Sites, features or other services unless covered by a separate privacy policy statement and particularly, the following:
            </span>
            </p>
            <ul className={classes.indent}>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                    KalloTV App mobile application software (App), once you have downloaded or streamed a copy of the App onto your mobile telephone or handheld device
                     (Device).
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                    Any of the services accessible through the App (Services) that are available on the App Site or other sites of ours (Services Sites). 
                    </span>
                    </p>
                </li>
            </ul>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            This policy sets out the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. 
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            This App is not intended for children and we do not knowingly collect data relating to children. Please read the following carefully to understand our practices regarding your personal data and how we will treat it.
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Important information and who we are
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            Spacekraft Media Limited is the controller and is responsible for your personal data (referred to as "Company", "we", "us" or "our" in this policy).
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Contact details
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            Our full details are:
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            Full name of legal entity: Spacekraft Media Limited
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            Email address: support@Kallo.ng
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            Postal address: c/o Edoigiawerie & Company, 126 Association Road, Dolphin Estate, Ikoyi, Lagos, Nigeria.
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            Telephone: You can also chat live or call us using the live chat icon in red
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Changes to the privacy policy and your duty to inform us of changes
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            We keep our privacy policy under regular review. 
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            This version was last updated on September 8, 2021. It may change and if it does, these changes will be posted on this page and, where appropriate, notified to you when you next start the App or log onto one of the Services Sites. The new policy may be displayed on-screen and you may be required to read and accept the changes to continue your use of the App or the Services.
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during our relationship with you. 
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Third party links
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            Our Sites may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. Please note that these websites and any services that may be accessible through them have their own privacy policies and that we do not accept any responsibility or liability for these policies or for any personal data that may be collected through these websites or services, such as Contact and Location Data. Please check these policies before you submit any personal data to these websites or use these services.
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            The data we collect about you
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            We may collect, use, store and transfer different kinds of personal data about you as follows:
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
                Identity Data.
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            Contact Data.
                </span>
                </p>
                <p className={classes.paragraphspace}> 
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            Financial Data.
                </span>
                </p>
                <p className={classes.paragraphspace}> 
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            Transaction Data.
                </span>
                </p>
                <p className={classes.paragraphspace}> 
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            Device Data.
                </span>
                </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            Content Data.
                </span>
                </p>
                <p className={classes.paragraphspace}> 
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            Profile Data.
                </span>
                </p>
                <p className={classes.paragraphspace}> 
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            Usage Data.
                </span>
                </p>
                <p className={classes.paragraphspace}> 
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            Marketing and Communications Data.
                </span>
                </p>
                <p className={classes.paragraphspace}> 
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            Location Data.
                </span>
                </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            We explain these categories of data under Description of categories of personal data below.
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
We also collect, use and share Aggregated Data such as statistical or demographic data for any purpose. Aggregated Data could be derived from your personal data but is not considered personal data in law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific App feature. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy policy.
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
We do not collect any Special Categories of Personal Data about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data). Nor do we collect any information about criminal convictions and offences.
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            How is your personal data collected?
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
We will collect and process the following data about you:
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            <span className={classes.title}>Information you give us. 
            </span> 
            <p className={classes.paragraphspace} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
                This is information (including Identity, Contact, Financial, and Marketing and Communications Data) you consent to giving us about you by filling in forms on the App Site and the Services Sites (together Our Sites), or by corresponding with us (for example, by email or chat or telephone call) or by visiting any of our offices. It includes information you provide when you register to use the App Site, download or register an App, subscribe to any of our Services, search for an App or Service, make an in-App purchase, make a feedback to us, enter a competition, promotion or survey, and when you report a problem with an App, our Services, or any of Our Sites. If you contact us, we will keep a record of that correspondence. 
                </span>
            </p>
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            <span className={classes.title}>
            Information we collect about you and your device.
            </span> 
            <p className={classes.paragraphspace} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            Each time you visit one of Our Sites or use one of our Apps we will automatically collect personal data including hardware models, device IP address, operating systems and versions, software, preferred languages, unique device identifiers, advertising identifiers, serial numbers, device motion data, content and usage data. We collect this data using cookies and other similar technologies.
            </span>
            </p>
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            <span className={classes.title}>
            Location Data.
            </span> 
            <p className={classes.paragraphspace} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
                We also use GPS technology to determine your current location. Some of our location-enabled Services require your personal data for the feature to work. If you wish to use the particular feature, you will be asked to consent to your data being used for this purpose.
            </span>
            </p>
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            <span className={classes.title}>
            Information we receive from other sources including third parties and publicly available sources.
            </span> 
            <p className={classes.paragraphspace} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            We will receive personal data about you from various third parties and public sources as set out below:
            </span>
            </p>
            <p className={classes.paragraphspace} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            Device Data from the following parties:
            </span>
            </p>
            <ul className={classes.indent}>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
                analytics providers;
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
                advertising networks; 
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
                search information providers;
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
                Contact, Financial and Transaction Data from providers of technical, payment and delivery services;
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
                Identity and Contact Data from data brokers or aggregators; and
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
                Identity and Contact Data from publicly available sources; 
                    </span>
                    </p>
                </li>
            </ul>
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Trademarks
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            The Kallo TV Logo and brand collaterals are trademarks of Spacekraft Media Limited. 
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            If you haven’t received our permission, do not use our marks as your own or in any manner that implies sponsorship or endorsement by Kallo TV.
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            A product branded with our name or logo is a reflection of Kallo TV. Unless you are one of our licensees, we don’t allow others to make, sell, or give away anything with our name or logo on it.
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Copyright
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
                The copyrights in the shows and movies on our service are owned by the producers, including Spacekraft Media Limited. If you believe your or someone else’s copyrights are being infringed upon through the service, let us know through our contact service. 
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Cookies
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            We use cookies or other tracking technologies to distinguish you from other users of the App, App Site, the distribution platform (App store and Play Store) or Services Sites and to remember your preferences. This helps us to provide you with a good experience when you use the App or browse any of Our Sites and also allows us to improve the App and Our Sites.
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            How we use your personal data
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
                We will only use your personal data when the law allows us to do so. Most commonly we will use your personal data in the following circumstances: 
            </span>
            </p>
            <ul className={classes.indent}>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Where we need to perform the Services you have engaged us for.
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                To help us monitor and improve our performance and Services, our customer relations teams and service providers and the App and to make these more efficient.
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                To maintain and develop our business systems and infrastructure, including testing and upgrading of these systems.
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                To manage staff training and quality assurance.
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                To notify you about benefits and changes to the features of our Services.
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                To determine how can we improve services to you.
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Where you have consented before the processing.
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Where we need to comply with a legal or regulatory obligation.
                    </span>
                    </p>
                </li>
            </ul>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            We will only send you direct marketing communications by email or text if we have your consent. You have the right to withdraw that consent at any time by contacting us. 
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            We will get your express opt-in consent before we share your personal data with any third party for marketing purposes.
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Purposes for which we will use your personal data
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
Table goes here
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Disclosures of your personal data
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            When you consent to providing us with your personal data, we will also ask you for your consent to share your personal data with the third parties set out below for the purposes set out in the table:
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            Internal Third Parties as set out in the Glossary.
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            External Third Parties as set out in the Glossary.
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element} style={{marginLeft: '36pt', textIndent: '-17.85pt', textAlign: 'justify'}}>
            Third parties to whom we may choose to sell, transfer or merge parts of our business or our assets.
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy policy.
            </span> 
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Data security
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            All information you provide to us is stored on our secure servers. Any payment transactions will be encrypted using Secured Sockets Layer technology. Where we have given you (or where you have chosen) a password that enables you to access certain parts of Our Sites, you are responsible for keeping this password confidential. We ask you not to share a password with anyone.
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            Once we have received your information, we will use strict procedures and security features to try to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way. 
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
                We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator when we are legally required to do so.
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Data retention
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            By law we have to keep basic information about our customers and transactions for up to six years after they cease being customers for accounting purposes.
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            In some circumstances you can ask us to delete your data: see Your Legal Rights below for further information.
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            In some circumstances we will anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            In the event that you do not use the App for a period of 36 months then we will treat the account as expired and your personal data may be deleted.
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Updates
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            We reserve the right to amend this Privacy Policy from time to time and the updated version shall apply and supersede any and all previous versions, including but not limited to, leaflets or hard copy versions. Please check our Service Sites for information on our most up-to-date practices.
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Your legal rights
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            Under certain circumstances you have the following rights under data protection laws in relation to your personal data. These rights are listed in the glossary under Your Legal Rights.
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            You also have the right to ask us not to continue to process your personal data for marketing purposes. 
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            You can exercise any of these rights at any time by contacting us at   
            </span>
            <span className={classes.element} style={{textDecoration: 'underline'}}>support@kallo.ng
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Glossary
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Lawful basis
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Consent means 
            </span>
            <span className={classes.element}>
            processing your personal data where you have signified your agreement by a statement or clear opt-in to processing for a specific purpose. Consent will only be valid if it is a freely given, specific, informed and unambiguous indication of what you want. You can withdraw your consent at any time by contacting us. 
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Legitimate Interest 
            </span>
            <span className={classes.element}>
            means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Performance of Contract 
            </span>
            <span className={classes.element}>
            means processing your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Comply with a legal obligation 
            </span>
            <span className={classes.element}>
            means processing your personal data where it is necessary for compliance with a legal obligation that we are subject to.
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Third parties
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Internal third parties
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            Other companies in the Spacekraft media Limited Group.
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            External third parties
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
                Service providers based in Nigeria who provide financial, IT and system administration services.
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
                Professional advisers, including lawyers, bankers, auditors and insurers based in Nigeria who provide consultancy, banking, legal, insurance and accounting services.
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
                Federal and State taxing authorities, regulators and other authorities who require reporting of processing activities in certain circumstances.
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
                Law enforcement agencies.
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Your legal rights
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.element}>
            You have the right to:
            </span>
            </p>
            <p className={classes.paragraphspace} style={{lineHeight: 1.295, marginLeft: '36pt', textIndent: '-17.85pt',
    textAlign: 'justify',
    marginTop: '6pt',
    marginBottom: '6pt',
    padding: '0pt 0pt 0pt 17.85pt'}}> 
            <span className={classes.element} >
            <span className={classes.title}>
            Request access
            </span> 
            <span className={classes.element}>
             to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.
                </span>
            </span>
            </p>
            <p className={classes.paragraphspace} style={{lineHeight: 1.295, marginLeft: '36pt', textIndent: '-17.85pt',
    textAlign: 'justify',
    marginTop: '6pt',
    marginBottom: '6pt',
    padding: '0pt 0pt 0pt 17.85pt'}}> 
            <span className={classes.element} >
            <span className={classes.title}>
            Request correction
            </span> 
            <span className={classes.element}>
             of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.
                </span>
            </span>
            </p>
            <p className={classes.paragraphspace} style={{lineHeight: 1.295, marginLeft: '36pt', textIndent: '-17.85pt',
    textAlign: 'justify',
    marginTop: '6pt',
    marginBottom: '6pt',
    padding: '0pt 0pt 0pt 17.85pt'}}> 
            <span className={classes.element} >
            <span className={classes.title}>
            Request erasure
            </span> 
            <span className={classes.element}>
             of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.
                </span>
            </span>
            </p>
            <p className={classes.paragraphspace} style={{lineHeight: 1.295, marginLeft: '36pt', textIndent: '-17.85pt',
    textAlign: 'justify',
    marginTop: '6pt',
    marginBottom: '6pt',
    padding: '0pt 0pt 0pt 17.85pt'}}> 
            <span className={classes.element} >
            <span className={classes.title}>
            Object to processing
            </span> 
            <span className={classes.element}>
             of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.
                </span>
            </span>
            </p>
            <p className={classes.paragraphspace} style={{lineHeight: 1.295, marginLeft: '36pt', textIndent: '-17.85pt',
    textAlign: 'justify',
    marginTop: '6pt',
    marginBottom: '6pt',
    padding: '0pt 0pt 0pt 17.85pt'}}> 
            <span className={classes.element} >
            <span className={classes.title}>
            Request access
            </span> 
            <span className={classes.element}>
             to your personal data (commonly known as a "data subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.
                </span>
            </span>
            </p>
            <p className={classes.paragraphspace} style={{lineHeight: 1.295, marginLeft: '36pt', textIndent: '-17.85pt',
    textAlign: 'justify',
    marginTop: '6pt',
    marginBottom: '6pt',
    padding: '0pt 0pt 0pt 17.85pt'}}> 
            <span className={classes.element} > 
           <span className={classes.title}>
           Request restriction
            </span> 
            <span className={classes.element}>
             of processing of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios:
                </span>
            </span>
            </p>
            <ul className={classes.indent}>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                if you want us to establish the data's accuracy;
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                where our use of the data is unlawful but you do not want us to erase it;
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                    you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.
                    </span>
                    </p>
                </li>
            </ul>
            <p className={classes.paragraphspace} style={{lineHeight: 1.295, marginLeft: '36pt', textIndent: '-17.85pt',
    textAlign: 'justify',
    marginTop: '6pt',
    marginBottom: '6pt',
    padding: '0pt 0pt 0pt 17.85pt'}}> 
            <span className={classes.element} >            
            <span className={classes.title}>
            Request the transfer
            </span> 
            <span className={classes.element}>
             of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.
                </span>
            </span>
            </p>
            <p className={classes.paragraphspace} style={{lineHeight: 1.295, marginLeft: '36pt', textIndent: '-17.85pt',
    textAlign: 'justify',
    marginTop: '6pt',
    marginBottom: '6pt',
    padding: '0pt 0pt 0pt 17.85pt'}}> 
            <span className={classes.element} >
            <span className={classes.title}>
            Withdraw consent at any time 
            </span> 
            <span className={classes.element}>
             where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.
                </span>
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Description of categories of personal data
            </span>
            </p>
            <p className={classes.paragraphspace} style={{lineHeight: 1.295, marginLeft: '36pt', textIndent: '-17.85pt',
    textAlign: 'justify',
    marginTop: '6pt',
    marginBottom: '6pt',
    padding: '0pt 0pt 0pt 17.85pt'}}> 
            <span className={classes.element} >
            <span className={classes.title}>
            Identity Data: 
            </span>
            <span className={classes.element}>
             first name, last name, maiden name, username or similar identifier, marital status, title, date of birth, gender.
            </span>
            </span>
            </p>
            <p className={classes.paragraphspace} style={{lineHeight: 1.295, marginLeft: '36pt', textIndent: '-17.85pt',
    textAlign: 'justify',
    marginTop: '6pt',
    marginBottom: '6pt',
    padding: '0pt 0pt 0pt 17.85pt'}}> 
            <span className={classes.element} >
            <span className={classes.title}>
            Contact Data: 
            </span>
            <span className={classes.element}>
             billing address, delivery address, email address and telephone numbers.
            </span>
            </span>
            </p>
            <p className={classes.paragraphspace} style={{lineHeight: 1.295, marginLeft: '36pt', textIndent: '-17.85pt',
    textAlign: 'justify',
    marginTop: '6pt',
    marginBottom: '6pt',
    padding: '0pt 0pt 0pt 17.85pt'}}> 
            <span className={classes.element} >
            <span className={classes.title}>
            Financial Data:
            </span>
            <span className={classes.element}>
             payment card details.
            </span>
            </span>
            </p>
            <p className={classes.paragraphspace} style={{lineHeight: 1.295, marginLeft: '36pt', textIndent: '-17.85pt',
    textAlign: 'justify',
    marginTop: '6pt',
    marginBottom: '6pt',
    padding: '0pt 0pt 0pt 17.85pt'}}> 
            <span className={classes.element} >
            <span className={classes.title}>
            Transaction Data:
            </span>
            <span className={classes.element}>
             includes details about payments to and from you and details of in-App purchases.
            </span>
            </span>
            </p>
            <p className={classes.paragraphspace} style={{lineHeight: 1.295, marginLeft: '36pt', textIndent: '-17.85pt',
    textAlign: 'justify',
    marginTop: '6pt',
    marginBottom: '6pt',
    padding: '0pt 0pt 0pt 17.85pt'}}> 
            <span className={classes.element} >
            <span className={classes.title}>
            Device Data:
            </span>
            <span className={classes.element}>
             includes the type of mobile device you use, the mobile phone number used by the Device, mobile network information, your mobile operating system, the type of mobile browser you use, time zone setting etc.
            </span>
            </span>
            </p>
            <p className={classes.paragraphspace} style={{lineHeight: 1.295, marginLeft: '36pt', textIndent: '-17.85pt',
    textAlign: 'justify',
    marginTop: '6pt',
    marginBottom: '6pt',
    padding: '0pt 0pt 0pt 17.85pt'}}> 
            <span className={classes.element} >
            <span className={classes.title}>
            Content Data:
            </span>
            <span className={classes.element}>
             includes information stored on your Device, including login information.
            </span>
            </span>
            </p>
            <p className={classes.paragraphspace} style={{lineHeight: 1.295, marginLeft: '36pt', textIndent: '-17.85pt',
    textAlign: 'justify',
    marginTop: '6pt',
    marginBottom: '6pt',
    padding: '0pt 0pt 0pt 17.85pt'}}> 
            <span className={classes.element} >
            <span className={classes.title}>
            Profile Data:
            </span>
            <span className={classes.element}>
             includes your username and password, in-App purchase history, your interests, preferences, feedback and survey responses.
            </span>
            </span>
            </p>
            <p className={classes.paragraphspace} style={{lineHeight: 1.295, marginLeft: '36pt', textIndent: '-17.85pt',
    textAlign: 'justify',
    marginTop: '6pt',
    marginBottom: '6pt',
    padding: '0pt 0pt 0pt 17.85pt'}}> 
            <span className={classes.element} >
            <span className={classes.title}>
            Usage Data:
            </span>
            <span className={classes.element}>
             includes details of your use of any of our Apps or your visits to any of Our Sites including, but not limited to, traffic data and other communication data, whether this is required for our own billing purposes or otherwise and the resources that you access.
            </span>
            </span>
            </p>
            <p className={classes.paragraphspace} style={{lineHeight: 1.295, marginLeft: '36pt', textIndent: '-17.85pt',
    textAlign: 'justify',
    marginTop: '6pt',
    marginBottom: '6pt',
    padding: '0pt 0pt 0pt 17.85pt'}}> 
            <span className={classes.element} >
            <span className={classes.title}>
            Marketing and Communications Data:
            </span>
            <span className={classes.element}>
             includes your preferences in receiving marketing from us and our third parties and your communication preferences. 
            </span>
            </span>
            </p>
            <p className={classes.paragraphspace} style={{lineHeight: 1.295, marginLeft: '36pt', textIndent: '-17.85pt',
    textAlign: 'justify',
    marginTop: '6pt',
    marginBottom: '6pt',
    padding: '0pt 0pt 0pt 17.85pt'}}> 
            <span className={classes.element} >
            <span className={classes.title}>
            Location Data: 
            </span>
            <span className={classes.element}>
            includes your current location disclosed by GPS technology.
            </span>
            </span>
            </p>


            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Privacy Statement
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            This Privacy Statement explains our practices, including your choices, 
            regarding the collection, use, and disclosure of certain information, 
            including your personal information in connection with the Kallo TV entertainment and streaming Service.
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            Privacy Statement
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            This Privacy Statement explains our practices, including your choices, 
            regarding the collection, use, and disclosure of certain information, 
            including your personal information in connection with the Kallo TV entertainment and streaming Service.
            </span>
            </p>
        </Box>
      </Container>
 
      <footer>
        <Footer />
        </footer>
        </div>
  )
}

export default withTheme(Terms);