import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router";
import { 
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../Navigation/Navbar';
import Footer from '../Navigation/Footer';
import '../../App.css';
import { withTheme } from '../Theme/Theme';

const useStyles = makeStyles((theme) => ({
    heading: {
            fontSize: '36px',
            fontStyle: 'normal',
            lineHeight: '44px',
            fontFamily: 'Jost, sans-serif',
            color: '#ffffff',
    },
    paragraphspace: {
        lineHeight: '1.295',
        textAlign: 'justify',
        marginTop: '0pt',
        marginBottom: '6pt',
    },
    title:{
        fontSize: '11pt',
        fontFamily: 'Century Gothic, sans-serif',
        color: '#ffffff',
        backgroundColor: 'transparent',
        fontWeight: 700,
        fontStyle: 'normal',
        fontVariant: 'normal',
        textDecoration: 'none',
        verticalAlign: 'baseline',
        whiteSpace: 'pre',
        whiteSpace: 'pre-wrap',
    },
    element: {
        fontSize: '11pt',
        fontFamily: 'Century Gothic, sans-serif',
        color: '#ffffff',
        backgroundColor: 'transparent',
        fontWeight: 400,
        fontStyle: 'normal',
        fontVariant: 'normal',
        textDecoration: 'none',
        verticalAlign: 'baseline',
        whiteSpace: 'pre',
        whiteSpace: 'pre-wrap',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
      },
    indent: {
        marginTop: '0',
        marginBottom: '0',
        paddingInlineStart: '48px',
    },
    list: {
        listStyleType: 'disc',
        fontSize: '11pt',
        fontFamily: 'Century Gothic, sans-serif',
        color: '#ffffff',
        backgroundColor: 'transparent',
        fontWeight: 400,
        fontStyle: 'normal',
        fontVariant: 'normal',
        textDecoration: 'none',
        verticalAlign: 'baseline',
        whiteSpace: 'pre',
        marginLeft: '36pt'
    },
    medium: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    },
    large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    },
    typograph: {
        color: "#fff"
    },
    cover: {
        objectFit: 'fill'
    }
  }));


function AboutUs() {
    const classes = useStyles();
  return (
    <div className='app'>
      <Navbar />
      <Container>
        <Box my={2}>
            <div>
            <h2 className={classes.heading}>
            About Us
                </h2>
            </div>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>

            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            Kallo.ng is an  entertainment streaming service with variety of contents in Hausa language. Kallo.ng streams classic and latest Hausa movies, series, documentaries and music of all genre in the comfort of their devices. Subscribers can now enjoy International contents dubbed in Hausa. 
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            A sha kallo lafiya!
            </span>
            </p>

            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
                 
            </span>
            </p>

            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
                Kallo.ng is a product of Spacekraft Media Limited, a registered company owned by the indigenous daughters and sons of Northern Nigeria who are passionate about promoting their culture. Our mission is to bring home close to communities through entertainments irrespective of their locations while we tell our story by ourselves. 
            </span>
            </p>

</Box>        
</Container>
        
         
         
         
<footer>
        <Footer />
        </footer>
        </div>
  )
}

export default withTheme(AboutUs);