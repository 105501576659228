import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router";
import { 
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../Navigation/Navbar';
import Footer from '../Navigation/Footer';
import AppleButton from '../Assets/app-store.png';
import GooglePlay from '../Assets/playstore.png';
import WindowsStore from '../Assets/windows-store.png';
import '../../App.css';
import { withTheme } from '../Theme/Theme';

const useStyles = makeStyles((theme) => ({
    heading: {
            fontSize: '36px',
            fontStyle: 'normal',
            lineHeight: '44px',
            fontFamily: 'Jost, sans-serif',
            color: '#ffffff',
    },
    paragraphspace: {
        lineHeight: '1.295',
        textAlign: 'justify',
        marginTop: '0pt',
        marginBottom: '6pt',
    },
    title:{
        fontSize: '13pt',
        fontFamily: 'Century Gothic, sans-serif',
        color: '#ffffff',
        backgroundColor: 'transparent',
        fontWeight: 700,
        fontStyle: 'normal',
        fontVariant: 'normal',
        textDecoration: 'none',
        verticalAlign: 'baseline',
        whiteSpace: 'pre',
        whiteSpace: 'pre-wrap',
    },
    element: {
        fontSize: '11pt',
        fontFamily: 'Century Gothic, sans-serif',
        color: '#ffffff',
        backgroundColor: 'transparent',
        fontWeight: 400,
        fontStyle: 'normal',
        fontVariant: 'normal',
        textDecoration: 'none',
        verticalAlign: 'baseline',
        whiteSpace: 'pre',
        whiteSpace: 'pre-wrap',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
      },
    indent: {
        marginTop: '0',
        marginBottom: '0',
        paddingInlineStart: '48px',
    },
    list: {
        listStyleType: 'disc',
        fontSize: '11pt',
        fontFamily: 'Century Gothic, sans-serif',
        color: '#ffffff',
        backgroundColor: 'transparent',
        fontWeight: 400,
        fontStyle: 'normal',
        fontVariant: 'normal',
        textDecoration: 'none',
        verticalAlign: 'baseline',
        whiteSpace: 'pre',
        marginLeft: '36pt'
    },
    medium: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    },
    large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    },
    typograph: {
        color: "#fff"
    },
    cover: {
        objectFit: 'fill'
    }
  }));


function ContactUs() {
    const classes = useStyles();
  return (
      <>
    <div className='app'>
      <Navbar />
      <Container>
        <Box my={2}>
            <div>
            <h2 className={classes.heading}>
            CIBIYAR TUNTUƁA
                </h2>
            </div>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
{"\n"}{"\n"}
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            YADDA AKE YIN RIJISTA DA KALLO.NG
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            More kallon nau'ikan fina -finai, shirye -shiryen bidiyo, da waƙoƙi masu nishaɗantarwa daga Kallo.ng 
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            A matsayinku na ƴan dandalin Kallo.ng, kuna da zaɓuɓɓukan biyan kuɗi; na kwana bakwai (7), ko kwana goma sha hudu (14)  ko kuma wata ɗaya (30),  wanda zai fara daga ranar da kuka yi rajista. Haka kuma kuna da ƴancin da za ku canja tsarin ku ko ku katse biyan kuɗin kallon da kuka biya a duk lokacin da ku ke buƙata. Amma mun ci muku alqawarin cewa muna da ƙayatattun shirye-shirye da ba za ku iya yadda su wuce ku ba.
            </span>
            </p>
            
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
{"\n"}{"\n"}
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            YIN RIJISTA CIKIN SAUƘI
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            Ku bi matakai ta waɗannan kafofin:
            </span>
            </p>
{/*             <p className={classes.paragraphspace}> 
            <span className={classes.element}>
                As a Kallo.ng subscriber, you have the options to pay for 7 days, 14 days or just pay once a month. You have the freedom to change your plan or cancel the subscription any time you may wish to.
            </span>
            </p>
 */}
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
{"\n"}{"\n"}
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            YIN RIJISTA CIKIN SAUƘI
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title} style={{textDecorationLine: 'underline'}}>
            Android
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            Ku saukar da app din Kallo.ng daga manhajar Google Play store, to sai ku yi rijista ta manhajar
            </span>
            </p>
            <ul className={classes.indent}>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Ku shiga manhajar ta hanyar amfani da google account dinka da lambar waya. 
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Ko ku saka lambar wayanka. Za’a tura maka lambar sirri na lokaci daya (OTP)

                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Idan ba kai amfani da wadannan hanyoyin ba, to ka kirkiri sabon asusu ta hanyar amfani da cikakken sunanka, da imel da lambar sirrinka.
                    </span>
                    </p>
                </li>
            </ul>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
{"\n"}
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title} style={{textDecorationLine: 'underline'}}>
            iOS (APPLE)
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            Ku saukar da app din Kallo.ng daga manhajar Apple Store, to sai ku yi rijista ta manhajar. 
            </span>
            </p>
            <ul className={classes.indent}>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Ku shiga manhajar ta hanyar amfani da google account dinka da lambar waya. 
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Ko ku saka lambar wayanka. Za’a tura maka lambar sirri na lokaci daya (OTP)
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Idan ba kai amfani da wadannan hanyoyin ba, to ka kirkiri sabon asusu ta hanyar amfani da cikakken sunanka, da imel da lambar sirrinka.
                    </span>
                    </p>
                </li>
            </ul>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
{"\n"}
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title} style={{textDecorationLine: 'underline'}}>
            YANAR GIZO (Website)
            </span>
            </p>
            <ul className={classes.indent}>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Don yin kallon fina-finai kai tsaye ta yanar gizo, ziyarci ⦁	www.Kallo.ng. ka shiga da imel dinka ko kayi rijista sabon susu domin morewa kallon shirye-shirye.
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Saukar da desktop App domin morewa Kallo.ng 
                    </span>
                    </p>
                </li>
            </ul>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
{"\n"}
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            SUBSCRIPTION PACKAGE
            </span>
            </p>
            <ul className={classes.indent}>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                    7 Days Subscription Plan
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                14 Days Subscription Plan
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                30 Days Subscription Plan
                    </span>
                    </p>
                </li>
            </ul>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
{"\n"}
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            MATAKIN BIYAN KUDI 
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            Domin saukaka mu’amala, mun kawo muku tsarin biyan kudiKu shigar da matakan biyan kuɗi ta shafinmu na biyan kudi. Muna da hanyoyi biyu na biyan kuɗi kamar haka:
            </span>
            </p>
            <ul className={classes.indent}>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                KATIN BANKI: Ta hanyar rubuta lambar dake jikin katinku na yin cinikayya wato “credit card” ko katin POS
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                TRANFISA: Zaku iya biyan kudin kallo ta hanyar yin transifa daga bankinku, wayarku ko kuma wani ya yi muku, kamar masu POS ko abokanku masu iya biyan kudi da App dinsu na banki ko internet.
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Yi ambobi da suka bayyana a shafin biyan kudi. Domin biyan kudi. Kuna da zabin biya a Access ko Zenith Bank kai tsaye ba tare da jinkiri ba. Banki zasu baka lambobi  da zaka kwapa ka shigar a kan wayarka domin tabbatar da biyan kudin.
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Akwai Biyan kudi ta Pyastack, wanda yake dauke da nau’ika uku (3) na biyan kudi (Katinku, transifa ko USSD)
                    </span>
                    </p>
                </li>
            </ul>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
{"\n"}
            </span>
            </p>
            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            CIBIYAR GANAWA DA ABOKAN CINIKAYYA
            </span>
            </p>
            <ul className={classes.indent}>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Za ku iya  kiranmu ko tattaunawa da sashen hulɗa da abokan cinikayyar Kallo.ng ta manhajar, idan a ka danna wata jaka mai launin “ja” da ke ƙasan shafin mu ta ɓangaren dama. Wannan tattaunawar ko kiran waya kyauta ne. Za ku iya kallon fina-finanmu daga hannunku ta Android, iOS, ipad ko kuma ta na'ura mai ƙwaƙwalwa wato computer.
                    </span>
                    </p>
                </li>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                Haka zalika, zaku iya samunmu ta shafinmu na Instagram, Facebook, LinkedIn, da Twitter, ko ku tura mana wasika ko kira kai tsaye ta wadannan bayanai dake kasa ta ⦁	support@kallo.ng ko lambobinmu +234 916 237 6497, +234 706 329 4298
                    </span>
                    </p>
                </li>
            </ul>



            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
                 
            </span>
            </p>

            <p className={classes.paragraphspace}>
            <span className={classes.title}>
            DANGANE DA MU
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            Kallo.ng manhajar  fina -finan Hausa ne ta kafar yanar gizo ko kan waya da ke nunawa masu kallo fina-finan Hausa da al’adun Hausa da kaɗe-kaɗen gargajiya - na da dana zamani.
            </span>
            </p>
            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            Kallo.ng mallakar Spacekraft Media Limited ne.  Kamfani mai lasisi kuma mallakar yan asalin Arewacin Najeriya masu kishin bunkasa al’adun Hausa.
            </span>
            </p>

            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            <a href='https://play.google.com/store/apps/details?id=com.ng.kallo' target="_blank" rel="noopener noreferrer">
                             <img
              //className={classes.title}
              src={AppleButton}
              alt="Kallo.ng Logo"
              style={{maxWidth: 120, height: 50, marginLeft: 2, objectFit: 'contain'}}
              href='/'
               />
               </a>
               <p className={classes.paragraphspace}>
            <span className={classes.title}>
{"\n"}
            </span>
            </p>
               <a href='https://play.google.com/store/apps/details?id=com.ng.kallo' target="_blank" rel="noopener noreferrer">
            <img
              //className={classes.title}
              src={GooglePlay}
              alt="Kallo.ng on Google Play"
              style={{maxWidth: 120, height: 50, marginLeft: -70, objectFit: 'contain'}}
              href='/'
               />
               </a>
               <p className={classes.paragraphspace}>
            <span className={classes.title}>
{"\n"}
            </span>
            </p>
               <a href='https://play.google.com/store/apps/details?id=com.ng.kallo' target="_blank" rel="noopener noreferrer">
            <img
              //className={classes.title}
              src={WindowsStore}
              alt="Kallo.ng on Windows Store"
              style={{maxWidth: 120, height: 50, marginLeft: -70, objectFit: 'contain'}}
              href='/'
               />
               </a>
            </span>
            </p>

            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
                 
            </span>
            </p>

            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
            Hakkin mallaka: 2022
            </span>
            </p>

            <p className={classes.paragraphspace}> 
            <span className={classes.element}>
                 
            </span>
            </p>

</Box>        
</Container>

        
         
         
         
        <footer>
        <Footer />
        </footer>
        </div>
        </>
  )
}

export default withTheme(ContactUs);