import axios from "axios";

/* Base url to make requests */
const instance = axios.create({
//    baseURL: "https://www.kallo.ng/rest-api/v100",
//    baseURL: 'https://corsanywhere.herokuapp.com/http://api.kallo.ng:5000/api/v1/users',
    baseURL: 'https://api.kallo.ng/api/v1/users',
//    baseURL: 'http://api.kallo.ng:5000/api/v1/users',
//    baseURL: 'http://localhost:5000/api/v1/users',
//    baseURL: "https://www.kallo.ng/rest-api/v100",
});

export default instance;