import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { register } from "./RegistrationStyles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Link } from "react-router-dom";
import { 
    Paper,
    TextField,
    Button,
    ButtonGroup,
    FormControl,
    FormControlLabel,
    Grid,
    MenuItem,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Select,
    Typography
 } from "@material-ui/core";
import logo from "../components/Assets/tv_logo_white.png";
import ErrorIcon from "@material-ui/icons/Error";
import VisibilityTwoToneIcon from "@material-ui/icons/VisibilityTwoTone";
import VisibilityOffTwoToneIcon from "@material-ui/icons/VisibilityOffTwoTone";
import CloseIcon from "@material-ui/icons/Close";
 import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import GoogleLogin from 'react-google-login';
import { useHistory } from "react-router";
import { 
  exchange,
  decryptWithAES,
  encryptWithAES,
  home
} from '../requests';

const API_KEY = "oczkk67p7pyykt9xlk1eytde";

const fetchUrl = `/home_content_for_android?API-KEY=${API_KEY}`;

//const exchange = 'http://api.kallo.ng:5000';
const useStyles = makeStyles(register);

export default function Login() {
  const classes = useStyles();
  const history = useHistory();
//  const paperStyle={padding: 20, height: 'auto', width: 280, margin:"20px auto"}

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [hidePassword, setHidePassword] = useState(true);


    const showPassword = () => {
      setHidePassword(!hidePassword)
    };

const submitRegistration = e => {
      e.preventDefault();

var myHeaders = new Headers();
myHeaders.append('Content-Type', 'application/json');
myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
myHeaders.append("Access-Control-Allow-Origin", "*");
myHeaders.append("Access-Control-Allow-Credentials", 'true');
myHeaders.append("Access-Control-Allow-Methods", "*");

var raw = JSON.stringify({
  email: email,
  password: password

});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${exchange}/api/v1/users/authenticate`, requestOptions)
  .then(response => response.text())
  .then((data) => {
    localStorage.setItem("token", data.token);
    localStorage.setItem("name", data.user.name);
    console.log('name', data.user.name)
  })
  .then(history.push('/'))
  .catch(error => console.log('error', error));

    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
      };


  return (
        <div className={classes.main}>
        <CssBaseline />

        <Paper className={classes.paper}>
            <img className={classes.avatar}
            src={logo}
            alt="Kallo.ng Logo" />
<Grid container align='center'>
<Grid item xs > 
       <Button
              disabled
              disableRipple
//              fullWidth
              variant="contained"
//              className={classes.button}
              type="submit"
              onClick={submitRegistration}
              style={{color: '#fff'}}
            >Sign In</Button>
          </Grid>
<Grid item xs> 
<Link to="/register">
          <Button 
          fullWidth
          style={{color: '#fff', backgroundColor: '#8b0000'}}
          >Register</Button>
          </Link>
          </Grid>
</Grid>
          <form
            className={classes.form}
            onSubmit={() => submitRegistration}
          >
           <Grid container align='center'>
            <Grid item xs={12} sm={6} > 
                        <FormControl required fullWidth margin="normal">
              <InputLabel htmlFor="email" className={classes.labels}>
                Email Address
              </InputLabel>
              <Input
                name="email"
                type="email"
                autoComplete="email"
                className={classes.inputs}
                disableUnderline={true}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} > 
            <FormControl required fullWidth margin="normal">
              <InputLabel htmlFor="password" className={classes.labels}>
                Password
              </InputLabel>
              <Input
                name="password"
                autoComplete="password"
                className={classes.inputs}
                disableUnderline={true}
                onChange={(e) => setPassword(e.target.value)}
                type={hidePassword ? "password" : "input"}
                endAdornment={
                  hidePassword ? (
                    <InputAdornment position="end">
                      <VisibilityOffTwoToneIcon
                        fontSize="default"
                        className={classes.passwordEye}
                        onClick={showPassword}
                      />
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <VisibilityTwoToneIcon
                        fontSize="default"
                        className={classes.passwordEye}
                        onClick={showPassword}
                      />
                    </InputAdornment>
                  )
                }
              />
            </FormControl>
            </Grid>
            </Grid> 
            <Button
              variant="outlined"
//              type="submit"
              onClick={submitRegistration}
            >
              Sign In
            </Button>
          </form>
        </Paper>
      </div>
  


  );
}