import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import ReactPlayer from 'react-player';
import { 
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Dialog,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  TextField,
  Typography,
  } from '@material-ui/core';
import Subscribe from '../../Sessions/Subscribe';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from './Navbar';
import Footer from './Footer';
import '../../App.css';
import { 
  exchange,
  decryptWithAES,
  encryptWithAES,
  home
} from '../../requests';
import { withTheme } from '../Theme/Theme';

const useStyles = makeStyles((theme) => ({
  rut: {
      backgroundColor: '#111',
      color: '#fff',
      margin: 20,
  },
  root: {
      maxWidth: 450,
      backgroundColor: '#222',
  },
  small: {
  width: theme.spacing(3),
  height: theme.spacing(3),
  },
  medium: {
  width: theme.spacing(7),
  height: theme.spacing(7),
  },
  large: {
  width: theme.spacing(10),
  height: theme.spacing(10),
  },
  typograph: {
      color: "#fff"
  },
  cover: {
      objectFit: 'fill'
  }
}));

//const exchange = 'http://api.kallo.ng:5000';
//const exchange = 'http://localhost:5000';

/* const CryptoJS = require('crypto-js');

const encryptWithAES = (text) => {
  const passphrase = 'kallo123';
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

const decryptWithAES = (ciphertext) => {
  const passphrase = 'kallo123';
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}; */

function Search() { 
  const classes = useStyles();
    const history = useHistory();
    const [searchValue, setSearchValue] = useState('');
    const [searchedFor, setSearchedFor] = useState('');
    const [movieSearchList, setMovieSearchList]= React.useState([]);
    const [seriesSearchList, setSeriesSearchList]= React.useState([]);
    const [trailerUrl, setTrailerUrl] = useState("");
    const [trailerId, setTrailerId] = useState(null);
    const [trailerUrll, setTrailerUrll] = useState("");
    const [validSubscription, setValidSubscription] = useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState('');
    const [series, setSeries] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openDescription, setOpenDescription] = useState(false);
    const [description, setDescription] = useState('');
    let { title } = useParams();

    function truncate(str, n) {
      return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  }

    const getSearchValue = (searchdata) => {
        setSearchValue(searchdata);
    }

    const handleClose = () => {
      setOpen(false);
      setOpenDescription(false);
    };


//first time page loads
    useEffect(() => {
      localStorage.removeItem("lupe");
      localStorage.removeItem("related_movies");
      localStorage.removeItem("movie_details");
      localStorage.removeItem('movie_title')
      if (title !== null || title !== '') {
        setSearchValue(title);
      }
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Credentials", 'true');
      myHeaders.append("Access-Control-Allow-Methods", "*");
      
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
       if (searchValue !== ''){
      fetch(`${exchange}/api/v1/users/search/${searchValue}`, requestOptions)
        .then(response => response.json())
        .then((data) => {
          if (data.message === 'Unauthorized') {
            history.push('/#')
          }
          setMovieSearchList(data.movies);
          setSeriesSearchList(data.series);
//          console.log(data)
        })
        .catch(error => console.log('error', error));
      }
    }, [searchValue, title])

        //once movie is SELECTED, get RELEVANT DATA FOR STREAMING
        useEffect(() => {
          var myHeaders = new Headers();
          myHeaders.append('Content-Type', 'application/json');
          myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
          myHeaders.append("Access-Control-Allow-Origin", "*");
          myHeaders.append("Access-Control-Allow-Credentials", 'true');
          myHeaders.append("Access-Control-Allow-Methods", "*");
    
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
    
          if (validSubscription) {
          fetch(`${exchange}/api/v1/users/movieSingleDetails/${trailerId}/movie`, requestOptions)
            .then(response => response.json())
            .then(result => {
              //get movie url
              {result.videos.map((val) => {
    
                setTrailerUrl(val.file_url);
                localStorage.setItem("lupe", encryptWithAES(val.file_url))
    //            alert(localStorage.getItem('lupe'))
              })}          
    //          setTrailer(result.videos);
            //get related movies as json
              localStorage.setItem("related_movies", JSON.stringify(result.related_movies));
//              localStorage.setItem("movie_details", JSON.stringify(result));
//              localStorage.setItem("movie_title", (result.movie.title))
    
              console.log(result)
            })
    /*         .then(
              alert(`Url: ${trailerUrl}`)
              ) */
            .then(()=>
             { if (trailerUrl !== '') {
                //history.push('/now-playing')
                history.push('/pages/contact-us')
              }
            }
            )
            .catch(error => console.log('error', error));
          }
          else if (subscriptionStatus === 'Unauthorized')
          {
              history.push('/sign-in');
          }
          else if (subscriptionStatus === 'No Susbcription Found!')
          {
              setOpen(true);
          }
        }, [trailerId, trailerUrl]);

        //once page loads, check for Active Subscription
        useEffect(() => {
          var myHeaders = new Headers();
          myHeaders.append('Content-Type', 'application/json');
          myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
          myHeaders.append("Access-Control-Allow-Origin", "*");
          myHeaders.append("Access-Control-Allow-Credentials", 'true');
          myHeaders.append("Access-Control-Allow-Methods", "*");
          
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
          
          fetch(`${exchange}/api/v1/users/checkUserSubscriptionStatus`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result.message);
              if (result.message === 'No Susbcription Found!' || result.message === 'Unauthorized') {
                  setSubscriptionStatus(result.message);
                  setValidSubscription(false);
              }
              else {
                  setValidSubscription(true);}
              })
            .catch(error => {
                console.log('error', error);
                setValidSubscription(false);});
        }, [])

  return (
    <div className='app'> 
    <div className={classes.rut}>
      <Navbar 
      getSearchValue={getSearchValue} />
      { (searchValue!=='') ? (
        <>
              <Typography variant="h6" >
            Searching for  
        <TextField 
          className="mb-4 w-half"
          placeholder="your favorite movies..."
//          variant='standard'
          onChange={(e) => {
            setSearchedFor(e.target.value);
            history.push(`/search/${e.target.value}`)
          }}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              setSearchValue(searchedFor)
            }
              }}
          type="text"
          style={{color: '#fff'}}
          value={searchValue}
        />
        </Typography> 
        <div >
          <Button 
            size="large" style={{color: "#fff", backgroundColor: "#8b0000"}}
            onClick={() => setSeries(!series)}>
              Click to view {series ? 'Movies' : 'Series'}
          </Button>
        <div>
            <h2 className={classes.heading}>
 
                </h2>
            </div>
            </div>
    { (!series) &&       
    <>
{/*     <div>        
      <Typography variant="h6" gutterBottom>
            Movie Results for ...
        </Typography> 
        </div> */}
    
    <Grid container spacing={2} justifyContent="center"
  alignItems="center">
    { (movieSearchList.length !== 0) ?
          (movieSearchList.map(val => (
      <Grid item xs={12} sm={4}>
        <Card className={classes.root}>
          <CardActionArea
        onClick={()=> {
          setOpenDescription(true);
          setDescription(val?.description)}}
          >
            <CardMedia
              className={classes.cover}
              component="img"
              alt={val?.slug}
              height="300"
              image={val?.thumbnail_url}
              title={val?.slug}
            />
            <CardContent>
              <Typography className={classes.typograph} gutterBottom variant="h5" component="h2">
                {val?.title}
              </Typography>
              <Typography className={classes.typograph} variant="body2" color="textSecondary" component="p">
              {truncate(val?.description, 200)}  
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
          <Button size="small" style={{color: "#fff", backgroundColor: "#8b0000"}}
        onClick={() => {
          if (trailerUrll === '') {
            setTrailerUrll(val.trailler_youtube_source.replace("watch?v=", "embed/"));
            }
            else {
              setTrailerUrll('')
            }
            }}>
          Watch Trailer
        </Button>
            <Button size="small" style={{color: "#fff", backgroundColor: "#8b0000"}}
            onClick={() => {setTrailerId(val.videos_id);}}>
              Watch
            </Button>
          </CardActions>
        </Card>                    
      </Grid>
                        ))) : (
                          <Grid item xs={12} sm={4}>
                          <div>
                        <h2 className={classes.heading}>
                        No Movies found
                            </h2>
                        </div>
                        </Grid>
                        )}            
        </Grid>
</>
}

{ series &&
    <>
{/*     <div>        
      <Typography variant="h6" gutterBottom>
            Series Results for {searchValue}...
        </Typography> 
      </div> */}
    <div> 
    <Grid container spacing={2} justifyContent="center"
  alignItems="center">
    { (seriesSearchList.length !== 0) ?
            (seriesSearchList.map(val => (
      <Grid item xs={12} sm={4}>
        <Card className={classes.root}>
          <CardActionArea
        onClick={()=> {
          setOpenDescription(true);
          setDescription(val?.description)}}
        >
            <CardMedia
              className={classes.cover}
              component="img"
              alt={val?.slug}
              height="300"
              image={val?.thumbnail_url}
              title={val?.slug}
            />
            <CardContent>
              <Typography className={classes.typograph} gutterBottom variant="h5" component="h2">
                {val?.title}
              </Typography>
              <Typography className={classes.typograph} variant="body2" color="textSecondary" component="p">
              {truncate(val?.description, 200)}  
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" style={{color: "#fff", backgroundColor: "#8b0000"}}
            onClick={() => {setTrailerId(val.videos_id);}}>
              Watch
            </Button>
          </CardActions>
        </Card>                    
      </Grid>
            )))  : (
              <Grid item xs={12} sm={4}>
              <div>
            <h2 className={classes.heading}>
            No Series found
                </h2>
            </div>
            </Grid>
            )}
        </Grid>

    </div>
    </>
}
    <footer>
        <Footer />
        </footer>
    </> ) : (    
    <div className='app'>        
    <div className={classes.rut}>        
      <Typography variant="h6" >
            Search for  
        <TextField 
          className="mb-4 w-full"
          placeholder="your favorite movies..."
//          variant='standard'
          onChange={(e) => setSearchedFor(e.target.value)}
          onKeyDown={(ev) => {
            if (ev.key === 'Enter') {
              setSearchValue(searchedFor)
            }
              }}
          type="text"
          style={{color: '#fff'}}
//          value={searchValue}
        />
        </Typography> 
        <footer style={{margin: -40}}>
        <Footer />
        </footer>
        </div>
        </div>
      )
}

<div>

<Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        style={{borderRadius: "20px"}}
        PaperComponent={Paper}
//        style={{color: '#222'}}
      >

        <Subscribe />
      </Dialog>

<Dialog
      open={trailerUrll==='' ? false: true}
      fullWidth
      maxWidth='md'
      onClose={()=> setTrailerUrll('')}
      aria-labelledby="max-width-dialog-title"
      style={{borderRadius: "20px"}}
      PaperComponent={Paper}
      >
      {  trailerUrll && 
  <>
  <Grid container justifyContent="center"
  alignItems="center"> 
    <iframe 
          src={`${trailerUrll}?showinfo=1&controls=1&autoplay=1`}
          title='Movie Trailer'
          frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          style={{
            width:'100%',
            height:'400px',
            borderRradius: '20px'
          }}/>
{/* <ReactPlayer
  // Disable download button
  config={{ file: { attributes: { controlsList: 'nodownload' } } }}

  // Disable right click
  onContextMenu={e => e.preventDefault()}

  // Your props
  url={trailerUrll}
  className="react-player"
  playing={true}
  controls
  width="100%"
  //height="100%"
/> */}
</Grid>
</>
}
      </Dialog>

   {//Movie  Description Dialog
      }
      <Dialog
fullWidth
maxWidth='md'
open={openDescription}
onClose={handleClose}
aria-labelledby="max-width-dialog-title"
style={{borderRadius: "20px"}}
PaperComponent={Paper}
//        style={{color: '#222'}}
>

<h2 style={{fontSize: '18px', color: '#fff'}}> {description} </h2>
</Dialog>


</div>
    </div>
    </div>
  )
}

export default withTheme(Search);
