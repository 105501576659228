import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import axios from '../../axios';
import './Row.css';

function Row({ title, fetchUrl, isLargeRow }) {
    const [movies, setMovies] = useState([]);


    useEffect(() => {
        //[] run once when the row loads and don't run again
        //[variable] run once when the row loads and everytime the variable changes 
        async function fetchData() {
            const config = {
                headers: {
                    'Access-Control-Allow-Origin' : '*',
                    'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
                    'Content-Type' : 'application/x-www-form-urlencoded'
                    }
            };
            const request = await axios.get(fetchUrl);
            console.log(request.data['all_genre']);
            setMovies(request.data);
            return request;
        } 
        fetchData();
    }, [fetchUrl]);

    //console.log(movies);
    
  return (
    <div className='row'>
        <h2 style={{fontSize: '35px'}}>{title}</h2>

        <div className='row__posters'>
            {/* several row__posters */}

            {movies.map((movie) => (
                <Link><img
                    className={`row__poster ${isLargeRow && "row__posterLarge"}`}
                    src={ isLargeRow ? movie.poster_url : movie.thumbnail_url}
                    alt={movie.title}
                    />
               <h2 style={{fontSize: '15px', color: '#fff'}}> {movie.title} </h2>
            </Link>
            ))}
        </div>
      </div>
  )
}

export default Row;
