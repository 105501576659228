import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { register } from "./RegistrationStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { 
  FormControl, 
  Input, 
  Grid, 
  InputLabel, 
  Button,
  Select,
  Typography,
  MenuItem } from "@material-ui/core";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import ErrorIcon from "@material-ui/icons/Error";
import VisibilityTwoToneIcon from "@material-ui/icons/VisibilityTwoTone";
import VisibilityOffTwoToneIcon from "@material-ui/icons/VisibilityOffTwoTone";
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from "@material-ui/icons/Close";
import logo from "../components/Assets/tv_logo_white.png";
import axios from '../axios';
import { 
  exchange,
  decryptWithAES,
  encryptWithAES,
  home
} from '../requests';
import { withTheme } from "../components/Theme/Theme";

const API_KEY = "oczkk67p7pyykt9xlk1eytde";

//const fetchUrl = `/home_content_for_android?API-KEY=${API_KEY}`;

const fetchUrl = `/home_content`;

//const exchange = 'http://api.kallo.ng:5000';
//const exchange = 'http://localhost:5000';

const useStyles = makeStyles(register);

function Registration() {
  const classes = useStyles();
  const history = useHistory();
  const [fullname, setFullname] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfrim, setPasswordConfrim] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [hidePassword, setHidePassword] = useState(true);
  const [error, setError] = useState(null);
  const [errorOpen, setErrorOpen] = useState(false);

  function redirect() {
    if ("token" in localStorage) {
    history.push('/')
    }
  };

/*   const CryptoJS = require('crypto-js');

const encryptWithAES = (text) => {
  const passphrase = 'kallo123';
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

const decryptWithAES = (ciphertext) => {
  const passphrase = 'kallo123';
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}; */

  useEffect(() => {
    //[] run once when the row loads and don't run again
    //[variable] run once when the row loads and everytime the variable changes 
    let axiosConfig = {
      headers: {
      'Content-Type': 'application/json',
      //"Authorization": `Bearer ${localStorage.getItem("token")}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": 'true',
      'Access-Control-Allow-Methods': '*'
      }
  };
    async function fetchData() {
        const request = await axios.get(fetchUrl, axiosConfig);
        //console.log(request);
        setCountryList(request.data.countries);
        return request;
    } 
    fetchData();
  }, [])

  const errorClose = e => {
    setErrorOpen(false);
  };


  const passwordMatch = () => password === passwordConfrim;

  const showPassword = () => {
    setHidePassword(!hidePassword)
  };

  const isValid = () => {
    if (email === "") {
      return false;
    }
    return true;
  };

  const signIn = e => {
    e.preventDefault();
    if (password !== '') {
/*       setErrorOpen(true);
      setError("Passwords Entered"); */
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Credentials", 'true');
      myHeaders.append("Access-Control-Allow-Methods", "*");

var raw = JSON.stringify({
  email: email,
  password: password

});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${exchange}/api/v1/users/authenticate`, requestOptions)
  .then(response => response.json())
  .then((data) => {
    if (data.message !== 'email or password is incorrect') {
    localStorage.setItem("token", data.token);
    localStorage.setItem("name", encryptWithAES(data.user.name));
    localStorage.setItem("email", data.user.email);
    localStorage.setItem("join_date", data.user.join_date);
    localStorage.setItem("last_login", data.user.last_login);
    history.push('/');
//    console.log('name', data.user.name)
    }
    else {
      alert(data.message);
      history.push('/sign-in');
    }
  })
  .then(redirect())
  .catch(error => console.log('error', error));
    }
  };

  const submitRegistration = e => {
    e.preventDefault();
    if (!passwordMatch()) {
      setErrorOpen(true);
      setError("Passwords don't match");
    }
    else {
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Credentials", 'true');
      myHeaders.append("Access-Control-Allow-Methods", "*");
      
      var raw = JSON.stringify({
        name: fullname,
        email: email,
        password: password,
        country: country
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      var raw_phone = JSON.stringify({
        phone: phone,
/*         firstname: firstname,
        lastname: lastname,
 */        password: password
      });
      
      var phoneRequestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw_phone,
        redirect: 'follow'
      };
      
      //this fetch handles user registration
      fetch(`${exchange}/api/v1/users/update`, requestOptions)
        .then(response => response.json())
        .then((data) => {
          if (data.message !== "Registration successful") {
          setErrorOpen(true);
          setError(data.message);
          //alert(data.message)
        }
        else {
          setErrorOpen(true);
          //if registration was successful, then sign in
          history.push('/user/profile');
          signIn();
        }
      })
        .catch(error => console.log('error', error));
}
/*     const newUserCredentials = {
      email: email,
      password: password,
      passwordConfrim: passwordConfrim
    };
    console.log(newUserCredentials); */
    //dispath to userActions
  };

    return (
      <div className={classes.main}>
        <CssBaseline />

        <Paper className={classes.paper}>
        <Button
              disableRipple
//              fullWidth
              variant="contained"
//              className={classes.button}
//              type="submit"
//              onClick={submitRegistration}
  //            style={{color: '#fff'}}
              color='primary'
            >
                <Typography variant="h6" display="block" gutterBottom noWrap>
              Update Profile || Sabunta bayanin martaba
              </Typography>
              </Button>
<Grid container align='center'>
<Grid item xs > 

          </Grid>
<Grid item xs> 

          </Grid>
</Grid>
<Grid container> 
<Grid item xs>
  {''}
</Grid>
</Grid>
          <form
            className={classes.form}
            onSubmit={submitRegistration}
          >
            <Grid container align='center'>
            <Grid item xs={12} sm={6} > 
            <FormControl required fullWidth margin="normal">
              <InputLabel className={classes.labels}>
                Fullname
              </InputLabel>
              <Input
                name="fullname"
                type="fullname"
                autoComplete="fullname"
                className={classes.inputs}
                disableUnderline={true}
                onChange={(e) => setFullname(e.target.value)}
              />
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} > 
                        <FormControl required fullWidth margin="normal">
              <InputLabel className={classes.labels}>
                Country
              </InputLabel>
              <Select
                name="country"
                validators={["required"]}
                className={classes.inputs}
                disableUnderline={true}
                onChange={(e) => setCountry(e.target.value)}
              >
              <MenuItem value=""><em>Select Country</em></MenuItem>
                {countryList?.map(option => {
                  return (
                    <MenuItem key={option.value} value={option.name}>
                      {option.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            </Grid>
          <Grid item xs={12} sm={6} > 
            <FormControl required fullWidth margin="normal">
              <InputLabel className={classes.labels}>
                Phone Number
              </InputLabel>
              <Input
                name="phonenumber"
                type="tel"
//                autoComplete="email"
                className={classes.inputs}
                disableUnderline={true}
                onChange={(e) => setPhone(e.target.value)}
              />
            </FormControl>
          </Grid>

            <Grid item xs={12} sm={6} > 
                        <FormControl required fullWidth margin="normal">
              <InputLabel htmlFor="email" className={classes.labels}>
                Email
              </InputLabel>
              <Input
                name="email"
                type="email"
                autoComplete="email"
                value={localStorage.getItem("email")}
                disabled
                className={classes.inputs}
                disableUnderline={true}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} > 
            <FormControl required fullWidth margin="normal">
              <InputLabel htmlFor="password" className={classes.labels}>
                Password
              </InputLabel>
              <Input
                name="password"
                autoComplete="password"
                className={classes.inputs}
                disableUnderline={true}
                //disabled
                onChange={(e) => setPassword(e.target.value)}
                type={hidePassword ? "password" : "input"}
                endAdornment={
                  hidePassword ? (
                    <InputAdornment position="end">
                      <VisibilityOffTwoToneIcon
                        fontSize="default"
                        className={classes.passwordEye}
                        onClick={showPassword}
                      />
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <VisibilityTwoToneIcon
                        fontSize="default"
                        className={classes.passwordEye}
                        onClick={showPassword}
                      />
                    </InputAdornment>
                  )
                }
              />
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} > 
            <FormControl required fullWidth margin="normal">
              <InputLabel htmlFor="passwordConfrim" className={classes.labels}>
                Confrim password
              </InputLabel>
              <Input
                name="passwordConfrim"
                autoComplete="passwordConfrim"
                //disabled
                className={classes.inputs}
                disableUnderline={true}
//                onClick={showPassword}
                onChange={(e) => setPasswordConfrim(e.target.value)}
                type={hidePassword ? "password" : "input"}
                endAdornment={
                  (!passwordMatch()) ? 
                  (<InputAdornment position="end">

                    <CancelIcon
                    style={{ color: "#ff1203" }} />

                </InputAdornment>) :
                (
                  <InputAdornment position="end">
                    {(passwordConfrim === "") ? (
                      <CancelIcon
                        style={{ color: "#ffffff", opacity: 0 }} />
                      ) : (
                      <CheckCircleIcon
                        style={{ color: "#12ff03" }} />
                      )
                    }

                  </InputAdornment>
                )
                }
              />
            </FormControl>
            </Grid>
            </Grid> 
            <Button
//              disabled={!passwordMatch() || passwordConfrim ===''}
//              disabled={!isValid()}
              disableRipple
//              fullWidth
              variant="outlined"
              className={classes.button}
              type="submit"
//              onClick={submitRegistration}
              style={{color: '#fff'}}
            >
              Register || sabunta
            </Button>
          </form>

          {error ? (
            <Snackbar
              variant="error"
              key={error}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={errorOpen}
              onClose={errorClose}
              autoHideDuration={5000}
            >
              <SnackbarContent
                className={classes.error}
                message={
                  <div>
                    <span style={{ marginRight: "8px" }}>
                      <ErrorIcon fontSize="large" color="error" />
                    </span>
                    <span> {error} </span>
                  </div>
                }
                action={[
                  <IconButton
                    key="close"
                    aria-label="close"
                    onClick={errorClose}
                  >
                    <CloseIcon color="error" />
                  </IconButton>
                ]}
              />
            </Snackbar>
          ) : (            
          <Snackbar
            variant="success"
            key={error}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
            open={errorOpen}
            onClose={errorClose}
            autoHideDuration={5000}
          >
            <SnackbarContent
              className={classes.success}
              message={
                <div>
                  <span style={{ marginRight: "8px" }}>
                    <ErrorIcon fontSize="large" color="success" />
                  </span>
                  <span> Registration Successful </span>
                </div>
              }
              action={[
                <IconButton
                  key="close"
                  aria-label="close"
                  onClick={errorClose}
                >
                  <CloseIcon color="error" />
                </IconButton>
              ]}
            />
          </Snackbar>)}
        </Paper>
      </div>
    );
}

export default withTheme(Registration);