const API_KEY = "oczkk67p7pyykt9xlk1eytde";

/* const requests = {
    fetchHomePageDisplay: `/home_content_for_android?API-KEY=${API_KEY}`,
    fetchMovieSuggestion: `/movies?API-KEY=${API_KEY}`,
    fetchLatestMovies: ``,
    fetchLatestSeries: ``,
    fetchPopularMovies: ``,
    fetchActionMovies: `/home_content_for_android?API-KEY=${API_KEY}`,
    fectchComedyMovies: `/home_content_for_android?API-KEY=${API_KEY}`,
    fetchThrillerMovies: `/home_content_for_android?API-KEY=${API_KEY}`
} */

const requests = {
    fetchHomePageDisplay: `/home_content`,
    fetchMovieSuggestion: `/movies?API-KEY=${API_KEY}`,
    fetchLatestMovies: ``,
    fetchLatestSeries: ``,
    fetchPopularMovies: ``,
    fetchActionMovies: `/home_content`,
    fectchComedyMovies: `/home_content`,
    fetchThrillerMovies: `/home_content`
}

export default requests;

//const API_URL = 'http://api.kallo.ng:5000';

const API_URL = 'https://api.kallo.ng';

const cors_ = 'https://corsanywhere.herokuapp.com';

//export const exchange = `${cors_}/${API_URL}`;

//export const exchange = 'https://corsanywhere.herokuapp.com/http://api.kallo.ng:5000';

export const exchange = 'https://api.kallo.ng';
//export const exchange = 'http://api.kallo.ng:5000';

export const home ='http://kallo-ng.herokuapp.com/search';

const CryptoJS = require('crypto-js');

export const encryptWithAES = (text) => {
  const passphrase = 'kallo123';
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

export const decryptWithAES = (ciphertext) => {
  const passphrase = 'kallo123';
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

export const countries = [
  {id:3, code:"AF", title:"Afghanistan", country_fullname:"Islamic Republic of Afghanistan", iso3:"AFG", iso_number:4, continent:"AS", continentid:3 },
  {id:16, code:"AX", title:"Åland", country_fullname:"Åland Islands", iso3:"ALA", iso_number:248, continent:"EU", continentid:4 },
  {id:6, code:"AL", title:"Albania", country_fullname:"Republic of Albania", iso3:"ALB", iso_number:8, continent:"EU", continentid:4 },
  {id:61, code:"DZ", title:"Algeria", country_fullname:"People's Democratic Republic of Algeria", iso3:"DZA", iso_number:12, continent:"AF", continentid:1 },
  {id:12, code:"AS", title:"American Samoa", country_fullname:"American Samoa", iso3:"ASM", iso_number:16, continent:"OC", continentid:6 },
  {id:1, code:"AD", title:"Andorra", country_fullname:"Principality of Andorra", iso3:"AND", iso_number:20, continent:"EU", continentid:4 },
  {id:9, code:"AO", title:"Angola", country_fullname:"Republic of Angola", iso3:"AGO", iso_number:24, continent:"AF", continentid:1 },
  {id:5, code:"AI", title:"Anguilla", country_fullname:"Anguilla", iso3:"AIA", iso_number:660, continent:"NA", continentid:5 },
  {id:10, code:"AQ", title:"Antarctica", country_fullname:"Antarctica (the territory South of 60 deg S)", iso3:"ATA", iso_number:10, continent:"AN", continentid:2 },
  {id:4, code:"AG", title:"Antigua and Barbuda", country_fullname:"Antigua and Barbuda", iso3:"ATG", iso_number:28, continent:"NA", continentid:5 },
  {id:11, code:"AR", title:"Argentina", country_fullname:"Argentine Republic", iso3:"ARG", iso_number:32, continent:"SA", continentid:7 },
  {id:7, code:"AM", title:"Armenia", country_fullname:"Republic of Armenia", iso3:"ARM", iso_number:51, continent:"AS", continentid:3 },
  {id:15, code:"AW", title:"Aruba", country_fullname:"Aruba", iso3:"ABW", iso_number:533, continent:"NA", continentid:5 },
  {id:14, code:"AU", title:"Australia", country_fullname:"Commonwealth of Australia", iso3:"AUS", iso_number:36, continent:"OC", continentid:6 },
  {id:13, code:"AT", title:"Austria", country_fullname:"Republic of Austria", iso3:"AUT", iso_number:40, continent:"EU", continentid:4 },
  {id:17, code:"AZ", title:"Azerbaijan", country_fullname:"Republic of Azerbaijan", iso3:"AZE", iso_number:31, continent:"AS", continentid:3 },
  {id:32, code:"BS", title:"Bahamas", country_fullname:"Commonwealth of the Bahamas", iso3:"BHS", iso_number:44, continent:"NA", continentid:5 },
  {id:24, code:"BH", title:"Bahrain", country_fullname:"Kingdom of Bahrain", iso3:"BHR", iso_number:48, continent:"AS", continentid:3 },
  {id:20, code:"BD", title:"Bangladesh", country_fullname:"People's Republic of Bangladesh", iso3:"BGD", iso_number:50, continent:"AS", continentid:3 },
  {id:19, code:"BB", title:"Barbados", country_fullname:"Barbados", iso3:"BRB", iso_number:52, continent:"NA", continentid:5 },
  {id:36, code:"BY", title:"Belarus", country_fullname:"Republic of Belarus", iso3:"BLR", iso_number:112, continent:"EU", continentid:4 },
  {id:21, code:"BE", title:"Belgium", country_fullname:"Kingdom of Belgium", iso3:"BEL", iso_number:56, continent:"EU", continentid:4 },
  {id:37, code:"BZ", title:"Belize", country_fullname:"Belize", iso3:"BLZ", iso_number:84, continent:"NA", continentid:5 },
  {id:26, code:"BJ", title:"Benin", country_fullname:"Republic of Benin", iso3:"BEN", iso_number:204, continent:"AF", continentid:1 },
  {id:28, code:"BM", title:"Bermuda", country_fullname:"Bermuda", iso3:"BMU", iso_number:60, continent:"NA", continentid:5 },
  {id:33, code:"BT", title:"Bhutan", country_fullname:"Kingdom of Bhutan", iso3:"BTN", iso_number:64, continent:"AS", continentid:3 },
  {id:30, code:"BO", title:"Bolivia", country_fullname:"Republic of Bolivia", iso3:"BOL", iso_number:68, continent:"SA", continentid:7 },
  {id:247, code:"BQ", title:"Bonaire", country_fullname:"Bonaire, Sint Eustatius and Saba", iso3:"BES", iso_number:535, continent:"NA", continentid:5 },
  {id:18, code:"BA", title:"Bosnia and Herzegovina", country_fullname:"Bosnia and Herzegovina", iso3:"BIH", iso_number:70, continent:"EU", continentid:4 },
  {id:35, code:"BW", title:"Botswana", country_fullname:"Republic of Botswana", iso3:"BWA", iso_number:72, continent:"AF", continentid:1 },
  {id:34, code:"BV", title:"Bouvet Island", country_fullname:"Bouvet Island (Bouvetoya)", iso3:"BVT", iso_number:74, continent:"AN", continentid:2 },
  {id:31, code:"BR", title:"Brazil", country_fullname:"Federative Republic of Brazil", iso3:"BRA", iso_number:76, continent:"SA", continentid:7 },
  {id:105, code:"IO", title:"British Indian Ocean Territory", country_fullname:"British Indian Ocean Territory (Chagos Archipelago)", iso3:"IOT", iso_number:86, continent:"AS", continentid:3 },
  {id:29, code:"BN", title:"Brunei Darussalam", country_fullname:"Brunei Darussalam", iso3:"BRN", iso_number:96, continent:"AS", continentid:3 },
  {id:23, code:"BG", title:"Bulgaria", country_fullname:"Republic of Bulgaria", iso3:"BGR", iso_number:100, continent:"EU", continentid:4 },
  {id:22, code:"BF", title:"Burkina Faso", country_fullname:"Burkina Faso", iso3:"BFA", iso_number:854, continent:"AF", continentid:1 },
  {id:25, code:"BI", title:"Burundi", country_fullname:"Republic of Burundi", iso3:"BDI", iso_number:108, continent:"AF", continentid:1 },
  {id:116, code:"KH", title:"Cambodia", country_fullname:"Kingdom of Cambodia", iso3:"KHM", iso_number:116, continent:"AS", continentid:3 },
  {id:47, code:"CM", title:"Cameroon", country_fullname:"Republic of Cameroon", iso3:"CMR", iso_number:120, continent:"AF", continentid:1 },
  {id:38, code:"CA", title:"Canada", country_fullname:"Canada", iso3:"CAN", iso_number:124, continent:"NA", continentid:5 },
  {id:52, code:"CV", title:"Cape Verde", country_fullname:"Republic of Cape Verde", iso3:"CPV", iso_number:132, continent:"AF", continentid:1 },
  {id:123, code:"KY", title:"Cayman Islands", country_fullname:"Cayman Islands", iso3:"CYM", iso_number:136, continent:"NA", continentid:5 },
  {id:41, code:"CF", title:"Central African Republic", country_fullname:"Central African Republic", iso3:"CAF", iso_number:140, continent:"AF", continentid:1 },
  {id:212, code:"TD", title:"Chad", country_fullname:"Republic of Chad", iso3:"TCD", iso_number:148, continent:"AF", continentid:1 },
  {id:46, code:"CL", title:"Chile", country_fullname:"Republic of Chile", iso3:"CHL", iso_number:152, continent:"SA", continentid:7 },
  {id:48, code:"CN", title:"China", country_fullname:"People's Republic of China", iso3:"CHN", iso_number:156, continent:"AS", continentid:3 },
  {id:53, code:"CX", title:"Christmas Island", country_fullname:"Christmas Island", iso3:"CXR", iso_number:162, continent:"AS", continentid:3 },
  {id:39, code:"CC", title:"Cocos (Keeling) Islands", country_fullname:"Cocos (Keeling) Islands", iso3:"CCK", iso_number:166, continent:"AS", continentid:3 },
  {id:49, code:"CO", title:"Colombia", country_fullname:"Republic of Colombia", iso3:"COL", iso_number:170, continent:"SA", continentid:7 },
  {id:118, code:"KM", title:"Comoros", country_fullname:"Union of the Comoros", iso3:"COM", iso_number:174, continent:"AF", continentid:1 },
  {id:42, code:"CG", title:"Congo (Brazzaville)", country_fullname:"Republic of the Congo", iso3:"COG", iso_number:178, continent:"AF", continentid:1 },
  {id:40, code:"CD", title:"Congo (Kinshasa)", country_fullname:"Democratic Republic of the Congo", iso3:"COD", iso_number:180, continent:"AF", continentid:1 },
  {id:45, code:"CK", title:"Cook Islands", country_fullname:"Cook Islands", iso3:"COK", iso_number:184, continent:"OC", continentid:6 },
  {id:50, code:"CR", title:"Costa Rica", country_fullname:"Republic of Costa Rica", iso3:"CRI", iso_number:188, continent:"NA", continentid:5 },
  {id:44, code:"CI", title:"Côte d'Ivoire", country_fullname:"Republic of Cote d'Ivoire", iso3:"CIV", iso_number:384, continent:"AF", continentid:1 },
  {id:97, code:"HR", title:"Croatia", country_fullname:"Republic of Croatia", iso3:"HRV", iso_number:191, continent:"EU", continentid:4 },
  {id:51, code:"CU", title:"Cuba", country_fullname:"Republic of Cuba", iso3:"CUB", iso_number:192, continent:"NA", continentid:5 },
  {id:248, code:"CW", title:"Curaçao", country_fullname:"Curaçao", iso3:"CUW", iso_number:531, continent:"NA", continentid:5 },
  {id:54, code:"CY", title:"Cyprus", country_fullname:"Republic of Cyprus", iso3:"CYP", iso_number:196, continent:"AS", continentid:3 },
  {id:55, code:"CZ", title:"Czech Republic", country_fullname:"Czech Republic", iso3:"CZE", iso_number:203, continent:"EU", continentid:4 },
  {id:58, code:"DK", title:"Denmark", country_fullname:"Kingdom of Denmark", iso3:"DNK", iso_number:208, continent:"EU", continentid:4 },
  {id:57, code:"DJ", title:"Djibouti", country_fullname:"Republic of Djibouti", iso3:"DJI", iso_number:262, continent:"AF", continentid:1 },
  {id:59, code:"DM", title:"Dominica", country_fullname:"Commonwealth of Dominica", iso3:"DMA", iso_number:212, continent:"NA", continentid:5 },
  {id:60, code:"DO", title:"Dominican Republic", country_fullname:"Dominican Republic", iso3:"DOM", iso_number:214, continent:"NA", continentid:5 },
  {id:62, code:"EC", title:"Ecuador", country_fullname:"Republic of Ecuador", iso3:"ECU", iso_number:218, continent:"SA", continentid:7 },
  {id:64, code:"EG", title:"Egypt", country_fullname:"Arab Republic of Egypt", iso3:"EGY", iso_number:818, continent:"AF", continentid:1 },
  {id:208, code:"SV", title:"El Salvador", country_fullname:"Republic of El Salvador", iso3:"SLV", iso_number:222, continent:"NA", continentid:5 },
  {id:87, code:"GQ", title:"Equatorial Guinea", country_fullname:"Republic of Equatorial Guinea", iso3:"GNQ", iso_number:226, continent:"AF", continentid:1 },
  {id:66, code:"ER", title:"Eritrea", country_fullname:"State of Eritrea", iso3:"ERI", iso_number:232, continent:"AF", continentid:1 },
  {id:63, code:"EE", title:"Estonia", country_fullname:"Republic of Estonia", iso3:"EST", iso_number:233, continent:"EU", continentid:4 },
  {id:68, code:"ET", title:"Ethiopia", country_fullname:"Federal Democratic Republic of Ethiopia", iso3:"ETH", iso_number:231, continent:"AF", continentid:1 },
  {id:71, code:"FK", title:"Falkland Islands", country_fullname:"Falkland Islands (Malvinas)", iso3:"FLK", iso_number:238, continent:"SA", continentid:7 },
  {id:73, code:"FO", title:"Faroe Islands", country_fullname:"Faroe Islands", iso3:"FRO", iso_number:234, continent:"EU", continentid:4 },
  {id:70, code:"FJ", title:"Fiji", country_fullname:"Republic of the Fiji Islands", iso3:"FJI", iso_number:242, continent:"OC", continentid:6 },
  {id:69, code:"FI", title:"Finland", country_fullname:"Republic of Finland", iso3:"FIN", iso_number:246, continent:"EU", continentid:4 },
  {id:74, code:"FR", title:"France", country_fullname:"French Republic", iso3:"FRA", iso_number:250, continent:"EU", continentid:4 },
  {id:79, code:"GF", title:"French Guiana", country_fullname:"French Guiana", iso3:"GUF", iso_number:254, continent:"SA", continentid:7 },
  {id:174, code:"PF", title:"French Polynesia", country_fullname:"French Polynesia", iso3:"PYF", iso_number:258, continent:"OC", continentid:6 },
  {id:213, code:"TF", title:"French Southern Lands", country_fullname:"French Southern Territories", iso3:"ATF", iso_number:260, continent:"AN", continentid:2 },
  {id:75, code:"GA", title:"Gabon", country_fullname:"Gabonese Republic", iso3:"GAB", iso_number:266, continent:"AF", continentid:1 },
  {id:84, code:"GM", title:"Gambia", country_fullname:"Republic of the Gambia", iso3:"GMB", iso_number:270, continent:"AF", continentid:1 },
  {id:78, code:"GE", title:"Georgia", country_fullname:"Georgia", iso3:"GEO", iso_number:268, continent:"AS", continentid:3 },
  {id:56, code:"DE", title:"Germany", country_fullname:"Federal Republic of Germany", iso3:"DEU", iso_number:276, continent:"EU", continentid:4 },
  {id:81, code:"GH", title:"Ghana", country_fullname:"Republic of Ghana", iso3:"GHA", iso_number:288, continent:"AF", continentid:1 },
  {id:82, code:"GI", title:"Gibraltar", country_fullname:"Gibraltar", iso3:"GIB", iso_number:292, continent:"EU", continentid:4 },
  {id:88, code:"GR", title:"Greece", country_fullname:"Hellenic Republic Greece", iso3:"GRC", iso_number:300, continent:"EU", continentid:4 },
  {id:83, code:"GL", title:"Greenland", country_fullname:"Greenland", iso3:"GRL", iso_number:304, continent:"NA", continentid:5 },
  {id:77, code:"GD", title:"Grenada", country_fullname:"Grenada", iso3:"GRD", iso_number:308, continent:"NA", continentid:5 },
  {id:86, code:"GP", title:"Guadeloupe", country_fullname:"Guadeloupe", iso3:"GLP", iso_number:312, continent:"NA", continentid:5 },
  {id:91, code:"GU", title:"Guam", country_fullname:"Guam", iso3:"GUM", iso_number:316, continent:"OC", continentid:6 },
  {id:90, code:"GT", title:"Guatemala", country_fullname:"Republic of Guatemala", iso3:"GTM", iso_number:320, continent:"NA", continentid:5 },
  {id:80, code:"GG", title:"Guernsey", country_fullname:"Bailiwick of Guernsey", iso3:"GGY", iso_number:831, continent:"EU", continentid:4 },
  {id:85, code:"GN", title:"Guinea", country_fullname:"Republic of Guinea", iso3:"GIN", iso_number:324, continent:"AF", continentid:1 },
  {id:92, code:"GW", title:"Guinea-Bissau", country_fullname:"Republic of Guinea-Bissau", iso3:"GNB", iso_number:624, continent:"AF", continentid:1 },
  {id:93, code:"GY", title:"Guyana", country_fullname:"Co-operative Republic of Guyana", iso3:"GUY", iso_number:328, continent:"SA", continentid:7 },
  {id:98, code:"HT", title:"Haiti", country_fullname:"Republic of Haiti", iso3:"HTI", iso_number:332, continent:"NA", continentid:5 },
  {id:95, code:"HM", title:"Heard and McDonald Islands", country_fullname:"Heard Island and McDonald Islands", iso3:"HMD", iso_number:334, continent:"AN", continentid:2 },
  {id:96, code:"HN", title:"Honduras", country_fullname:"Republic of Honduras", iso3:"HND", iso_number:340, continent:"NA", continentid:5 },
  {id:94, code:"HK", title:"Hong Kong", country_fullname:"Hong Kong Special Administrative Region of China", iso3:"HKG", iso_number:344, continent:"AS", continentid:3 },
  {id:99, code:"HU", title:"Hungary", country_fullname:"Republic of Hungary", iso3:"HUN", iso_number:348, continent:"EU", continentid:4 },
  {id:108, code:"IS", title:"Iceland", country_fullname:"Republic of Iceland", iso3:"ISL", iso_number:352, continent:"EU", continentid:4 },
  {id:104, code:"IN", title:"India", country_fullname:"Republic of India", iso3:"IND", iso_number:356, continent:"AS", continentid:3 },
  {id:100, code:"ID", title:"Indonesia", country_fullname:"Republic of Indonesia", iso3:"IDN", iso_number:360, continent:"AS", continentid:3 },
  {id:107, code:"IR", title:"Iran", country_fullname:"Islamic Republic of Iran", iso3:"IRN", iso_number:364, continent:"AS", continentid:3 },
  {id:106, code:"IQ", title:"Iraq", country_fullname:"Republic of Iraq", iso3:"IRQ", iso_number:368, continent:"AS", continentid:3 },
  {id:101, code:"IE", title:"Ireland", country_fullname:"Ireland", iso3:"IRL", iso_number:372, continent:"EU", continentid:4 },
  {id:103, code:"IM", title:"Isle of Man", country_fullname:"Isle of Man", iso3:"IMN", iso_number:833, continent:"EU", continentid:4 },
  {id:102, code:"IL", title:"Israel", country_fullname:"State of Israel", iso3:"ISR", iso_number:376, continent:"AS", continentid:3 },
  {id:109, code:"IT", title:"Italy", country_fullname:"Italian Republic", iso3:"ITA", iso_number:380, continent:"EU", continentid:4 },
  {id:111, code:"JM", title:"Jamaica", country_fullname:"Jamaica", iso3:"JAM", iso_number:388, continent:"NA", continentid:5 },
  {id:113, code:"JP", title:"Japan", country_fullname:"Japan", iso3:"JPN", iso_number:392, continent:"AS", continentid:3 },
  {id:110, code:"JE", title:"Jersey", country_fullname:"Bailiwick of Jersey", iso3:"JEY", iso_number:832, continent:"EU", continentid:4 },
  {id:112, code:"JO", title:"Jordan", country_fullname:"Hashemite Kingdom of Jordan", iso3:"JOR", iso_number:400, continent:"AS", continentid:3 },
  {id:124, code:"KZ", title:"Kazakhstan", country_fullname:"Republic of Kazakhstan", iso3:"KAZ", iso_number:398, continent:"AS", continentid:3 },
  {id:114, code:"KE", title:"Kenya", country_fullname:"Republic of Kenya", iso3:"KEN", iso_number:404, continent:"AF", continentid:1 },
  {id:117, code:"KI", title:"Kiribati", country_fullname:"Republic of Kiribati", iso3:"KIR", iso_number:296, continent:"OC", continentid:6 },
  {id:120, code:"KP", title:"Korea, North", country_fullname:"Democratic People's Republic of Korea", iso3:"PRK", iso_number:408, continent:"AS", continentid:3 },
  {id:121, code:"KR", title:"Korea, South", country_fullname:"Republic of Korea", iso3:"KOR", iso_number:410, continent:"AS", continentid:3 },
  {id:251, code:"XK", title:"Kosovo", country_fullname:"Kosovo", iso3:"   ", iso_number:0, continent:"EU", continentid:4 },
  {id:122, code:"KW", title:"Kuwait", country_fullname:"State of Kuwait", iso3:"KWT", iso_number:414, continent:"AS", continentid:3 },
  {id:115, code:"KG", title:"Kyrgyzstan", country_fullname:"Kyrgyz Republic", iso3:"KGZ", iso_number:417, continent:"AS", continentid:3 },
  {id:125, code:"LA", title:"Laos", country_fullname:"Lao People's Democratic Republic", iso3:"LAO", iso_number:418, continent:"AS", continentid:3 },
  {id:134, code:"LV", title:"Latvia", country_fullname:"Republic of Latvia", iso3:"LVA", iso_number:428, continent:"EU", continentid:4 },
  {id:126, code:"LB", title:"Lebanon", country_fullname:"Lebanese Republic", iso3:"LBN", iso_number:422, continent:"AS", continentid:3 },
  {id:131, code:"LS", title:"Lesotho", country_fullname:"Kingdom of Lesotho", iso3:"LSO", iso_number:426, continent:"AF", continentid:1 },
  {id:130, code:"LR", title:"Liberia", country_fullname:"Republic of Liberia", iso3:"LBR", iso_number:430, continent:"AF", continentid:1 },
  {id:135, code:"LY", title:"Libya", country_fullname:"Libyan Arab Jamahiriya", iso3:"LBY", iso_number:434, continent:"AF", continentid:1 },
  {id:128, code:"LI", title:"Liechtenstein", country_fullname:"Principality of Liechtenstein", iso3:"LIE", iso_number:438, continent:"EU", continentid:4 },
  {id:132, code:"LT", title:"Lithuania", country_fullname:"Republic of Lithuania", iso3:"LTU", iso_number:440, continent:"EU", continentid:4 },
  {id:133, code:"LU", title:"Luxembourg", country_fullname:"Grand Duchy of Luxembourg", iso3:"LUX", iso_number:442, continent:"EU", continentid:4 },
  {id:147, code:"MO", title:"Macau", country_fullname:"Macao Special Administrative Region of China", iso3:"MAC", iso_number:446, continent:"AS", continentid:3 },
  {id:143, code:"MK", title:"Macedonia", country_fullname:"Republic of Macedonia", iso3:"MKD", iso_number:807, continent:"EU", continentid:4 },
  {id:141, code:"MG", title:"Madagascar", country_fullname:"Republic of Madagascar", iso3:"MDG", iso_number:450, continent:"AF", continentid:1 },
  {id:155, code:"MW", title:"Malawi", country_fullname:"Republic of Malawi", iso3:"MWI", iso_number:454, continent:"AF", continentid:1 },
  {id:157, code:"MY", title:"Malaysia", country_fullname:"Malaysia", iso3:"MYS", iso_number:458, continent:"AS", continentid:3 },
  {id:154, code:"MV", title:"Maldives", country_fullname:"Republic of Maldives", iso3:"MDV", iso_number:462, continent:"AS", continentid:3 },
  {id:144, code:"ML", title:"Mali", country_fullname:"Republic of Mali", iso3:"MLI", iso_number:466, continent:"AF", continentid:1 },
  {id:152, code:"MT", title:"Malta", country_fullname:"Republic of Malta", iso3:"MLT", iso_number:470, continent:"EU", continentid:4 },
  {id:142, code:"MH", title:"Marshall Islands", country_fullname:"Republic of the Marshall Islands", iso3:"MHL", iso_number:584, continent:"OC", continentid:6 },
  {id:149, code:"MQ", title:"Martinique", country_fullname:"Martinique", iso3:"MTQ", iso_number:474, continent:"NA", continentid:5 },
  {id:150, code:"MR", title:"Mauritania", country_fullname:"Islamic Republic of Mauritania", iso3:"MRT", iso_number:478, continent:"AF", continentid:1 },
  {id:153, code:"MU", title:"Mauritius", country_fullname:"Republic of Mauritius", iso3:"MUS", iso_number:480, continent:"AF", continentid:1 },
  {id:243, code:"YT", title:"Mayotte", country_fullname:"Mayotte", iso3:"MYT", iso_number:175, continent:"AF", continentid:1 },
  {id:156, code:"MX", title:"Mexico", country_fullname:"United Mexican States", iso3:"MEX", iso_number:484, continent:"NA", continentid:5 },
  {id:72, code:"FM", title:"Micronesia", country_fullname:"Federated States of Micronesia", iso3:"FSM", iso_number:583, continent:"OC", continentid:6 },
  {id:138, code:"MD", title:"Moldova", country_fullname:"Republic of Moldova", iso3:"MDA", iso_number:498, continent:"EU", continentid:4 },
  {id:137, code:"MC", title:"Monaco", country_fullname:"Principality of Monaco", iso3:"MCO", iso_number:492, continent:"EU", continentid:4 },
  {id:146, code:"MN", title:"Mongolia", country_fullname:"Mongolia", iso3:"MNG", iso_number:496, continent:"AS", continentid:3 },
  {id:139, code:"ME", title:"Montenegro", country_fullname:"Republic of Montenegro", iso3:"MNE", iso_number:499, continent:"EU", continentid:4 },
  {id:151, code:"MS", title:"Montserrat", country_fullname:"Montserrat", iso3:"MSR", iso_number:500, continent:"NA", continentid:5 },
  {id:136, code:"MA", title:"Morocco", country_fullname:"Kingdom of Morocco", iso3:"MAR", iso_number:504, continent:"AF", continentid:1 },
  {id:158, code:"MZ", title:"Mozambique", country_fullname:"Republic of Mozambique", iso3:"MOZ", iso_number:508, continent:"AF", continentid:1 },
  {id:145, code:"MM", title:"Myanmar", country_fullname:"Union of Myanmar", iso3:"MMR", iso_number:104, continent:"AS", continentid:3 },
  {id:159, code:"NA", title:"Namibia", country_fullname:"Republic of Namibia", iso3:"NAM", iso_number:516, continent:"AF", continentid:1 },
  {id:168, code:"NR", title:"Nauru", country_fullname:"Republic of Nauru", iso3:"NRU", iso_number:520, continent:"OC", continentid:6 },
  {id:167, code:"NP", title:"Nepal", country_fullname:"State of Nepal", iso3:"NPL", iso_number:524, continent:"AS", continentid:3 },
  {id:165, code:"NL", title:"Netherlands", country_fullname:"Kingdom of the Netherlands", iso3:"NLD", iso_number:528, continent:"EU", continentid:4 },
  {id:8, code:"AN", title:"Netherlands Antilles", country_fullname:"Netherlands Antilles", iso3:"ANT", iso_number:530, continent:"NA", continentid:5 },
  {id:160, code:"NC", title:"New Caledonia", country_fullname:"New Caledonia", iso3:"NCL", iso_number:540, continent:"OC", continentid:6 },
  {id:170, code:"NZ", title:"New Zealand", country_fullname:"New Zealand", iso3:"NZL", iso_number:554, continent:"OC", continentid:6 },
  {id:164, code:"NI", title:"Nicaragua", country_fullname:"Republic of Nicaragua", iso3:"NIC", iso_number:558, continent:"NA", continentid:5 },
  {id:161, code:"NE", title:"Niger", country_fullname:"Republic of Niger", iso3:"NER", iso_number:562, continent:"AF", continentid:1 },
  {id:163, code:"NG", title:"Nigeria", country_fullname:"Federal Republic of Nigeria", iso3:"NGA", iso_number:566, continent:"AF", continentid:1 },
  {id:169, code:"NU", title:"Niue", country_fullname:"Niue", iso3:"NIU", iso_number:570, continent:"OC", continentid:6 },
  {id:162, code:"NF", title:"Norfolk Island", country_fullname:"Norfolk Island", iso3:"NFK", iso_number:574, continent:"OC", continentid:6 },
  {id:148, code:"MP", title:"Northern Mariana Islands", country_fullname:"Commonwealth of the Northern Mariana Islands", iso3:"MNP", iso_number:580, continent:"OC", continentid:6 },
  {id:166, code:"NO", title:"Norway", country_fullname:"Kingdom of Norway", iso3:"NOR", iso_number:578, continent:"EU", continentid:4 },
  {id:171, code:"OM", title:"Oman", country_fullname:"Sultanate of Oman", iso3:"OMN", iso_number:512, continent:"AS", continentid:3 },
  {id:177, code:"PK", title:"Pakistan", country_fullname:"Islamic Republic of Pakistan", iso3:"PAK", iso_number:586, continent:"AS", continentid:3 },
  {id:184, code:"PW", title:"Palau", country_fullname:"Republic of Palau", iso3:"PLW", iso_number:585, continent:"OC", continentid:6 },
  {id:182, code:"PS", title:"Palestine", country_fullname:"Occupied Palestinian Territory", iso3:"PSE", iso_number:275, continent:"AS", continentid:3 },
  {id:172, code:"PA", title:"Panama", country_fullname:"Republic of Panama", iso3:"PAN", iso_number:591, continent:"NA", continentid:5 },
  {id:175, code:"PG", title:"Papua New Guinea", country_fullname:"Independent State of Papua New Guinea", iso3:"PNG", iso_number:598, continent:"OC", continentid:6 },
  {id:185, code:"PY", title:"Paraguay", country_fullname:"Republic of Paraguay", iso3:"PRY", iso_number:600, continent:"SA", continentid:7 },
  {id:173, code:"PE", title:"Peru", country_fullname:"Republic of Peru", iso3:"PER", iso_number:604, continent:"SA", continentid:7 },
  {id:176, code:"PH", title:"Philippines", country_fullname:"Republic of the Philippines", iso3:"PHL", iso_number:608, continent:"AS", continentid:3 },
  {id:180, code:"PN", title:"Pitcairn", country_fullname:"Pitcairn Islands", iso3:"PCN", iso_number:612, continent:"OC", continentid:6 },
  {id:178, code:"PL", title:"Poland", country_fullname:"Republic of Poland", iso3:"POL", iso_number:616, continent:"EU", continentid:4 },
  {id:183, code:"PT", title:"Portugal", country_fullname:"Portuguese Republic", iso3:"PRT", iso_number:620, continent:"EU", continentid:4 },
  {id:181, code:"PR", title:"Puerto Rico", country_fullname:"Commonwealth of Puerto Rico", iso3:"PRI", iso_number:630, continent:"NA", continentid:5 },
  {id:186, code:"QA", title:"Qatar", country_fullname:"State of Qatar", iso3:"QAT", iso_number:634, continent:"AS", continentid:3 },
  {id:187, code:"RE", title:"Reunion", country_fullname:"Reunion", iso3:"REU", iso_number:638, continent:"AF", continentid:1 },
  {id:188, code:"RO", title:"Romania", country_fullname:"Romania", iso3:"ROU", iso_number:642, continent:"EU", continentid:4 },
  {id:190, code:"RU", title:"Russian Federation", country_fullname:"Russian Federation", iso3:"RUS", iso_number:643, continent:"EU", continentid:4 },
  {id:191, code:"RW", title:"Rwanda", country_fullname:"Republic of Rwanda", iso3:"RWA", iso_number:646, continent:"AF", continentid:1 },
  {id:27, code:"BL", title:"Saint Barthélemy", country_fullname:"Saint Barthelemy", iso3:"BLM", iso_number:652, continent:"NA", continentid:5 },
  {id:198, code:"SH", title:"Saint Helena", country_fullname:"Saint Helena", iso3:"SHN", iso_number:654, continent:"AF", continentid:1 },
  {id:119, code:"KN", title:"Saint Kitts and Nevis", country_fullname:"Federation of Saint Kitts and Nevis", iso3:"KNA", iso_number:659, continent:"NA", continentid:5 },
  {id:127, code:"LC", title:"Saint Lucia", country_fullname:"Saint Lucia", iso3:"LCA", iso_number:662, continent:"NA", continentid:5 },
  {id:140, code:"MF", title:"Saint Martin (French part)", country_fullname:"Saint Martin", iso3:"MAF", iso_number:663, continent:"NA", continentid:5 },
  {id:179, code:"PM", title:"Saint Pierre and Miquelon", country_fullname:"Saint Pierre and Miquelon", iso3:"SPM", iso_number:666, continent:"NA", continentid:5 },
  {id:234, code:"VC", title:"Saint Vincent and the Grenadines", country_fullname:"Saint Vincent and the Grenadines", iso3:"VCT", iso_number:670, continent:"NA", continentid:5 },
  {id:241, code:"WS", title:"Samoa", country_fullname:"Independent State of Samoa", iso3:"WSM", iso_number:882, continent:"OC", continentid:6 },
  {id:203, code:"SM", title:"San Marino", country_fullname:"Republic of San Marino", iso3:"SMR", iso_number:674, continent:"EU", continentid:4 },
  {id:207, code:"ST", title:"Sao Tome and Principe", country_fullname:"Democratic Republic of Sao Tome and Principe", iso3:"STP", iso_number:678, continent:"AF", continentid:1 },
  {id:192, code:"SA", title:"Saudi Arabia", country_fullname:"Kingdom of Saudi Arabia", iso3:"SAU", iso_number:682, continent:"AS", continentid:3 },
  {id:204, code:"SN", title:"Senegal", country_fullname:"Republic of Senegal", iso3:"SEN", iso_number:686, continent:"AF", continentid:1 },
  {id:189, code:"RS", title:"Serbia", country_fullname:"Republic of Serbia", iso3:"SRB", iso_number:688, continent:"EU", continentid:4 },
  {id:194, code:"SC", title:"Seychelles", country_fullname:"Republic of Seychelles", iso3:"SYC", iso_number:690, continent:"AF", continentid:1 },
  {id:202, code:"SL", title:"Sierra Leone", country_fullname:"Republic of Sierra Leone", iso3:"SLE", iso_number:694, continent:"AF", continentid:1 },
  {id:197, code:"SG", title:"Singapore", country_fullname:"Republic of Singapore", iso3:"SGP", iso_number:702, continent:"AS", continentid:3 },
  {id:250, code:"SX", title:"Sint Maarten", country_fullname:"Sint Maarten (Dutch part)", iso3:"SXM", iso_number:534, continent:"NA", continentid:5 },
  {id:201, code:"SK", title:"Slovakia", country_fullname:"Slovakia (Slovak Republic)", iso3:"SVK", iso_number:703, continent:"EU", continentid:4 },
  {id:199, code:"SI", title:"Slovenia", country_fullname:"Republic of Slovenia", iso3:"SVN", iso_number:705, continent:"EU", continentid:4 },
  {id:193, code:"SB", title:"Solomon Islands", country_fullname:"Solomon Islands", iso3:"SLB", iso_number:90, continent:"OC", continentid:6 },
  {id:205, code:"SO", title:"Somalia", country_fullname:"Somali Republic", iso3:"SOM", iso_number:706, continent:"AF", continentid:1 },
  {id:244, code:"ZA", title:"South Africa", country_fullname:"Republic of South Africa", iso3:"ZAF", iso_number:710, continent:"AF", continentid:1 },
  {id:89, code:"GS", title:"South Georgia and South Sandwich Islands", country_fullname:"South Georgia and the South Sandwich Islands", iso3:"SGS", iso_number:239, continent:"AN", continentid:2 },
  {id:249, code:"SS", title:"South Sudan", country_fullname:"South Sudan", iso3:"SSD", iso_number:728, continent:"AF", continentid:1 },
  {id:67, code:"ES", title:"Spain", country_fullname:"Kingdom of Spain", iso3:"ESP", iso_number:724, continent:"EU", continentid:4 },
  {id:129, code:"LK", title:"Sri Lanka", country_fullname:"Democratic Socialist Republic of Sri Lanka", iso3:"LKA", iso_number:144, continent:"AS", continentid:3 },
  {id:195, code:"SD", title:"Sudan", country_fullname:"Republic of Sudan", iso3:"SDN", iso_number:736, continent:"AF", continentid:1 },
  {id:206, code:"SR", title:"Suriname", country_fullname:"Republic of Suriname", iso3:"SUR", iso_number:740, continent:"SA", continentid:7 },
  {id:200, code:"SJ", title:"Svalbard and Jan Mayen Islands", country_fullname:"Svalbard & Jan Mayen Islands", iso3:"SJM", iso_number:744, continent:"EU", continentid:4 },
  {id:210, code:"SZ", title:"Swaziland", country_fullname:"Kingdom of Swaziland", iso3:"SWZ", iso_number:748, continent:"AF", continentid:1 },
  {id:196, code:"SE", title:"Sweden", country_fullname:"Kingdom of Sweden", iso3:"SWE", iso_number:752, continent:"EU", continentid:4 },
  {id:43, code:"CH", title:"Switzerland", country_fullname:"Swiss Confederation", iso3:"CHE", iso_number:756, continent:"EU", continentid:4 },
  {id:209, code:"SY", title:"Syria", country_fullname:"Syrian Arab Republic", iso3:"SYR", iso_number:760, continent:"AS", continentid:3 },
  {id:225, code:"TW", title:"Taiwan", country_fullname:"Taiwan", iso3:"TWN", iso_number:158, continent:"AS", continentid:3 },
  {id:216, code:"TJ", title:"Tajikistan", country_fullname:"Republic of Tajikistan", iso3:"TJK", iso_number:762, continent:"AS", continentid:3 },
  {id:226, code:"TZ", title:"Tanzania", country_fullname:"United Republic of Tanzania", iso3:"TZA", iso_number:834, continent:"AF", continentid:1 },
  {id:215, code:"TH", title:"Thailand", country_fullname:"Kingdom of Thailand", iso3:"THA", iso_number:764, continent:"AS", continentid:3 },
  {id:218, code:"TL", title:"Timor-Leste", country_fullname:"Democratic Republic of Timor-Leste", iso3:"TLS", iso_number:626, continent:"AS", continentid:3 },
  {id:214, code:"TG", title:"Togo", country_fullname:"Togolese Republic", iso3:"TGO", iso_number:768, continent:"AF", continentid:1 },
  {id:217, code:"TK", title:"Tokelau", country_fullname:"Tokelau", iso3:"TKL", iso_number:772, continent:"OC", continentid:6 },
  {id:221, code:"TO", title:"Tonga", country_fullname:"Kingdom of Tonga", iso3:"TON", iso_number:776, continent:"OC", continentid:6 },
  {id:223, code:"TT", title:"Trinidad and Tobago", country_fullname:"Republic of Trinidad and Tobago", iso3:"TTO", iso_number:780, continent:"NA", continentid:5 },
  {id:220, code:"TN", title:"Tunisia", country_fullname:"Tunisian Republic", iso3:"TUN", iso_number:788, continent:"AF", continentid:1 },
  {id:222, code:"TR", title:"Turkey", country_fullname:"Republic of Turkey", iso3:"TUR", iso_number:792, continent:"AS", continentid:3 },
  {id:219, code:"TM", title:"Turkmenistan", country_fullname:"Turkmenistan", iso3:"TKM", iso_number:795, continent:"AS", continentid:3 },
  {id:211, code:"TC", title:"Turks and Caicos Islands", country_fullname:"Turks and Caicos Islands", iso3:"TCA", iso_number:796, continent:"NA", continentid:5 },
  {id:224, code:"TV", title:"Tuvalu", country_fullname:"Tuvalu", iso3:"TUV", iso_number:798, continent:"OC", continentid:6 },
  {id:228, code:"UG", title:"Uganda", country_fullname:"Republic of Uganda", iso3:"UGA", iso_number:800, continent:"AF", continentid:1 },
  {id:227, code:"UA", title:"Ukraine", country_fullname:"Ukraine", iso3:"UKR", iso_number:804, continent:"EU", continentid:4 },
  {id:2, code:"AE", title:"United Arab Emirates", country_fullname:"United Arab Emirates", iso3:"ARE", iso_number:784, continent:"AS", continentid:3 },
  {id:76, code:"GB", title:"United Kingdom", country_fullname:"United Kingdom of Great Britain & Northern Ireland", iso3:"GBR", iso_number:826, continent:"EU", continentid:4 },
  {id:229, code:"UM", title:"United States Minor Outlying Islands", country_fullname:"United States Minor Outlying Islands", iso3:"UMI", iso_number:581, continent:"OC", continentid:6 },
  {id:230, code:"US", title:"United States of America", country_fullname:"United States of America", iso3:"USA", iso_number:840, continent:"NA", continentid:5 },
  {id:231, code:"UY", title:"Uruguay", country_fullname:"Eastern Republic of Uruguay", iso3:"URY", iso_number:858, continent:"SA", continentid:7 },
  {id:232, code:"UZ", title:"Uzbekistan", country_fullname:"Republic of Uzbekistan", iso3:"UZB", iso_number:860, continent:"AS", continentid:3 },
  {id:239, code:"VU", title:"Vanuatu", country_fullname:"Republic of Vanuatu", iso3:"VUT", iso_number:548, continent:"OC", continentid:6 },
  {id:233, code:"VA", title:"Vatican City", country_fullname:"Holy See (Vatican City State)", iso3:"VAT", iso_number:336, continent:"EU", continentid:4 },
  {id:235, code:"VE", title:"Venezuela", country_fullname:"Bolivarian Republic of Venezuela", iso3:"VEN", iso_number:862, continent:"SA", continentid:7 },
  {id:238, code:"VN", title:"Vietnam", country_fullname:"Socialist Republic of Vietnam", iso3:"VNM", iso_number:704, continent:"AS", continentid:3 },
  {id:236, code:"VG", title:"Virgin Islands, British", country_fullname:"British Virgin Islands", iso3:"VGB", iso_number:92, continent:"NA", continentid:5 },
  {id:237, code:"VI", title:"Virgin Islands, U.S.", country_fullname:"United States Virgin Islands", iso3:"VIR", iso_number:850, continent:"NA", continentid:5 },
  {id:240, code:"WF", title:"Wallis and Futuna Islands", country_fullname:"Wallis and Futuna", iso3:"WLF", iso_number:876, continent:"OC", continentid:6 },
  {id:65, code:"EH", title:"Western Sahara", country_fullname:"Western Sahara", iso3:"ESH", iso_number:732, continent:"AF", continentid:1 },
  {id:242, code:"YE", title:"Yemen", country_fullname:"Yemen", iso3:"YEM", iso_number:887, continent:"AS", continentid:3 },
  {id:245, code:"ZM", title:"Zambia", country_fullname:"Republic of Zambia", iso3:"ZMB", iso_number:894, continent:"AF", continentid:1 },
  {id:246, code:"ZW", title:"Zimbabwe", country_fullname:"Republic of Zimbabwe", iso3:"ZWE", iso_number:716, continent:"AF", continentid:1 }
]