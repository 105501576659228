import React from 'react';
import ReactDOM from "react-dom";
import { Route, Router, Switch, BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Registration from './Sessions/Registration';
//import Login from './Sessions/Login';
import SignIn from './Sessions/SignIn';
import PhoneSignIn from './Sessions/PhoneSignIn';
import Search from './components/Navigation/Search';
import MyProfile from "./Sessions/MyProfile";
import MySubscription from './Sessions/MySubscription';
import MyFavorite from './Sessions/MyFavorite';
import MyProfileUpdate from './Sessions/MyProfileUpdate';
import Player from './components/Pages/Player';
import SeriesPlayer from './components/Pages/SeriesPlayer';
import MovieGenre from './components/Pages/MovieGenre';
import Terms from './components/Contracts/Terms';
import ContactUs from './components/Contracts/ContactUs';
import AboutUs from './components/Contracts/AboutUs';
import FAQEnglish from './components/Contracts/FAQEnglish';
import FAQHausa from './components/Contracts/FAQHausa';
import Documentary from './components/Pages/Documentary';
import Subscribe from './Sessions/Subscribe';
import LiveTv from './components/Pages/LiveTv';
import TvSeries from './components/Pages/TvSeries';
import Music from './components/Pages/Music';
import MusicClassical from './components/Pages/MusicClassical';
import MusicContemporary from './components/Pages/MusicContemporary';
import Filmography from './components/Pages/Filmography';
import FilmographyYear from './components/Pages/FilmographyYear';
import ProtectedRoute from "./Sessions/ProtectedRoute";
import Home from './Home';

var hist = createBrowserHistory();

var val = 'fati';
/* ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
); */

    // Include the Crisp code for live chat here, without the <script></script> tags
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = "215bb823-b5cd-4410-b48c-9e9d42701722";

    (function() {
      var d = document;
      var s = d.createElement("script");

      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
    })();

ReactDOM.render(
<Router history={hist}>
      <Switch>
      <Route exact path="/user/profile-update" component={MyProfileUpdate} />
      <Route exact path="/user/favorites" component={MyFavorite} />
      <Route exact path="/user/subscription" component={MySubscription} />
      <Route exact path="/user/profile" component={MyProfile} />
      <Route exact path="/pages/faq" component={FAQEnglish} />
      <Route exact path="/pages/faq-hausa" component={FAQHausa} />
      <Route exact path="/pages/contact-us" component={ContactUs} />
      <Route exact path="/pages/about-us" component={AboutUs} />
      <Route exact path="/pages/privacy-policy" component={Terms} />
      <Route exact path="/movies" component={MovieGenre} />
      <Route path={`/movies/:title`}> <MovieGenre /> </Route>
      <Route exact path="/music" component={Music} />
      <Route exact path="/music/classical" component={MusicClassical} />
      <Route exact path="/music/contemporary" component={MusicContemporary} />
      {/* <Route exact path="/live-tv" component={LiveTv} /> */}
      <Route exact path="/live-tv" component={Home} />
      <Route exact path="/tv-series" component={TvSeries} />
      <Route exact path="/documentary" component={Documentary} />
      <Route path={`/search/:title`}> <Search /> </Route>
      <Route path={`/star/:stars`}> <Filmography /> </Route>
      <Route path={`/release/:year`}> <FilmographyYear /> </Route>
{/*       <Route exact path="/search" component={Search} />
      <Route exact path="/now-playing" component={Player} />
      <Route exact path="/now-playing-series" component={SeriesPlayer} />
      <Route exact path="/subscribe" component={MySubscription} /> */}
      <Route exact path="/sign-in" component={SignIn} />
      <Route exact path='/sign-in-with-phone' component={PhoneSignIn} />
      <ProtectedRoute path="/subscribe">
         <MySubscription />
      </ProtectedRoute>
      <ProtectedRoute path="/search">
         <Search />
      </ProtectedRoute>
      <ProtectedRoute path="/now-playing">
         <Player />
      </ProtectedRoute>
      <ProtectedRoute path="/now-playing-series">
         <SeriesPlayer />
      </ProtectedRoute>
      <Route exact path="/register" component={Registration} />
      <Route exact path="/home" component={Home} />
      <Route exact path="/" component={App} />
      <Route component={Home} />
     </Switch>
</Router>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
