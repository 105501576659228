import React, { useState, useRef, useEffect } from 'react';
/* import Navbar from '../Navigation/Navbar';
import Footer from '../Navigation/Footer';
import RowRelated from '../Rows/RowRelated'; */
import { 
    Container,
    Grid,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Slider,
    Tooltip,
    Typography,
    Popover,
    Paper
    } from '@material-ui/core';
import ReactPlayer from 'react-player';
import moment from 'moment';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import HdRoundedIcon from '@material-ui/icons/HdRounded';
import HdTwoToneIcon from '@material-ui/icons/HdTwoTone';
import IndeterminateCheckBoxRoundedIcon from '@material-ui/icons/IndeterminateCheckBoxRounded';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import FastForwardIcon from '@material-ui/icons/FastForward';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import FullScreenIcon from '@material-ui/icons/Fullscreen'; 
import PlayerControls from './PlayerControls';
import screenful from "screenfull";
import { 
  exchange,
  decryptWithAES,
  encryptWithAES,
  home
} from '../requests';

import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import { withTheme } from "./Theme/Theme";



const useStyles = makeStyles({
    root: {
        backgroundColor: '#111',
    },
    rot: {
      backgroundColor: '#111',
      "&:hover": {
        color: '#fff',
        backgroundColor: '#8b0000'
  //      transition: "transform cubic-bezier(0.85,.36,.8,.42) .5s"
      }
  },
    playerWrapper: {
        width: "100%",
        // marginLeft: 'auto',
        // marginRight: 'auto',
        position: "relative",
    },
    controlsWrapper: {
        visibility: "hidden",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(0,0,0,0.6)",
//        backgroundColor: '#111',
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        zIndex: 1,
    },
    controlIcons: {
        color: "#8b0000",
        fontSize: 50,
//        backgroundColor: '#aaa',
        transform: 'scale(0.9)',
        opacity: 0.2,
        "&:hover": {
            color: "#fff",
            transform: 'scale(1)',
        }
    },
    bottomIcons: {
        color: "#aaa",
        "&:hover":{
            color: '#fff'
        }
    },
    volumeSlider: {
        width: 100,
    },
    heading: {
      fontSize: '36px',
      fontStyle: 'normal',
      lineHeight: '44px',
      fontFamily: 'Jost, sans-serif',
      color: '#ffffff',
    },
    paragraphspace: {
      lineHeight: '4.295',
      textAlign: 'justify',
      marginTop: '15pt',
      marginBottom: '6pt',
  },

})

const format = (seconds) => {
    if (isNaN(seconds)) {
      return `00:00`;
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");
    if (hh) {
      return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    }
    return `${mm}:${ss}`;
  };

let count = 0;

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color,
      backgroundColor: color,
      height: 5
    }}
  />
);

//"https://kallong.s3.eu-west-2.amazonaws.com/movies/second/GIDAN+GADO+FULL+MASTER.mp4";
//const exchange = 'http://api.kallo.ng:5000';

//const home ='http://kallo-ng.herokuapp.com';
const trailerUrll = "https://www.youtube.com/watch?v=gTvU1kM8_aE";

function PlayerComponent() {
  
    const classes = useStyles();
    const [timeDisplayFormat, setTimeDisplayFormat] = useState("normal");
    const [bookmarks, setBookmarks] = useState([]);
    const [trailerUrl, setTrailerUrl] = useState("");
    const [movieRelated, setMovieRelated] = useState('');
    const [movieDetails, setMovieDetails] = useState(null);
    const [state, setState] = useState({
        playing: true,
        muted: false,
        volume: 0.5,
        playbackRate: 1.0,
        played: 0,
        seeking: false
    })

    const { playing, muted, volume, playbackRate, played, seeking } = state;

    const playerRef = useRef(null);
    const playerContainerRef = useRef(null);
    const controlsRef = useRef(null);
    const canvasRef = useRef(null);
    
    const handlePlayPause = () => {
        setState({ ...state, playing: !state.playing})
    }; 

    const handleRewind = () => {
        playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10)
    };

    const handleFastForward = () => {
        playerRef.current.seekTo(playerRef.current.getCurrentTime() + 30)
    };

    const handleMute = () => {
        setState({ ...state, muted: !state.muted})
    };

    const handleVolumeChange = (e, newValue) =>{
        setState({
            ...state, 
            volume:parseFloat(newValue / 100),
            muted: newValue === 0 ? true: false,
         })
    };

    const handleVolumeSeekUp = (e, newValue) =>{
        setState({
            ...state, 
            volume:parseFloat(newValue / 100),
            muted: newValue === 0 ? true: false,
         })
    };

    const handlePlaybackRateChange = (rate) => {
        setState({...state, playbackRate: rate })
    };

    const toggleFullScreen = () => {
        screenful.toggle(playerContainerRef.current);
      };

    const handleProgress = (changeState) => {
        if (count > 3) {
            controlsRef.current.style.visibility = "hidden";
            count = 0;
        }
        if (controlsRef.current.style.visibility == "visible") {
            count += 1;
        }
        if (!state.seeking) {
            setState({ ...state, ...changeState });
        }
        };

    const handleSeekChange = (e, newValue) => {
//        console.log({ newValue });
        setState({ ...state, played: parseFloat(newValue / 100) });
        };
    
    const handleSeekMouseDown = (e) => {
        setState({ ...state, seeking: true });
        };
    
    const handleSeekMouseUp = (e, newValue) => {
//        console.log({ value: e.target });
        setState({ ...state, seeking: false });
        // console.log(sliderRef.current.value)
        playerRef.current.seekTo(newValue / 100);
        };

    const handleChangeDisplayFormat = () => {
        setTimeDisplayFormat(
            timeDisplayFormat==='normal' ? 'remaining' : 'normal')
    }

    const addBookmark = () => {
        const canvas = canvasRef.current;
        canvas.width = 160;
        canvas.height = 90;
        const ctx = canvas.getContext("2d");
    
        ctx.drawImage(
          playerRef.current.getInternalPlayer(),
          0,
          0,
          canvas.width,
          canvas.height
        );
        const imageUrl = canvas.toDataURL();
        canvas.width = 0;
        canvas.height = 0;
        const bookmarksCopy = [...bookmarks];
        bookmarksCopy.push({
          time: playerRef.current.getCurrentTime(),
          display: format(playerRef.current.getCurrentTime()),
          image: imageUrl,
        });
        setBookmarks(bookmarksCopy);
      };

      const handleMouseMove = () => {
        controlsRef.current.style.visibility = "visible";
        count = 0;
      };
    
      const handleMouseLeave = () => {
        controlsRef.current.style.visibility = "hidden";
        count = 0;
      };

    const currentTime = playerRef.current ? playerRef.current.getCurrentTime() : "00:00";
    
    const duration = playerRef.current ? playerRef.current.getDuration() : "00:00";
    
    const elapsedTime = timeDisplayFormat === 'normal' ? format(currentTime) : `${format(duration - currentTime)}`;
    
    const totalDuration = format(duration);

    const [favoriteId, setFavoriteId] = React.useState(null);
    const [shareUrl, setShareUrl] = useState("");
    const [open, setOpen] = React.useState(false);
    const [verified, setVerified] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handlePopClose = () => {
      setAnchorEl(null);
    };
  
    const shareTitle = 'Hey, Check this out on Kallo.ng'
    const openPop = Boolean(anchorEl);
    const id = openPop ? 'simple-popover' : undefined;

useEffect(() => {
/*   const CryptoJS = require('crypto-js');

  const encryptWithAES = (text) => {
    const passphrase = 'kallo123';
    return CryptoJS.AES.encrypt(text, passphrase).toString();
  };
  
  const decryptWithAES = (ciphertext) => {
    const passphrase = 'kallo123';
    const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  }; */

//  setTrailerUrl(decryptWithAES(localStorage.getItem('lupe')));
  setMovieRelated(localStorage.getItem('related_movies'));
  setMovieDetails(JSON.parse(localStorage.getItem('movie_details')));
  setTrailerUrl(decryptWithAES(trailerUrll))
    //[] run once when the row loads and don't run again
    //[variable] run once when the row loads and everytime the variable changes 
    
}, []);


useEffect(() => {
        
  if (verifyFavs()) {
  
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
  myHeaders.append("Access-Control-Allow-Origin", "*");
  myHeaders.append("Access-Control-Allow-Credentials", 'true');
  myHeaders.append("Access-Control-Allow-Methods", "*");
  
  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };
  
  fetch(`${exchange}/api/v1/users/add_favorite/${favoriteId}`, requestOptions)
    .then(response => response.json())
    .then(result => 
      {
        alert(result.message)
      })
    .catch(error => console.log('error', error));
}
}, [favoriteId, verified])

function verifyFavs() {
  if (favoriteId !== null) {
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
  myHeaders.append("Access-Control-Allow-Origin", "*");
  myHeaders.append("Access-Control-Allow-Credentials", 'true');
  myHeaders.append("Access-Control-Allow-Methods", "*");

  var requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

fetch(`${exchange}/api/v1/users/verify_favorite_list/${favoriteId}`, requestOptions)
.then(response => response.json())
.then(result => {
if (result.message === 'Already in your wish list') {
alert(result.message);
setVerified(false);
}
else if (result.message !== 'Already in your wish list') {
//      alert(result.message);
setVerified(true);
}
})
.catch(error => console.log('error', error));


return verified;
}
}
/*   React.useLayoutEffect(() => {
      window.scrollTo(0, 0)
  }); */

  return (
    <div className={classes.root}>
        <p className={classes.paragraphspace}>
                </p>
            <h2 className={classes.heading}>
            Kana Kallon 
              <span 
                style={{
                  fontFamily: 'URW Chancery L, cursive',
                  color: '#fff',
                //  backgroundColor: '#8b0000'
                }}>
               "Live TV"</span>
                </h2>
                <ColoredLine color="#8b0000" />
                <IconButton className={classes.rot}
                    onClick={
                      (event) =>  {
                      setShareUrl('live-tv')
                      setAnchorEl(event.currentTarget);
                      //handlePopClick();
//                      navigator.clipboard.writeText(`${home}/${movie?.title}`);
//                      alert(` ${movie?.title} Link Copied`)
                      }}>
                      <ShareRoundedIcon />
                      Share 
                    </IconButton> 
            <div 
                ref={playerContainerRef} 
                className={classes.playerWrapper}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                >
                    
            <ReactPlayer
            // Disable download button
            config={{ 
                file: { 
                    attributes: { 
                        controlsList: 'nodownload',
//                        crossorigin: "anonymous", 
                    }, 
                }, 
            }}
            // Disable right click
            onContextMenu={e => e.preventDefault( )}
          
            // Your props
            ref={playerRef}
            url={trailerUrll}
//            className="react-player"
            playing={playing}
            muted={muted}
            volume={volume}
            playbackRate={playbackRate}
            onProgress={handleProgress}
/*             config={{
                file: {
                    attributes: {
                        crossorigin: "anonymous",
                    },
                },
            }} */
//            controls
            width="100%"
//            height="100%"
          />

          <PlayerControls 
            ref={controlsRef}
            onPlayPause={handlePlayPause}
            onRewind={handleRewind}
            onFastForward={handleFastForward}
            playing={playing}
            volume={volume}
            muted={muted}
            played={played}
            playbackRate={playbackRate}
            onMute={handleMute}
            onVolumeChange={handleVolumeChange}
            onVolumeSeekUp={handleVolumeSeekUp}
            onPlaybackRateChange={handlePlaybackRateChange}
            onToggleFullScreen={toggleFullScreen}
            onSeek={handleSeekChange}
            onSeekMouseDown={handleSeekMouseDown}
            onSeekMouseUp={handleSeekMouseUp}
            elapsedTime={elapsedTime}
            totalDuration={totalDuration}
            onChangeDisplayFormat={handleChangeDisplayFormat}
            onBookmark={addBookmark}
            
            />

        
          </div>
<Grid container style={{ marginTop: 20 }} spacing={3}>
          {bookmarks.map((bookmark, index) => (
            <Grid key={index} item>
              <Paper
                onClick={() => {
                  playerRef.current.seekTo(bookmark.time);
                  controlsRef.current.style.visibility = "visible";

                  setTimeout(() => {
                    controlsRef.current.style.visibility = "hidden";
                  }, 1000);
                }}
                elevation={3}
              >
                <img crossOrigin="anonymous" src={bookmark.image} />
                <Typography variant="body2" align="center">
                  bookmark at {bookmark.display}
                </Typography>
              </Paper>
            </Grid>
          ))}
        </Grid>
        <canvas ref={canvasRef} />

        <Popover
        id={id}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handlePopClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
       <div className="Demo__container">
        <div className="Demo__some-network">
         <FacebookShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            quote={shareTitle}
            className="Demo__some-network__share-button"
            >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          </div>

        <div className="Demo__some-network">
          <TwitterShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            title={(shareTitle)}
            className="Demo__some-network__share-button"
            >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          </div>

        <div className="Demo__some-network">
          <TelegramShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            title={shareTitle}
            className="Demo__some-network__share-button"
            >
            <TelegramIcon size={32} round />
          </TelegramShareButton>
          </div>

        <div className="Demo__some-network">
          <WhatsappShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            title={shareTitle}
            separator=":: "
            className="Demo__some-network__share-button"
            >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton> 
            </div>

            <div className="Demo__some-network">
          <EmailShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            subject={shareTitle}
            className="Demo__some-network__share-button"
            body={'Check out this Movie on Kallo.ng  '}
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
        </div>
            </div>

      </Popover>

        <ColoredLine color="#8b0000" />

    </div>
  )
}

export default withTheme(PlayerComponent)