import React from 'react'
import { Link } from "react-router-dom";
import {
    Box,
    Container,
    Grid
} from '@material-ui/core';
import logo from "../Assets/tv_logo_white.png";
import AppleButton from '../Assets/app-store.png';
import GooglePlay from '../Assets/playstore.png';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    heading: {
            fontSize: '36px',
            fontStyle: 'normal',
            lineHeight: '44px',
            fontFamily: 'Jost, sans-serif',
            color: '#ffffff',
    },
    paragraphspace: {
        lineHeight: '1.295',
        textAlign: 'justify',
        marginTop: '0pt',
        marginBottom: '6pt',
    },
    title:{
        fontSize: '21pt',
        fontFamily: 'Century Gothic, sans-serif',
        color: '#ffffff',
        backgroundColor: 'transparent',
        fontWeight: 700,
        fontStyle: 'normal',
        fontVariant: 'normal',
        textDecoration: 'none',
        verticalAlign: 'baseline',
        whiteSpace: 'pre',
        whiteSpace: 'pre-wrap',
    },
    element: {
        fontSize: '11pt',
        fontFamily: 'sans-serif, Century Gothic',
        color: '#ffffff',
        backgroundColor: 'transparent',
        fontWeight: 400,
        fontStyle: 'normal',
        fontVariant: 'normal',
        textDecoration: 'none',
        verticalAlign: 'baseline',
        whiteSpace: 'pre',
        whiteSpace: 'pre-wrap',
        textAlign: 'left'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
      },
    indent: {
        marginTop: '0',
        marginBottom: '0',
        paddingInlineStart: '1px',
//        color: '#8b0000'
    },
    textindent: {
        marginTop: '0',
        marginBottom: '0',
        paddingInlineStart: '48px',
//        color: '#8b0000'
    },
    list: {
        listStyleType: 'square',// use disc for rounded bullet points
        fontSize: '11pt',
        fontFamily: 'Century Gothic, sans-serif',
        color: '#db0000',
        backgroundColor: 'transparent',
        fontWeight: 400,
        fontStyle: 'normal',
        fontVariant: 'normal',
        textDecoration: 'none',
        verticalAlign: 'baseline',
        whiteSpace: 'pre',
        marginLeft: '36pt'
    },
    medium: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    },
    large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    },
    typograph: {
        color: "#fff"
    },
    cover: {
        objectFit: 'fill'
    }
  }));

export default function Footer() {
    const classes = useStyles();


  return (
    <footer>
        <Box style={{backgroundColor: '#222', marginTop: '80px'}}>
            <Container maxWidth='lg' >
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={3}>
                        {/* Column 1 */}
                        <Box style={{marginTop: '30px'}}>
                        <Link to='/'>
                        <p className={classes.paragraphspace}>
        <img
              //className={classes.title}
              src={logo}
              alt="Kallo.ng Logo"
              style={{maxWidth: 160, height: 50, marginLeft: 40, objectFit: 'contain'}}
              href='/'
               />
               </p>
               </Link>
                        </Box>
                        <Box>
                            <div className={classes.textindent}>
                            <p className={classes.paragraphspace}>
                        <span style={{color: '#fff'}}>
                            Kalli fina-finan Hausa a manhajarmu ta www.kallo.ng
A Sha Kallo Lafiya!
                            </span></p>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        {/* Column 2 */}
                        <Box style={{marginTop: '30px'}}>
                        <p className={classes.paragraphspace}>
                        <span className={classes.title}>
Explore</span>
</p>
                        </Box>
                        <Box>
            <ul className={classes.indent}>
<Link to='/' style={{textDecoration:'none'}}>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                    Home
                    </span>
                    </p>
                </li>
                </Link>
                <Link to='/movies' style={{textDecoration:'none'}}>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                    Movies
                    </span>
                    </p>
                </li>
                </Link>
                <Link to='/tv-series' style={{textDecoration:'none'}}>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                    Tv Shows
                    </span>
                    </p>
                </li>
                </Link>
                <Link to='/live-tv' style={{textDecoration:'none'}}>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                    Live Tv
                    </span>
                    </p>
                </li>
                </Link>
            </ul>
                        </Box>
                    </Grid>
                    <Grid container item xs={12} sm={3}>
                        {/* Column 3 */}
                        <Box style={{marginTop: '30px'}}>
                        <p className={classes.paragraphspace}>
<span className={classes.title}>Company</span>
</p>
                        </Box>
                        <Grid container item xs={6} sm={3}></Grid>
                        <Box>
            <ul className={classes.indent}>
<Link to="/pages/about-us" style={{textDecoration:'none'}}>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                    About Us
                    </span>
                    </p>
                </li>
                </Link>
                <Link to='/pages/privacy-policy' style={{textDecoration:'none'}}>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                    Terms/Privacy Policy
                    </span>
                    </p>
                </li>
                </Link>
                <Link to='/pages/help' style={{textDecoration:'none'}}>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                    Help Center
                    </span>
                    </p>
                </li>
                </Link>
                <Link to='/pages/contact-us' style={{textDecoration:'none'}}>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element} href='/pages/contact-us'>
                    Customer Care
                    </span>
                    </p>
                </li>
                </Link>
                <Link to='/subscribe' style={{textDecoration:'none'}}>
                <li className={classes.list}>
                <p className={classes.paragraphspace}>
                <span className={classes.element}>
                    Subscribe
                    </span>
                    </p>
                </li>
                </Link>
            </ul>
                        </Box>     
                </Grid>
                <Grid container item xs={12} sm={3}>
                        {/* Column 4 */}
                        <Box style={{marginTop: '30px'}}>
                        <p className={classes.paragraphspace}>
<span className={classes.title}>Download App</span>
</p>
                        </Box>
                        <Grid container item xs={6} sm={3}></Grid>
                        <Box>
                        <div className={classes.indent}>
                            <p className={classes.paragraphspace}>
                            <span className={classes.element} style={{color: '#fff'}}>
                            Download 
                            <Link to='/' style={{textDecoration:'none', color: '#fb0000'}}> Kallo.ng </Link>
                            app is available for Android and IOS device!
                            </span>
                            </p>
                            </div>
                        </Box>
                        <Grid container item xs={3} sm={3}></Grid>
                            <Box>
                            <a href='https://play.google.com/store/apps/details?id=com.ng.kallo' target="_blank" rel="noopener noreferrer">
                            <img
              //className={classes.title}
              src={GooglePlay}
              alt="Kallo.ng on Google Play"
              style={{maxWidth: 120, height: 50, marginLeft: -70, objectFit: 'contain'}}
              href='/'
               />
               </a>
                            </Box>
                        <Grid container item xs={3} sm={3}></Grid>
                            <Box>
                            <a href='https://play.google.com/store/apps/details?id=com.ng.kallo' target="_blank" rel="noopener noreferrer">
                             <img
              //className={classes.title}
              src={AppleButton}
              alt="Kallo.ng Logo"
              style={{maxWidth: 120, height: 50, marginLeft: 2, objectFit: 'contain'}}
              href='/'
               />
               </a>
                            </Box>
                    </Grid>
            </Grid>
                <Box textAlign='center' pt={{xs: 5, sm: 10}} pb={{xs: 5, sm: 0}} color='#fff' fontSize='18px' marginBottom='20px'>
                Copyright {new Date().getFullYear()} SpaceKraft Media, All Rights Reserved.
                </Box>
                <Box textAlign='center' pt={{xs: 5, sm: 10}} pb={{xs: 5, sm: 0}} style={{opacity: '0.05'}} >
mide_X
                </Box>
            </Container>
        </Box>
         
        </footer>
  )
}