import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import Navbar from '../Navigation/Navbar';
import Footer from '../Navigation/Footer';
import Subscribe from '../../Sessions/Subscribe';
import ReactPlayer from 'react-player';
import AccountCircle from '@material-ui/icons/AccountCircle';
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import HdRoundedIcon from '@material-ui/icons/HdRounded';
import HdTwoToneIcon from '@material-ui/icons/HdTwoTone';
import IndeterminateCheckBoxRoundedIcon from '@material-ui/icons/IndeterminateCheckBoxRounded';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import Popover from '@material-ui/core/Popover';
import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { 
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    Dialog,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Typography,
    } from '@material-ui/core';
    import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import ImageIcon from '@material-ui/icons/Image';
import moment from 'moment';
import '../../App.css';
import { 
  exchange,
  decryptWithAES,
  encryptWithAES,
  home
} from '../../requests';
import { withTheme } from "../Theme/Theme";

const useStyles = makeStyles((theme) => ({
    rut: {
        backgroundColor: '#111',
        color: '#fff',
    },
    root: {
        maxWidth: 450,
        backgroundColor: '#222',
    },
    small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    },
    medium: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    },
    large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    },
    typograph: {
        color: "#fff"
    },
    cover: {
        objectFit: 'fill'
    },
    heading: {
      fontSize: '36px',
      fontStyle: 'normal',
      lineHeight: '44px',
      fontFamily: 'Jost, sans-serif',
      color: '#ffffff',
    },
}));


/* const CryptoJS = require('crypto-js');

const encryptWithAES = (text) => {
  const passphrase = 'kallo123';
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

const decryptWithAES = (ciphertext) => {
  const passphrase = 'kallo123';
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

const exchange = 'http://api.kallo.ng:5000'; */
//const exchange = 'http://localhost:5000';

//const home ='http://kallo-ng.herokuapp.com/search';
const shareTitle = 'Hey, Check this out on Kallo.ng'

function FilmographyYear() {
    const classes = useStyles();
    const history = useHistory();
    const [trailerUrll, setTrailerUrll] = useState("");
    const [shareUrl, setShareUrl] = useState("");
    const [favoriteId, setFavoriteId] = React.useState(null);
    const [verified, setVerified] = React.useState(false);
    const [movieList, setMovieList]= React.useState([]);
    const [seriesList, setSeriesList]= React.useState([]);
    const [episodeList, setEpisodeList]= React.useState([]);
    const [actor, setActor] = React.useState('');
    const [genreName, setGenreName] = React.useState('Comedy');
    const [trailerUrl, setTrailerUrl] = useState("");
    const [trailerId, setTrailerId] = useState(null);
    const [seriesUrl, setSeriesUrl] = useState("");
    const [seriesId, setSeriesId] = useState(null);
    const [validSubscription, setValidSubscription] = useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState('');
    const [open, setOpen] = React.useState(false);
    const [openList, setOpenList] = React.useState(false);
    const [openDescription, setOpenDescription] = useState(false);
    const [description, setDescription] = useState('');
    const [series, setSeries] = React.useState(false);
    let { year } = useParams();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handlePopClose = () => {
      setAnchorEl(null);
    };
  
    const openPop = Boolean(anchorEl);
    const id = openPop ? 'simple-popover' : undefined;


    const handleClose = () => {
      setOpen(false);
      setOpenList(false);
      setOpenDescription(false);
    };


    const getGenreValue = (genredata) => {
      setActor(genredata.genre_id);
      setGenreName(genredata.name);
    }

    //On first load
    React.useEffect(()=> {
      localStorage.removeItem("lupe");
      localStorage.removeItem("related_movies");
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Access-Control-Allow-Credentials", 'true');
        myHeaders.append("Access-Control-Allow-Methods", "*");
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        fetch(`${exchange}/api/v1/users/content_by_year/${year}/`, requestOptions)
          .then(response => response.json())
          .then((data) => {
            if (data.message === 'Unauthorized') {
              history.push('/#')
            }
              setMovieList(data.movies);
              setSeriesList(data.series);
              setActor(data.star.star_name);
              console.log(data)
          })
          .catch(error => console.log('error', error));
    }, [year])

            //once movie is SELECTED, get RELEVANT DATA FOR STREAMING
            useEffect(() => {
              var myHeaders = new Headers();
              myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
        
              var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
              };
        
              if (validSubscription) {
              fetch(`${exchange}/api/v1/users/movieSingleDetails/${trailerId}/movie`, requestOptions)
                .then(response => response.json())
                .then(result => {
                  //get movie url
                  {result.videos.map((val) => {
        
                    setTrailerUrl(val.file_url);
                    localStorage.setItem("lupe", encryptWithAES(val.file_url))
        //            alert(localStorage.getItem('lupe'))
                  })}          
        //          setTrailer(result.videos);
                //get related movies as json
                  localStorage.setItem("related_movies", JSON.stringify(result.related_movies));
        
                  console.log(result)
                })
        /*         .then(
                  alert(`Url: ${trailerUrl}`)
                  ) */
                .then(()=>
                 { if (trailerUrl !== '') {
                    //history.push('/now-playing')
                    history.push('/pages/contact-us')
                  }
                }
                )
                .catch(error => console.log('error', error));
              }
              else if (subscriptionStatus === 'Unauthorized')
              {
                  history.push('/sign-in');
              }
              else if (subscriptionStatus === 'No Susbcription Found!')
              {
                  setOpen(true);
              }
            }, [trailerId, trailerUrl]);
    
      //once series is SELECTED, get RELEVANT DATA FOR STREAMING
      useEffect(() => {
        if (seriesId !== null) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
  
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        if (validSubscription) {
        fetch(`${exchange}/api/v1/users/movieSingleDetails/${seriesId}/tvseries`, requestOptions)
          .then(response => response.json())
          .then(result => {
            if (result.message !== "Cannot read properties of undefined (reading 'star_id')") {
                    //get  as json
          localStorage.setItem("series_details", JSON.stringify(result));
              //get list of episodes
//            setEpisodeList(result.season);
              
            console.log(result.season)
          }
          else {
            alert('Not available');
            history.push('/tv-series');
            setOpenList(false);
          }
          })
          .then(()=>
          { if (seriesId !== '') {
             history.push('/now-playing-series')
           }}
         )
          .catch(error => console.log('error', error));
        }
        else if (subscriptionStatus === 'Unauthorized')
        {
            history.push('/sign-in');
        }
        else if (subscriptionStatus === 'No Susbcription Found!')
        {
            setOpen(true);
        }
      }
      }, [seriesId, seriesUrl]);

            //once page loads, check for Active Subscription
            useEffect(() => {
              var myHeaders = new Headers();
              myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
              
              var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
              };
              
              fetch(`${exchange}/api/v1/users/checkUserSubscriptionStatus`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result.message);
                  if (result.message === 'No Susbcription Found!' || result.message === 'Unauthorized') {
                      setSubscriptionStatus(result.message);
                      setValidSubscription(false);
                  }
                  else {
                      setValidSubscription(true);}
                  })
                .catch(error => {
                    console.log('error', error);
                    setValidSubscription(false);});
            }, [])

            // Add SELECTED movie/series to favorite
      useEffect(() => {
        
        if (verifyFavs()) {
        
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        fetch(`${exchange}/api/v1/users/add_favorite/${favoriteId}`, requestOptions)
          .then(response => response.json())
          .then(result => 
            {
              alert(result.message)
            })
          .catch(error => console.log('error', error));
      }
    }, [favoriteId, verified])

      function verifyFavs() {
        if (favoriteId !== null) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

fetch(`${exchange}/api/v1/users/verify_favorite_list/${favoriteId}`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if (result.message === 'Already in your wish list') {
      alert(result.message);
      setVerified(false);
    }
    else if (result.message !== 'Already in your wish list') {
//      alert(result.message);
      setVerified(true);
    }
  })
  .catch(error => console.log('error', error));


  return verified;
      }
    }
    
    function truncate(str, n) {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    }

  return (
    <div className='app'>
    <div className={classes.rut}>
        
<>        <Navbar 
            getGenreValue={getGenreValue}/>
        <Container 
        //maxWidth='md' 
        >
        <Box my={2}>
        <div>
            <h2 className={classes.heading}>
            {series? 'Series' : 'Movies'} : {year}
                </h2>
            </div>
<div>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={6} sm={3}>

        </Grid>
        <Grid item xs={6} sm={3}>

        </Grid>
        </Grid>
        </div>
        <div >
          <Button 
            size="large" style={{color: "#fff", backgroundColor: "#8b0000"}}
            onClick={() => setSeries(!series)}>
              Click to view {series ? 'Movies' : 'Series'}
          </Button>
        <div>
            <h2 className={classes.heading}>
 
                </h2>
            </div>
            </div>
{ (!series) &&       
<Grid container spacing={2} justifyContent="center"
  alignItems="center">
{ (movieList.length !== 0) ?
            (movieList.map(val => (
        <Grid item xs={12} sm={4}>
        <Card className={classes.root}>
      <CardActionArea
        onClick={()=> {
          setOpenDescription(true);
          setDescription(val?.description)}}
                        >
        <CardMedia
          className={classes.cover}
          component="img"
          alt={val?.slug}
          height="300"
          image={val?.thumbnail_url}
          title={val?.slug}
        />
        <CardContent>
          <Typography className={classes.typograph} gutterBottom variant="h5" component="h2">
            {val?.title}
          </Typography>
          <Typography className={classes.typograph} gutterBottom variant="caption" component="p">
            {moment(val?.release).format('MMM Do YYYY')}
          </Typography>
          <Typography className={classes.typograph} variant="body2" color="textSecondary" component="p">
          {truncate(val?.description, 200)}  
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" style={{color: "#fff", backgroundColor: "#8b0000"}}
        onClick={() => {
          if (trailerUrll === '') {
            setTrailerUrll(val.trailler_youtube_source.replace("watch?v=", "embed/"));
            }
            else {
              setTrailerUrll('')
            }
            }}>
          Watch Trailer
        </Button>
        <Button size="small" style={{color: "#fff", backgroundColor: "#8b0000"}}
        onClick={() => {setTrailerId(val.videos_id);}}>
          Watch 
        </Button>
        <Grid container alignItems="center">
                     <Grid item xs={8}>
                     <IconButton className={classes.root}
                     onClick={()=>{
                       //alert(movie.videos_id)
                       //handleFavs(movie.videos_id)
                       setFavoriteId(val.videos_id)
                       }}>
                      <FavoriteBorderRoundedIcon />
                    </IconButton>
                    <IconButton className={classes.root}
                    onClick={
                      (event) =>  {
                      setShareUrl(val?.title)
                      setAnchorEl(event.currentTarget);
                      //handlePopClick();
//                      navigator.clipboard.writeText(`${home}/${movie?.title}`);
//                      alert(` ${movie?.title} Link Copied`)
                      }}>
                      <ShareRoundedIcon />
                    </IconButton>
                    <IconButton className={classes.root}
                     onClick={()=>{
                       setTrailerId(val.videos_id);
                       }}>
                      <PlayArrowIcon />
                    </IconButton>
                    </Grid>
                    <Grid item xs={4}>
                     {(val?.video_quality === 'HD') ? (
                     <IconButton disabled style={{backgroundColor: '#fff', borderRadius: 0, height: '20px', width: '20px'}}>
                      <HdTwoToneIcon fontSize='large' color='primary' />
                    </IconButton>) : (
                      <IconButton className={classes.root}>
                      <IndeterminateCheckBoxRoundedIcon />
                    </IconButton>
                    )}
                    </Grid>
                    </Grid>
      </CardActions>
    </Card>         
            </Grid>
            ))) : (
              <Grid item xs={12} sm={4}>
              <div>
            <h2 className={classes.heading}>
            No Movies found
                </h2>
            </div>
            </Grid>
            )}
        </Grid>
  }

{ series &&
        <Grid container spacing={2} justifyContent="center"
  alignItems="center">
    { (seriesList.length !== 0) ?
            (seriesList.map(val => (
        <Grid item xs={12} sm={4}>
        <Card className={classes.root}>
      <CardActionArea
        onClick={()=> {
          setOpenDescription(true);
          setDescription(val?.description)}}
                        >
        <CardMedia
          className={classes.cover}
          component="img"
          alt={val?.slug}
          height="300"
          image={val?.thumbnail_url}
          title={val?.slug}
        />
        <CardContent>
          <Typography className={classes.typograph} gutterBottom variant="h5" component="h2">
            {val?.title}
          </Typography>
          <Typography className={classes.typograph} gutterBottom variant="caption" component="p">
            {moment(val?.release).format('MMM Do YYYY')}
          </Typography>
          <Typography className={classes.typograph} variant="body2" color="textSecondary" component="p">
          {truncate(val?.description, 200)}  
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" style={{color: "#fff", backgroundColor: "#8b0000"}}
        onClick={() => {
          if (trailerUrll === '') {
            setTrailerUrll(val.trailler_youtube_source.replace("watch?v=", "embed/"));
            }
            else {
              setTrailerUrll('')
            }
            }}>
          Watch Trailer
        </Button>
        <Button size="small" style={{color: "#fff", backgroundColor: "#8b0000"}}
        onClick={() => {
          setSeriesId(val.videos_id);
          setOpenList(true);
          }}>
          Watch 
        </Button>
        <Grid container alignItems="center">
                     <Grid item xs={8}>
                     <IconButton className={classes.root}
                     onClick={()=>{
                       //alert(movie.videos_id)
                       //handleFavs(movie.videos_id)
                       setFavoriteId(val.videos_id)
                       }}>
                      <FavoriteBorderRoundedIcon />
                    </IconButton>
                    <IconButton className={classes.root}
                    onClick={
                      (event) =>  {
                      setShareUrl(val?.title)
                      setAnchorEl(event.currentTarget);
                      //handlePopClick();
//                      navigator.clipboard.writeText(`${home}/${movie?.title}`);
//                      alert(` ${movie?.title} Link Copied`)
                      }}>
                      <ShareRoundedIcon />
                    </IconButton>
                    <IconButton className={classes.root}
                     onClick={()=>{
                       setTrailerId(val.videos_id);
                       }}>
                      <PlayArrowIcon />
                    </IconButton>
                    </Grid>
                    <Grid item xs={4}>
                     {(val?.video_quality === 'HD') ? (
                     <IconButton disabled style={{backgroundColor: '#fff', borderRadius: 0, height: '20px', width: '20px'}}>
                      <HdTwoToneIcon fontSize='large' color='primary' />
                    </IconButton>) : (
                      <IconButton className={classes.root}>
                      <IndeterminateCheckBoxRoundedIcon />
                    </IconButton>
                    )}
                    </Grid>
                    </Grid>
      </CardActions>
    </Card>         
            </Grid>
            )))  : (
              <Grid item xs={12} sm={4}>
              <div>
            <h2 className={classes.heading}>
            No Series found
                </h2>
            </div>
            </Grid>
            )}
        </Grid>
}
        <div>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={6} sm={3}>

        </Grid>
        <Grid item xs={6} sm={3}>

        </Grid>
        </Grid>
        </div>

        </Box>
        </Container>
        <footer>
        <Footer />
        </footer>
        </>


    </div>
     
    <div style={{backgroundColor: '#222'}} >
<Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        style={{borderRadius: "20px"}}
        PaperComponent={Paper}
//        style={{color: '#222'}}
      >

        <Subscribe />
      </Dialog>

{// ------ TRAILER POP-UP
}
      <Dialog
      open={trailerUrll===''? false: true}
      fullWidth
      maxWidth='md'
      onClose={()=> setTrailerUrll('')}
      aria-labelledby="max-width-dialog-title"
      style={{borderRadius: "20px"}}
      PaperComponent={Paper}>
      {  trailerUrll && 
  <>
  <Grid container justifyContent="center"
  alignItems="center"> 
          <iframe 
          src={`${trailerUrll}?showinfo=1&controls=1&autoplay=1`}
          title='Movie Trailer'
          frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          style={{
            width:'100%',
            height:'400px',
            borderRradius: '20px'
          }}/>
{/* <ReactPlayer
  // Disable download button
  config={{ file: { attributes: { controlsList: 'nodownload' } } }}

  // Disable right click
  onContextMenu={e => e.preventDefault()}

  // Your props
  url={trailerUrll}
  className="react-player"
  playing={true}
  controls
  width="100%"
  //height="100%"
/> */}
</Grid>
</>
}
      </Dialog>

      <Dialog
        fullWidth
        maxWidth='md'
        open={openList}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        style={{borderRadius: "20px"}}
        PaperComponent={Paper}
//        style={{color: '#222'}}
      >
              <>
            {episodeList.map(valew => (
<div>
                        <Typography className={classes.typograph} gutterBottom variant="h5" component="h2">
                        {valew?.seasons_name}
                      </Typography>
        <Grid container spacing={2} justifyContent="center"
  alignItems="center">
          {valew.episodes.map((val) => (
        <Grid item xs={12} sm={4}>
        <Card className={classes.root}>
      <CardActionArea>
        <CardContent>
          <Typography className={classes.typograph} gutterBottom variant="h5" component="h2">
            {val?.episodes_name}
          </Typography>
          <Typography className={classes.typograph} gutterBottom variant="caption" component="p">
            {moment(val?.date_added).format('MMM Do YYYY')}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" style={{color: "#fff", backgroundColor: "#8b0000"}}
        //onClick={() => {setTrailerId(val?.videos_id);}}
        >
          Play
        </Button>
      </CardActions>
    </Card>                    
            </Grid>
            ))}
        </Grid>
        </div>
            ))}
            </>
      </Dialog>

            {//Movie  Description Dialog
      }
      <Dialog
fullWidth
maxWidth='md'
open={openDescription}
onClose={handleClose}
aria-labelledby="max-width-dialog-title"
style={{borderRadius: "20px"}}
PaperComponent={Paper}
//        style={{color: '#222'}}
>

<h2 style={{fontSize: '18px', color: '#fff'}}> {description} </h2>
</Dialog>

      <Popover
        id={id}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handlePopClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
       <div className="Demo__container">
        <div className="Demo__some-network">
         <FacebookShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            quote={shareTitle}
            className="Demo__some-network__share-button"
            >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          </div>

        <div className="Demo__some-network">
          <TwitterShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            title={(shareTitle)}
            className="Demo__some-network__share-button"
            >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          </div>

        <div className="Demo__some-network">
          <TelegramShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            title={shareTitle}
            className="Demo__some-network__share-button"
            >
            <TelegramIcon size={32} round />
          </TelegramShareButton>
          </div>

        <div className="Demo__some-network">
          <WhatsappShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            title={shareTitle}
            separator=":: "
            className="Demo__some-network__share-button"
            >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton> 
            </div>

            <div className="Demo__some-network">
          <EmailShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            subject={shareTitle}
            className="Demo__some-network__share-button"
            body={'Check out this Movie on Kallo.ng  '}
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
        </div>
            </div>

      </Popover>
      </div>
    </div>
  )
}

export default withTheme(FilmographyYear);