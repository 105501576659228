import React from 'react';
import { useHistory } from "react-router";
import Navbar from '../components/Navigation/Navbar';
import Footer from '../components/Navigation/Footer';
import ChangePassword from './ChangePassword';
import { 
    Avatar,
    Box,
    Button,
    Container,
    Dialog,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Typography,
    } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ImageIcon from '@material-ui/icons/Image';
import moment from 'moment';
import { withTheme } from "../components/Theme/Theme";
import ProfileUpdate from './ProfileUpdate';
import { 
    exchange,
    decryptWithAES,
    encryptWithAES,
    home
  } from '../requests';

const useStyles = makeStyles((theme) => ({
    rut: {
        backgroundColor: '#111',
        color: '#fff',
    },
    root: {
    display: 'flex',
    '& > *': {
        margin: theme.spacing(1),
    },
    },
    small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    },
    medium: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    },
    large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    },
}));



function Profile() {
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    const [openList, setOpenList] = React.useState(false);

    const handleClose = () => {
      setOpen(false);
      setOpenList(false);
    };

/*     const CryptoJS = require('crypto-js');

const encryptWithAES = (text) => {
  const passphrase = 'kallo123';
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

const decryptWithAES = (ciphertext) => {
  const passphrase = 'kallo123';
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
 */
    

  return (
    <div className={classes.rut}>
        {("name" in localStorage) ? (
<>        <Navbar/>
        <Container>
        <Box my={2}>
        <Typography variant="h3" gutterBottom>
            Profile Information
        </Typography>
        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" className={classes.large} />
        <List style={{color: '#fff'}}>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <ImageIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    disableTypography 
                    primary="Fullname" 
                    secondary={<Typography variant="h6" gutterBottom>{decryptWithAES(localStorage.getItem("name"))}</Typography>} />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <ImageIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText 
                    primary="Fullname" 
                    secondary={<Typography variant="h6" gutterBottom>{decryptWithAES(localStorage.getItem("name"))}</Typography>} />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <ImageIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText 
                    primary="Email" 
                    secondary={<Typography variant="h6" gutterBottom>{localStorage.getItem("email")}</Typography>} />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <ImageIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText 
                    primary="Join Date" 
                    secondary={<Typography variant="h6" gutterBottom>{moment(localStorage.getItem("join_date")).format('MMM Do YYYY')}</Typography>} />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <ImageIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText 
                    primary="Last Login" 
                    secondary={<Typography variant="h6" gutterBottom>{moment(localStorage.getItem("last_login")).format('MMM Do YYYY')}</Typography>} />
            </ListItem>
        </List>
        <Button size="small" style={{color: "#fff", backgroundColor: "#8b0000"}}
            onClick={()=>setOpenList(true)}>
            Edit Account Info
        </Button>
        <Button size="small" style={{color: "#fff", backgroundColor: "#8b0000"}}
            onClick={()=>setOpen(true)}>
            Change Password
        </Button>
        </Box>
        </Container>
        <footer>
        <Footer />
        </footer>


        <div style={{backgroundColor: '#222'}} >
<Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        style={{borderRadius: "20px"}}
        PaperComponent={Paper}
//        style={{color: '#222'}}
      >

        <ChangePassword />
      </Dialog>

      <Dialog
        fullWidth
        maxWidth='md'
        open={openList}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        style={{borderRadius: "20px"}}
        PaperComponent={Paper}
//        style={{color: '#222'}}
      >

        <ProfileUpdate />
      </Dialog>
    </div>
        </>
        ) : (history.push("/"))
}
</div>
  )
}

export default withTheme(Profile)