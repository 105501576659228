import React from 'react';
import './Home.css';
import Row from "./components/Rows/Row";
import RowActor from './components/Rows/RowActor';
import RowPopular from './components/Rows/RowPopular';
import RowLatest from './components/Rows/RowLatest';
import RowLatestSeries from './components/Rows/RowLatestSeries';
import RowMusic from './components/Rows/RowMusic';
//import Banner from "./Banner"
import Carousel from "./components/Carousel/Carousel";
import requests from './requests';
//import Nav from "./Nav"
import Navbar from "./components/Navigation/Navbar.js";
import Footer from "./components/Navigation/Footer.js";
import logo from "./components/Assets/tv_logo_white.png";
import {
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  IconButton,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
  Typography
  } from '@material-ui/core';
//import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';
import { withTheme } from './components/Theme/Theme';
import RowGenre from './components/Rows/RowGenre';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 3,
    border: 0,
    color: 'white',
    textColor: 'white',
  },
  label: {
    textTransform: 'capitalize',
  },
}));

function App() {
  const classes = useStyles();

    const [openRegister, setOpenRegister] = React.useState(false);
    const [openLogin, setOpenLogin] = React.useState(false);

  
  const getOpenRegister = (registerdata) => {
    setOpenRegister(registerdata);
  }
  
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0)
});

  return (
    <div className="app">
      {/* Nav */}
<Navbar 
  />
{/* <Banner /> */}

<div><Carousel />
</div>
{/* <Row 
  title="Suggestions" 
  fetchUrl={requests.fetchMovieSuggestion}
  section='all_genre'
  //isLargeRow
   /> */}
<RowGenre 
  title="Explore by Genre" 
  fetchUrl={requests.fetchHomePageDisplay}
  section='all_genre'
  //isLargeRow
   />
<RowLatest title="Specials & Latest Movies" fetchUrl={requests.fetchHomePageDisplay} section='latest_movies' isLargeRow/>
<RowLatestSeries title="Latest Series" fetchUrl={requests.fetchHomePageDisplay} section='latest_tvseries' isLargeRow />
<RowLatest title="Popular Movies" fetchUrl={requests.fetchHomePageDisplay} section='latest_movies' isLargeRow/>
<RowPopular title="Documentaries" fetchUrl={requests.fetchHomePageDisplay} genre='Documentary' section='features_genre_and_movie'/>
<RowMusic title="Popular Music" fetchUrl={requests.fetchHomePageDisplay} genre='Music' section='music' isLargeRow />
<RowActor title="Popular Actors" fetchUrl={requests.fetchHomePageDisplay} genre='Actors' section='popular_actors' />
{/* <RowPopular title="Popular Action Movies" fetchUrl={requests.fetchHomePageDisplay} genre='Action' section='features_genre_and_movie'  />
<RowPopular title="Popular Comedy" fetchUrl={requests.fetchHomePageDisplay} genre='Comedy' section='features_genre_and_movie'/>
<RowPopular title="Popular Thrillers" fetchUrl={requests.fetchHomePageDisplay} genre='Thriller' section='features_genre_and_movie' /> */}

{//<Row title="Latest Movies" fetchUrl={} />
}

<Footer />
    </div>
  );
}

export default withTheme(App);
