import React, { useState } from 'react';
import { useHistory } from "react-router";
import Navbar from '../components/Navigation/Navbar';
import Footer from '../components/Navigation/Footer';
import { 
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ImageIcon from '@material-ui/icons/Image';
import moment from 'moment';
import { 
  exchange,
  decryptWithAES,
  encryptWithAES,
  home
} from '../requests';

import { withTheme } from "../components/Theme/Theme";

const useStyles = makeStyles((theme) => ({
    rut: {
        backgroundColor: '#111',
        color: '#fff',
    },
    root: {
        maxWidth: 450,
        backgroundColor: '#222',
    },
    small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    },
    medium: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    },
    large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    },
    typograph: {
        color: "#fff"
    },
    cover: {
        objectFit: 'fill'
    }
}));

/* const exchange = 'http://api.kallo.ng:5000';

const CryptoJS = require('crypto-js');

const encryptWithAES = (text) => {
  const passphrase = 'kallo123';
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

const decryptWithAES = (ciphertext) => {
  const passphrase = 'kallo123';
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}; */

function MyFavorite() {
    const classes = useStyles();
    const history = useHistory();
    const [favoriteList, setFavoriteList]= React.useState([]);
    const [favoriteId, setFavoriteId] = React.useState(null);
    const [trailerUrl, setTrailerUrl] = useState("");
    const [trailerId, setTrailerId] = useState(null);
    const [validSubscription, setValidSubscription] = useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState('');
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Credentials", 'true');
      myHeaders.append("Access-Control-Allow-Methods", "*");
      
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      
      fetch(`${exchange}/api/v1/users/remove_favorite/${favoriteId}`, requestOptions)
        .then(response => response.json())
        .then(result => 
          { if (favoriteId !== null ) {
            alert(result.message)
          }
          })
        .catch(error => console.log('error', error));
  }, [favoriteId])

          //once movie is SELECTED, get RELEVANT DATA FOR STREAMING
          React.useEffect(() => {
            var myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
            myHeaders.append("Access-Control-Allow-Origin", "*");
            myHeaders.append("Access-Control-Allow-Credentials", 'true');
            myHeaders.append("Access-Control-Allow-Methods", "*");
      
            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
            };
      
            if (validSubscription) {
            fetch(`${exchange}/api/v1/users/movieSingleDetails/${trailerId}/movie`, requestOptions)
              .then(response => response.json())
              .then(result => {
                //get movie url
                {result.videos.map((val) => {
      
                  setTrailerUrl(val.file_url);
                  localStorage.setItem("lupe", encryptWithAES(val.file_url))
      //            alert(localStorage.getItem('lupe'))
                })}          
      //          setTrailer(result.videos);
              //get related movies as json
                localStorage.setItem("related_movies", JSON.stringify(result.related_movies));
                localStorage.setItem("movie_details", JSON.stringify(result));
                localStorage.setItem("movie_title", (result.movie.title))
      
                console.log(result)
              })
      /*         .then(
                alert(`Url: ${trailerUrl}`)
                ) */
              .then(()=>
               { if (trailerUrl !== '') {
                  //history.push('/now-playing')
                  history.push('/pages/contact-us')
                }
              }
              )
              .catch(error => console.log('error', error));
            }
            else if (subscriptionStatus === 'Unauthorized')
            {
                history.push('/sign-in');
            }
            else if (subscriptionStatus === 'No Susbcription Found!')
            {
                setOpen(true);
            }
          }, [trailerId, trailerUrl]);

    React.useEffect(()=> {
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Access-Control-Allow-Credentials", 'true');
        myHeaders.append("Access-Control-Allow-Methods", "*");
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        fetch(`${exchange}/api/v1/users/favorites`, requestOptions)
          .then(response => response.json())
          .then((data) => {
              setFavoriteList(data);
              console.log(data)
          })
          .catch(error => console.log('error', error));
    }, [favoriteList])
    
    function truncate(str, n) {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    }

  return (
    <div className={classes.rut}>
        {("name" in localStorage) ? (
<>        <Navbar/>
        <Container>
        <Box my={2}>
        <Typography variant="h3" gutterBottom>
            My Favorites
        </Typography>
<div>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={6} sm={3}>
        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" className={classes.large} />
        </Grid>
        <Grid item xs={6} sm={3}>
        <Typography className={classes.typograph} gutterBottom variant="h5" component="h2">
            {decryptWithAES(localStorage.getItem('name'))}
          </Typography>
        </Grid>
        </Grid>
        </div>
        <Grid container spacing={2} justifyContent="center"
  alignItems="center">
            {favoriteList.map(val => (
        <Grid item xs={12} sm={4}>
        <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.cover}
          component="img"
          alt={val?.slug}
          height="300"
          image={val?.thumbnail_url}
          title={val?.slug}
        />
        <CardContent>
          <Typography className={classes.typograph} gutterBottom variant="h5" component="h2">
            {val?.title}
          </Typography>
          <Typography className={classes.typograph} variant="body2" color="textSecondary" component="p">
          {truncate(val?.description, 200)}  
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" style={{color: "#fff", backgroundColor: "#8b0000"}}
        onClick={() => {setTrailerId(val.videos_id);}}>
          Watch
        </Button>
        <Button size="small" style={{color: "#fff", backgroundColor: "#8b0000"}}
        onClick={() => setFavoriteId(val?.videos_id)}>
          Delete
        </Button>
      </CardActions>
    </Card>                    
            </Grid>
            ))
            }
        </Grid>

        </Box>
        </Container>
         
         
        <footer>
        <Footer />
        </footer>
        </>
        ) : (() =>
            {history.push("/")}
            )
}

    </div>
  )
}

export default withTheme(MyFavorite);