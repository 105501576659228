import React, { forwardRef} from 'react';
import { 
    Grid,
    Button,
    IconButton,
    Slider,
    Tooltip,
    Typography,
    Popover
    } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import FastForwardIcon from '@material-ui/icons/FastForward';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOff from '@material-ui/icons/VolumeOff';
import FullScreenIcon from '@material-ui/icons/Fullscreen'; 
import KalloLogo from './Assets/tv_logo_white.png';

const useStyles = makeStyles({
    root: {
        //backgroundColor: '#111'
    },
    title:{
        paddingLeft: '-10px'
    },
/*     playerWrapper: {
        width: "100%",
        // marginLeft: 'auto',
        // marginRight: 'auto',
        position: "relative",
    },
 */
    controlsWrapper: {
//        visibility: "hidden",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(0,0,0,0.6)",
//        backgroundColor: '#111',
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        zIndex: 1,
    },
    controlIcons: {
        color: "#8b0000",
        fontSize: 50,
//        backgroundColor: '#aaa',
        transform: 'scale(0.9)',
        opacity: 0.5,
        "&:hover": {
            color: "#fff",
            transform: 'scale(1)',
        }
    },
    bottomIcons: {
        color: "#aaa",
        "&:hover":{
            color: '#fff'
        }
    },
    volumeSlider: {
        width: 100,
    }
})

function ValueLabelComponent(props) {
    const { children, open, value } = props;
  
    return (
      <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
        {children}
      </Tooltip>
    );
  }

const PrettoSlider = withStyles({
    root: {
      color: '#8b0000',
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: '#fff',
      border: '2px solid currentColor',
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
  })(Slider);


export default forwardRef(
    ({ 
    onPlayPause, 
    playing, 
    onRewind, 
    onFastForward,
    muted,
    volume,
    onMute,
    onVolumeChange,
    onVolumeSeekUp,
    playbackRate,
    onPlaybackRateChange,
    onToggleFullScreen,
    played,
    onSeekMouseDown,
    onSeekMouseUp,
    onSeek,
    elapsedTime,
    totalDuration,
    onChangeDisplayFormat,
    onBookmark,

}, ref ) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopover = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'playbackrate-popover' : undefined;
  

  return (

        <div ref={ref} className={classes.controlsWrapper} >
            {/**Top Controls */}
              <Grid 
                container
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                style={{padding: 16}} >
                  <Grid item style={{color: '#fff'}}>
            <img
              src={KalloLogo}
              alt="Kallo.ng Logo"
              style={{maxWidth: 160, height: 45, objectFit: 'contain'}}
              href='/'
               />
                  </Grid>
                  <Grid item>
{/*                       <Button
                        onClick={onBookmark}
                        variant='contained'
                        color='primary'
                        startIcon={<BookmarkIcon />}
                        style={{backgroundColor: "#8b0000"}}
                        >
                            Bookmark
                      </Button> */}
                  </Grid>
              </Grid>

              {/** Middle controls */}
              <Grid 
                container
                direction='row'
                alignItems='center'
                justifyContent='center'>
                  <IconButton onClick={onRewind} className={classes.controlIcons} aria-label='reqind'>
                      <FastRewindIcon fontSize='inherit'/>
                  </IconButton>

                  <IconButton onClick={onPlayPause} className={classes.controlIcons} aria-label='reqind'>
                      {playing ? 
                      (<PauseIcon fontSize='inherit' />)
                      :
                      (<PlayArrowIcon fontSize='inherit'/> )
                      }
                  </IconButton>

                  <IconButton onClick={onFastForward} className={classes.controlIcons} aria-label='reqind'>
                      <FastForwardIcon fontSize='inherit'/>
                  </IconButton>
              </Grid>
              {/** Bottom Controls */}
              <Grid
                container
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                style={{padding: 16}}>
<Grid item xs={12}>
    <PrettoSlider 
        min={0} 
        max={100} 
        value={played * 100}
        ValueLabelComponent={(props) => <ValueLabelComponent {...props} value={elapsedTime}/>}
//        ValueLabelComponent={ValueLabelComponent}
        onChange={onSeek}
        onMouseDown={onSeekMouseDown}
        onChangeCommitted={onSeekMouseUp}
        />
</Grid>
<Grid item> 
    <Grid container alignItems='center' direction='row'>
        <IconButton onClick={onPlayPause} className={classes.bottomIcons}>
        {playing ? 
        (<PauseIcon fontSize='large' />)
        :
        (<PlayArrowIcon fontSize='large'/> )
        }
        </IconButton>

        <IconButton onClick={onMute} className={classes.bottomIcons}>
        {!muted ?    <VolumeUpIcon fontSize='large'/> :
        <VolumeOff fontSize='large' />}
        </IconButton>

        <Slider 
            min={0} 
            max={100} 
            value={volume * 100}
            className={classes.volumeSlider}
            onChange={onVolumeChange}
            onChangeCommitted={onVolumeSeekUp} />
<Button
    onClick={onChangeDisplayFormat}
    variant="text"
    style={{ color: "#fff", marginLleft: 16 }}>
    <Typography>
        {elapsedTime}/{totalDuration}
    </Typography>
</Button>
    </Grid>
</Grid>
<Grid item>
    <Button 
        onClick={handlePopover} 
        aria-describedby={id}
        variant="text" 
        className={classes.bottomIcons}
        disabled
    >
        <Typography>{playbackRate}X</Typography>
    </Button>

    <Popover
        container={ref.current}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
      >
          <Grid container direction='column-reverse'>
              {[0.5,1,1.5,2].map((rate) => (
        <Button
        onClick={()=> onPlaybackRateChange(rate)}
        variant="text"
        >
    <Typography color={rate===playbackRate?'secondary':'default '}>
        {rate}
    </Typography>
</Button>
))}
</Grid>
      </Popover>

    <IconButton onClick={onToggleFullScreen} className={classes.bottomIcons}>
        <FullScreenIcon fontSize='large' />
    </IconButton>
</Grid>
                </Grid>


              </div>

  );
});
