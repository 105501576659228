import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  //...
  apiKey: "AIzaSyA21UBcwc1yTR6oERTGryKQ-ceT_JjIoI4",
  authDomain: "kallong.firebaseapp.com",
  databaseURL: "https://kallong.firebaseio.com",
  projectId: "kallong",
  storageBucket: "kallong.appspot.com",
  messagingSenderId: "403441755996",
  appId: "1:403441755996:web:68ae79155345ece0f20399",
  measurementId: "G-506GSMS2K0"
};

const app = initializeApp(firebaseConfig);
export const authentication= getAuth(app);