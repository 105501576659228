import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { 
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    Dialog,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Popover,
    Typography,
    } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import HdRoundedIcon from '@material-ui/icons/HdRounded';
import HdTwoToneIcon from '@material-ui/icons/HdTwoTone';
import IndeterminateCheckBoxRoundedIcon from '@material-ui/icons/IndeterminateCheckBoxRounded';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import {
    EmailShareButton,
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailIcon,
    FacebookIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
  } from "react-share";
import Subscribe from '../../Sessions/Subscribe';
import axios from '../../axios';
import { 
  exchange,
  decryptWithAES,
  encryptWithAES,
  home
} from '../../requests';
import './Row.css';
import logo from '../Assets/favicon.png';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    title: {
      fontFamily: 'Jost, sans-serif',
      fontSize: '16px',
      lineHeight: '28px',
      fontWeight: 700,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      "&:hover": {
        color: '#8b0000',
      }
    },
    root: {
      position: 'static',
      bottom: theme.spacing(4),
      right: theme.spacing(4),
      zIndex: 2,
      color: '#fff',
      "&:hover": {
        color: '#fff',
        backgroundColor: '#8b0000'
  //      transition: "transform cubic-bezier(0.85,.36,.8,.42) .5s"
      }
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
    typography: {
      padding: theme.spacing(2),
    },
  }));

/* const exchange = 'http://api.kallo.ng:5000';

const home ='http://kallo-ng.herokuapp.com/search'; */

function RowGenre({ title, fetchUrl, isLargeRow, section }) {
    const classes = useStyles();
    const history = useHistory();
    const [genres, setgenres] = useState([]);
    const [genreType, setgenreType] = useState([]);
    const [genreUrl, setgenreUrl] = useState("");
    const [favoriteId, setFavoriteId] = React.useState(null);
    const [genreSlug, setgenreSlug] = useState('');
    const [validSubscription, setValidSubscription] = useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState('');
    const [open, setOpen] = React.useState(false);
    const [verified, setVerified] = React.useState(false);
    const [shareUrl, setShareUrl] = useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    //const categoryBackColorList = ['#2196F3', '#F44336', '#FF9800', '#4CAF50', '#009688', '#2196F3', '#F44336', '#FF9800', '#4CAF50', '#009688', '#2196F3', '#F44336', '#FF9800', '#4CAF50', '#009688', '#F44336'];
    //const categoryBackColorList = [ '#8E1922', '#9C4E5B', '#EB2F3D', '#C27914', '#400D11', '#373435','#8E1922', '#9C4E5B', '#EB2F3D', '#C27914', '#400D11', '#373435', '#8E1922', '#9C4E5B', '#EB2F3D', '#C27914', '#400D11', '#373435' ];
    const categoryBackColorList = [ '#8E1922', '#fff', '#8E1922', '#fff', '#8E1922', '#fff', '#8E1922', '#fff', '#8E1922', '#fff', '#8E1922', '#fff', '#8E1922', '#fff', '#8E1922', '#fff', '#8E1922', '#fff', '#8E1922', '#fff', '#8E1922', '#fff', '#8E1922', '#fff', ];
    const categoryTextColorList = [ '#fff', '#8E1922', '#fff', '#8E1922', '#fff', '#8E1922', '#fff', '#8E1922', '#fff', '#8E1922', '#fff', '#8E1922', '#fff', '#8E1922', '#fff', '#8E1922', '#fff', '#8E1922', '#fff', '#8E1922', '#fff', '#8E1922', '#fff', '#8E1922',]

  const handlePopClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
  };

  const shareTitle = 'Hey, Check this out on Kallo.ng'
  const openPop = Boolean(anchorEl);
  const id = openPop ? 'simple-popover' : undefined;



/*     const CryptoJS = require('crypto-js');

const encryptWithAES = (text) => {
  const passphrase = 'kallo123';
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

const decryptWithAES = (ciphertext) => {
  const passphrase = 'kallo123';
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}; */

    const handleClose = () => {
      setOpen(false);
    };

//DISPLAY genreS IN ROW
    useEffect(() => {
        //[] run once when the row loads and don't run again
        //[variable] run once when the row loads and everytime the variable changes 
        let axiosConfig = {
          headers: {
          'Content-Type': 'application/json',
          //"Authorization": `Bearer ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": 'true',
          'Access-Control-Allow-Methods': '*'
          }
      };
        async function fetchData() {
            const request = await axios.get(fetchUrl, axiosConfig);

            setgenres(request.data[section].reverse())
            console.log('request');
            console.log(request.data[section]);
            
            return request;
        } 
        fetchData();
        
    }, [fetchUrl, section]);

    const handleClick = () => {
      alert(Object.values(genres.find(x => x.genre_id === favoriteId)))
    }
    
        //once genre is SELECTED, go to genre's filmography
/*         useEffect(() => {
          if (genreSlug !== '') {
                history.push(`/star/${genreSlug}`)
              }
        }, [genreSlug]); */
    //console.log('genres');
    //console.log(genres);

    function truncate(str, n) {
      return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  }

  return (
    <div className='row'>
        <h2 style={{fontSize: '35px'}}>{title}</h2>

        <div className='button__posters'>
            {/* several row__posters */}

            {  genres.map((val, index) => (
                <>
                <Button 
                size='large'
                style={{backgroundColor: categoryBackColorList[index],
                    //borderRadius: 10 - 3.0,
                    //paddingVertical: 10 - 1.0,
                    lineWeight: 600,
                    minWidth: '100px',
                    maxWidth: '150px',
                    display: 'inline-block',
                    textTransform: "none",
                    textDecorationThickness: 600,
                    color: categoryTextColorList[index],
                    fontWeight: 'bolder',
                    fontSize: 'relative-size',
                    padding: "20px 0px",
                    alignItems: 'center',
                    justifyContent: 'center',
                    //padding: 10 * 5,
                    marginRight: 10 + 2.0
                }}
                onClick={()=> history.push(`/movies/${val.slug}`)}>
                  {val.name}
                </Button>
                {/* <figure>
                <img
                    onClick={() => {
                      alert(val.slug)  
                      //setgenreSlug(genre.slug)
                    }}
                    className={`actor__poster ${isLargeRow && "actor__posterLarge"}`}
                    src={ isLargeRow ? logo : logo}
                    alt={val?.slug}
                    />
                    <figcaption>
                    <h2 className={classes.title} 
                    onClick={() => {
                    setgenreSlug(val?.slug)
                          }}
                  > {truncate(val.name, 15)} </h2>
                   </figcaption><button>fish</button>
                   </figure> */}
                   </>            
            ))}
        </div>
        <div style={{backgroundColor: '#222'}} >
<Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        style={{borderRadius: "20px"}}
        PaperComponent={Paper}
//        style={{color: '#222'}}
      >

        <Subscribe />
      </Dialog>
      </div>

      
      <Popover
        id={id}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handlePopClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
       <div className="Demo__container">
        <div className="Demo__some-network">
         <FacebookShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            quote={shareTitle}
            className="Demo__some-network__share-button"
            >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          </div>

        <div className="Demo__some-network">
          <TwitterShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            title={shareTitle}
            className="Demo__some-network__share-button"
            >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          </div>

        <div className="Demo__some-network">
          <TelegramShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            title={shareTitle}
            className="Demo__some-network__share-button"
            >
            <TelegramIcon size={32} round />
          </TelegramShareButton>
          </div>

        <div className="Demo__some-network">
          <WhatsappShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            title={shareTitle}
            separator=":: "
            className="Demo__some-network__share-button"
            >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton> 
            </div>

            <div className="Demo__some-network">
          <EmailShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            subject={shareTitle}
            className="Demo__some-network__share-button"
            body={'Check out this genre on Kallo.ng  '}
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
        </div>
            </div>

      </Popover>
      </div>
  )
}

export default RowGenre;