import React from 'react';
import './App.css';
import logo from "./components/Assets/tv_logo_white.png";
import { useHistory } from "react-router";
import BackImage from "./components/Assets/bg.jpg";
import {
  Button,
  Grid,
  Typography
  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withTheme } from './components/Theme/Theme';
import Nav from './Nav';
import Footer from './components/Navigation/Footer';

const orange = "#F2A74B";
const textLight = "#FFF";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 3,
    border: 0,
    color: 'white',
    textColor: 'white',
  },
  label: {
    textTransform: 'capitalize',
  },
  typograph: {
        color: "#fff",
        //height: 30,
    },
    paper: {
      position: "relative",
      marginTop: theme.spacing(2),
      margin: theme.spacing(2),
      padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      zIndex: 1
      //background: backgroundDark,
    },
    main: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(4),
      marginRight: theme.spacing(3),
      marginLeft: theme.spacing(3),
      display: "block",
      width: "auto",
      [theme.breakpoints.up(800 + theme.spacing(2))]: {
        width: 800,
        marginLeft: "auto",
        marginRight: "auto"
      }
    },
    button: {
      color: textLight,
      background: "#8B0000 0",
      position: "relative",
      fontWeight: 400,
      fontFamily: "Raleway, sans-serif",
      overflow: "hidden",
  //    marginTop: theme.spacing(2),
      padding: `${theme.spacing(1.6)}px`,
      border: "none",
      borderRadius: "8px",
      letterSpacing: "3px",
  
      "&::before, &::after": {
        position: "absolute",
        content: '""',
        boxSizing: "border-box",
        borderRadius: "8px",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 1
      },
      "&::before": {
        borderBottom: "2px solid rgba(255,255,255,.58)",
        borderTop: "2px solid rgba(255,255,255,.58)",
        transform: "scale(0,1)"
      },
      "&::after": {
        borderLeft: "3px solid rgba(255,255,255,.58)",
        borderRight: "3px solid rgba(255,255,255,.58)",
        transform: "scale(1,0)"
      },
      "&:hover::before": {
        transform: "scale(1,1)",
        transition: "transform cubic-bezier(0.85,.36,.8,.42) 0.3s"
      },
      "&:hover::after": {
        transform: "scale(1,1)",
        transition: "transform cubic-bezier(0.85,.36,.8,.42) .2s"
      },
      "&::first-letter": {
        color: orange
      },
      "&:hover": {
        background: "rgba(139, 25, 0,0.8)",
        color: textLight
      }
    },
}));

function App() {
  const classes = useStyles();
  const history = useHistory();

    const [openRegister, setOpenRegister] = React.useState(false);
    const [openLogin, setOpenLogin] = React.useState(false);

  
  const getOpenRegister = (registerdata) => {
    setOpenRegister(registerdata);
  }
  
  React.useLayoutEffect(() => {
    window.scrollTo(0, 0)
});

  return (
    <div className="app" style={{
      backgroundImage: `url(${BackImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center center",
      height: '1920px',
      objectFit: "contain"
      }}
      >
                <div className={classes.paper}>
        </div>
      <div className={classes.main}>
        <Nav />
        <div className={classes.paper}>

        </div>
        <div className={classes.paper}>
        </div>
<div className={classes.paper}>

</div>
<div> 
<div className={classes.paper}>
<Grid item xs align='center' > 
        <Typography className={classes.typograph} variant="h4" display="block">
        Kalli kayatattun fina-finai da wakokin Hausa cikin sauki. 
          </Typography>
          <Typography className={classes.typograph} style={{fontFamily: 'Mukta'}} variant="h5" display="block" gutterBottom paragraph>
          Maza danna shiga
          </Typography>
          <Typography className={classes.typograph} style={{color: '#fff'}} variant="h4" display="block">
          Watch variety of Movies, Series and Music in Hausa 
          </Typography>
          <Typography className={classes.typograph} style={{fontFamily: 'Mukta'}} variant="h5" display="block" gutterBottom paragraph>
          Just a click away
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs>
          <Button 
          className={classes.button}
          variant="outlined"
          style={{color: '#fff', backgroundColor: '#8b0000'}}
          onClick={()=> history.push('/register')}
          //href="/register"
          >Register | Rijista
          </Button>
          </Grid>
          <Grid item xs>
          <Button 
          className={classes.button}
          variant="outlined"
          style={{color: '#fff', backgroundColor: '#8b0000'}}
          onClick={()=> history.push('/sign-in')}
          //href="/register"
          >Sign In | Shiga
          </Button>
          </Grid>
          </Grid>
          <div className={classes.paper}>
          <Typography className={classes.typograph} style={{fontFamily: 'Mukta'}} variant="h5" display="block" gutterBottom paragraph>
          A sha kallo lafiya!
          </Typography>
</div>
          </Grid>
          </div>
          </div>
          <div className={classes.paper}>
          <Footer />
          </div>
    </div>
    </div>
  );
}

export default withTheme(App);
