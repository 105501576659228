import React, { Component } from 'react'
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import Mukta from '../fonts/Mukta-Medium.ttf';

const theme = createTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#8b0000'},
        secondary: {
            main: '#fff'},
    },
    typography: {
        fontFamily: 'Raleway, Arial',
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: `
            @font-face {
              font-family: 'Raleway';
              font-style: normal;
              font-display: swap;
              font-weight: 400;
              src: local('Mukta'), local('Mukta-Medium'), url(${Mukta}) format('ttf');
              unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            }
          `,
        },
      },
})


const Theme = (props) => {
    const { children } = props;
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>

}

export const withTheme = (Component) => {
    return (props) => {
        return ( 
            <Theme>
                <Component {...props} />
            </Theme>
        );
    };
};