import React, { useEffect, useState } from "react";
import { alpha, makeStyles } from '@material-ui/core/styles';
import { 
  Dialog,
  Paper
} from '@material-ui/core';
import axios from '../../axios';
import requests from '../../requests';
import ReactCardCarousel from "react-card-carousel";
import './Carousel.css'



const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 2,
  },
  card_style: {
    height: "430px",
    width: "650px",
    minWidth: "auto",
    paddingTop: "80px",
    textAlign: "center",
    background: "#52C0F5",
    color: "#FFF",
    fontFamily: "sans-serif",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "10px",
    boxSizing: "border-box"
  }
  })
);

export default function Carousel() {
    const [movie, setMovie] = useState([]);
    const [open, setOpen] = useState(false);
    const [description, setDescription] = useState('')
    const classes = useStyles();

    const handleClose = () => {
      setOpen(false);
    }

    useEffect(() => {
        async function fetchData() {
            const request = await axios.get(requests.fetchHomePageDisplay)
            setMovie(request.data.slide);
            //setMovie(request.data.slide.sort(() => Math.random() - 0.5));
            //alert(movie.length)
//            setMovie(request.data[Math.floor(Math.random() * request.data.length-1)]);
            //console.log(request.data[
            //    Math.floor(Math.random() * request.data.length-1)
            //]);
            // >> Math.floor(Math.random() * request.data.length-1)
        } 
        fetchData();
    }, []);

    function truncate(str, n) {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    }
  return (
    <div className='carousel'>
{/*               <header 
        className='banner'
        style={{
            backgroundSize: "cover",
            backgroundImage: `url(${movie?.thumbnail_url})`,
            backgroundPosition: "center center",
        }}>
            {// Background Image 
            } 

          <div className='banner__contents'>
              {//Title 
              }
              <div className='banner--fadeBottom'>
              <h1 className='banner__title'>
                  {currentImage?.title || movie?.slug }
              </h1>
               
        <div className="banner__buttons">
          {// div with 2 buttons
          }
            <button className="banner__button">
                Play
            </button>
            <button className="banner__button">
                My List
            </button>
        </div>

        <h1 className='banner__description'>
    {// description 
    }
            {(truncate(movie?.description, 180))}
        </h1>
           </div>
          </div>


      </header> */}
      <ReactCardCarousel initial_index={1} autoplay={movie.length === 1 ? (false) : (true)} autoplay_speed={2500} disable_box_shadow={false}>
          {movie.map(val => (
/*           <div className={classes.card_style}         
          style={{
            backgroundSize: "cover",
            objectFit: 'contain',
            backgroundImage: `url(${val?.thumbnail_url})`,
            backgroundPosition: "center center",
        }}>{val?.title}</div> */
        <div>
        <img 
        className={'carousel__poster'}        
        src={val?.image_link}
        //src={val?.thumbnail_url}
        alt='poster'
        onClick={()=> {
          setOpen(true);
          setDescription(val?.description)}}
      />
               <h2 style={{fontSize: '18px', color: '#fff'}}> {val?.title} </h2>
      </div>
          ))}
        </ReactCardCarousel>

        <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        style={{borderRadius: "20px"}}
        PaperComponent={Paper}
//        style={{color: '#222'}}
      >

<h2 style={{fontSize: '18px', color: '#fff'}}> {description} </h2>
      </Dialog>
    </div>
  )
}
