import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import ReactPlayer from 'react-player';
import movieTrailer from 'movie-trailer';
import Backdrop from '@material-ui/core/Backdrop';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import HdRoundedIcon from '@material-ui/icons/HdRounded';
import HdTwoToneIcon from '@material-ui/icons/HdTwoTone';
import IndeterminateCheckBoxRoundedIcon from '@material-ui/icons/IndeterminateCheckBoxRounded';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import Subscribe from '../../Sessions/Subscribe';
import axios from '../../axios';
import { 
  exchange,
  decryptWithAES,
  encryptWithAES,
  home
} from '../../requests';
import './Row.css';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  title: {
    fontFamily: 'Jost, sans-serif',
    fontSize: '16px',
    lineHeight: '28px',
    fontWeight: 700,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    "&:hover": {
      color: '#8b0000',
    }
  },
  root: {
    position: 'static',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    zIndex: 2,
    color: '#fff',
    "&:hover": {
      color: '#fff',
      backgroundColor: '#8b0000'
//      transition: "transform cubic-bezier(0.85,.36,.8,.42) .5s"
    }
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));


/* const exchange = 'https://corsanywhere.herokuapp.com/http://api.kallo.ng:5000';

const home ='http://kallo-ng.herokuapp.com/search'; */

function RowLatest({ title, fetchUrl, isLargeRow, section}) {
    const classes = useStyles();
    const history = useHistory();
    const [movies, setMovies] = useState([]);
    const [trailerUrl, setTrailerUrl] = useState("");
    const [trailerUrll, setTrailerUrll] = useState("");
    const [shareUrl, setShareUrl] = useState("");
    const [favoriteId, setFavoriteId] = React.useState(null);
    const [trailer, setTrailer] = useState([]);
    const [trailerId, setTrailerId] = useState(null);
    const [validSubscription, setValidSubscription] = useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState('');
    const [open, setOpen] = React.useState(false);
    const [verified, setVerified] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
  };

  const shareTitle = 'Hey, Check this out on Kallo.ng'
  const openPop = Boolean(anchorEl);
  const id = openPop ? 'simple-popover' : undefined;

/*     const CryptoJS = require('crypto-js');

const encryptWithAES = (text) => {
  const passphrase = 'kallo123';
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

const decryptWithAES = (ciphertext) => {
  const passphrase = 'kallo123';
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}; */

    const handleClose = () => {
      setOpen(false);
    };
    const handleToggle = () => {
      setOpen(!open);
    };

    //DISPLAY MOVIES IN ROW
    useEffect(() => {
      localStorage.removeItem("lupe");
      localStorage.removeItem("related_movies");
      localStorage.removeItem("series_details");
      localStorage.removeItem('movie_title')
        //[] run once when the row loads and don't run again
        //[variable] run once when the row loads and everytime the variable changes 
        let axiosConfig = {
          headers: {
          'Content-Type': 'application/json',
          //"Authorization": `Bearer ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": 'true',
          'Access-Control-Allow-Methods': '*'
          }
      };
        async function fetchData() {
            const request = await axios.get(fetchUrl, axiosConfig);
            console.log(request);
            setMovies(request.data[section]);
            return request;
        } 
        fetchData();
        
    }, [fetchUrl, section]);

    //once page loads, check for Active Subscription
    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Access-Control-Allow-Credentials", 'true');
        myHeaders.append("Access-Control-Allow-Methods", "*");


        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        fetch(`${exchange}/api/v1/users/checkUserSubscriptionStatus`, requestOptions)
          .then(response => response.json())
          .then(result => {
              console.log(result);
            if (result.message === 'No Susbcription Found!' || result.message === 'Unauthorized') {
                setSubscriptionStatus(result.message);
                setValidSubscription(false);
            }
            else {
                setValidSubscription(true);}
            })
          .catch(error => {
              console.log('error', error);
              setValidSubscription(false);});
      }, [])

    //once movie is SELECTED, get RELEVANT DATA FOR STREAMING
    useEffect(() => {
      if (subscriptionStatus !== 'Unauthorized') {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Credentials", 'true');
      myHeaders.append("Access-Control-Allow-Methods", "*");

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      if (validSubscription) {
      fetch(`${exchange}/api/v1/users/movieSingleDetails/${trailerId}/tvseries`, requestOptions)
        .then(response => response.json())
        .then(result => {
          //get series details

        //get  as json
          localStorage.setItem("series_details", JSON.stringify(result));
//          console.log(result)
        })
/*         .then(
          alert(`Url: ${trailerUrl}`)
          ) */
        .then(()=>
         { if (trailerId !== '') {
            history.push('/now-playing-series')
          }}
        )
        .catch(error => console.log('error', error));
      }
      else if (subscriptionStatus === 'Unauthorized')
      {
          history.push('/sign-in');
      }
      else if (subscriptionStatus === 'No Susbcription Found!' || subscriptionStatus === 'inactive')
      {
          setOpen(true);
      }
    }
    else {history.push('/sign-in')}
  }, [trailerId, trailerUrl]);


//    console.log(movies);
// Add SELECTED movie/series to favorite
      useEffect(() => {
        
        if (verifyFavs()) {
        
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Access-Control-Allow-Credentials", 'true');
        myHeaders.append("Access-Control-Allow-Methods", "*");
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        fetch(`${exchange}/api/v1/users/add_favorite/${favoriteId}`, requestOptions)
          .then(response => response.json())
          .then(result => 
            {
              alert(result.message)
            })
          .catch(error => console.log('error', error));
      }
    }, [favoriteId, verified])

      function verifyFavs() {
        if (favoriteId !== null) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

fetch(`${exchange}/api/v1/users/verify_favorite_list/${favoriteId}`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if (result.message === 'Already in your wish list') {
      alert(result.message);
      setVerified(false);
    }
    else if (result.message !== 'Already in your wish list') {
//      alert(result.message);
      setVerified(true);
    }
  })
  .catch(error => console.log('error', error));


  return verified;
      }
    }

    const handleClick = (movie) => {
        if (validSubscription) {
        //history.push('/now-playing');
        history.push('/pages/contact-us')
        }
        else if (subscriptionStatus === 'Unauthorized')
        {
            history.push('/sign-in');
        }
        else if (subscriptionStatus === 'No Susbcription Found!')
        {
            setOpen(true);
        }
        /* if (trailerUrl) {
            setTrailerUrl("");
        } else {
                setTrailerUrl("https://kallong.s3.eu-west-2.amazonaws.com/movies/second/GIDAN+GADO+FULL+MASTER.mp4");
        } */
    }
    

    function truncate(str, n) {
      return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  }

  return (
    <div className='row'>
        <h2 style={{fontSize: '35px'}}>{title}</h2>

        <div className='row__posters'>
            {/* several row__posters */}

            {  movies.map((movie) => (
                <>
                <figure>
                <img
                    onClick={() => {
                    //  alert(`The ${movie.videos_id} and title is ${movie.title}`)
//---> to play movie                    setTrailerId(movie.videos_id);
                    setTrailerUrll(movie.trailler_youtube_source);
                    //handleSelect(movie.videos_id);
//                    alert(`${movie.videos_id} and url: ${localStorage.getItem('movie_url')}`);
//                    console.log(trailer['file_url'])
                    }
                  }
                  onDoubleClick={()=> setTrailerUrll('')}
                    className={`row__poster ${isLargeRow && "row__posterLarge"}`}
                    src={ isLargeRow ? movie.poster_url : movie.thumbnail_url}
                    alt={movie.title}
                    />
                   <figcaption>
                   <h2 className={classes.title} 
                    onClick={() => {
                    setTrailerId(movie.videos_id)
                          }}
                  > {truncate(movie.title, 25)} </h2>
                     <Grid container alignItems="center">
                     <Grid item xs={8}>
                     <IconButton className={classes.root}
                     onClick={()=>{
                       //alert(movie.videos_id)
                       //handleFavs(movie.videos_id)
                       setFavoriteId(movie.videos_id)
                       }}>
                      <FavoriteBorderRoundedIcon />
                    </IconButton>
                    <IconButton className={classes.root}
                    onClick={
                      (event) =>  {
                      setShareUrl(movie?.title)
                      setAnchorEl(event.currentTarget);
                      //handlePopClick();
//                      navigator.clipboard.writeText(`${home}/${movie?.title}`);
//                      alert(` ${movie?.title} Link Copied`)
                      }}>
                      <ShareRoundedIcon />
                    </IconButton>
                    <IconButton className={classes.root}
                     onClick={()=>{
                       setTrailerId(movie.videos_id);
                       }}>
                      <PlayArrowIcon />
                    </IconButton>
                    </Grid>
                    <Grid item xs={4}>
                     {(movie?.video_quality === 'HD') ? (
                     <IconButton disabled size='large' style={{backgroundColor: '#fff', borderRadius: 0, 
                     height: '20px', width: '20px'
                     }}>
                      <HdTwoToneIcon fontSize='large' color='primary' />
                    </IconButton>) : (
                      <IconButton className={classes.root}>
                      <IndeterminateCheckBoxRoundedIcon />
                    </IconButton>
                    )}
                    </Grid>
                    </Grid>
                   </figcaption>
                   </figure>
                   </>
            ))}
        </div>

        {// **** the react palyer below should be used to display trailers if available ****
        }
{  trailerUrll && 
<>

<ReactPlayer
  // Disable download button
  config={{ file: { attributes: { controlsList: 'nodownload' } } }}

  // Disable right click
  onContextMenu={e => e.preventDefault()}

  // Your props
  url={trailerUrll}
  className="react-player"
  playing={true}
  controls
  width="50%"
  //height="100%"
/>
</>
}

<div style={{backgroundColor: '#222'}} >
<Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        style={{borderRadius: "20px"}}
        PaperComponent={Paper}
//        style={{color: '#222'}}
      >
<ClickAwayListener onClickAway={handleClose}>
        <Subscribe />
        </ClickAwayListener>
      </Dialog>
      </div>

      <Popover
        id={id}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handlePopClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
       <div className="Demo__container">
        <div className="Demo__some-network">
         <FacebookShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            quote={shareTitle}
            className="Demo__some-network__share-button"
            >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          </div>

        <div className="Demo__some-network">
          <TwitterShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            title={(shareTitle)}
            className="Demo__some-network__share-button"
            >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          </div>

        <div className="Demo__some-network">
          <TelegramShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            title={shareTitle}
            className="Demo__some-network__share-button"
            >
            <TelegramIcon size={32} round />
          </TelegramShareButton>
          </div>

        <div className="Demo__some-network">
          <WhatsappShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            title={shareTitle}
            separator=":: "
            className="Demo__some-network__share-button"
            >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton> 
            </div>

            <div className="Demo__some-network">
          <EmailShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            subject={shareTitle}
            className="Demo__some-network__share-button"
            body={'Check out this Movie on Kallo.ng  '}
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
        </div>

        <div className="Demo__some-network">
        <IconButton
          size='small'
          style={{backgroundColor: 'darkred'}} 
          onClick={()=> {
            navigator.clipboard.writeText(`${home}/${encodeURI(shareUrl)}`);
            alert(` ${shareUrl} Link Copied`)
          }}
        >
          <FileCopyRoundedIcon fontSize='medium' round />
        </IconButton>

        </div>
            </div>

      </Popover>
      </div>
  )
}

export default RowLatest;
