import React from 'react';
import { useHistory } from "react-router";
import Navbar from '../components/Navigation/Navbar';
import Footer from '../components/Navigation/Footer';
import { 
    Avatar,
    Box,
    Button,
    Container,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
    } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ImageIcon from '@material-ui/icons/Image';
import moment from 'moment';
import { withTheme } from "../components/Theme/Theme";
import { 
    exchange,
    decryptWithAES,
    encryptWithAES,
    home
  } from '../requests';

const useStyles = makeStyles((theme) => ({
    rut: {
        backgroundColor: '#111',
        color: '#fff',
    },
    root: {
    display: 'flex',
    '& > *': {
        margin: theme.spacing(1),
    },
    },
    small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    },
    medium: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    },
    large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    },
}));



function MyProfileUpdate() {
    const classes = useStyles();
    const history = useHistory();

    

  return (
    <div className={classes.rut}>
        {("name" in localStorage) ? (
<>        <Navbar/>
        <Container>
        <Box my={2}>
        <Typography variant="h3" gutterBottom>
            Profile Update
        </Typography>
        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" className={classes.large} />
        <List style={{color: '#fff'}}>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <ImageIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    disableTypography 
                    primary="Fullname" 
                    secondary={<Typography variant="h6" gutterBottom>{localStorage.getItem("name")}</Typography>} />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <ImageIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText 
                    primary="Fullname" 
                    secondary={<Typography variant="h6" gutterBottom>{localStorage.getItem("name")}</Typography>} />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <ImageIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText 
                    primary="Email" 
                    secondary={<Typography variant="h6" gutterBottom>{localStorage.getItem("email")}</Typography>} />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <ImageIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText 
                    primary="Join Date" 
                    secondary={<Typography variant="h6" gutterBottom>{moment(localStorage.getItem("join_date")).format('MMM Do YYYY')}</Typography>} />
            </ListItem>
            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        <ImageIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText 
                    primary="Last Login" 
                    secondary={<Typography variant="h6" gutterBottom>{moment(localStorage.getItem("last_login")).format('MMM Do YYYY')}</Typography>} />
            </ListItem>
        </List>
        <Button color='primary'>
            Edit Account Info
        </Button>
        </Box>
        </Container>
        <footer>
        <Footer />
        </footer>
        </>
        ) : (history.push("/"))
}

    </div>
  )
}

export default withTheme(MyProfileUpdate)