import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { register } from "./RegistrationStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { 
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  FormControl, 
  Grid, 
  IconButton,
  Input, 
  InputLabel, 
  MenuItem,
  Popover,
  Select,
  Typography,
} from "@material-ui/core";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import ErrorIcon from "@material-ui/icons/Error";
import VisibilityTwoToneIcon from "@material-ui/icons/VisibilityTwoTone";
import VisibilityOffTwoToneIcon from "@material-ui/icons/VisibilityOffTwoTone";
import CloseIcon from "@material-ui/icons/Close";
import Kalogo from "../components/Assets/tv_logo_white.png";
import MTNMomo from '../components/Assets/momo_agent.png';
import Zenith from '../components/Assets/zenith.png';
import Access from '../components/Assets/accessbank.png';
import axios from '../axios';
import { 
  exchange,
  decryptWithAES,
  encryptWithAES,
  home
} from '../requests';
import { PaystackButton } from 'react-paystack';
import { useFlutterwave, FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';
import { useHistory } from "react-router";
import { withTheme } from "../components/Theme/Theme";

const useStyles = makeStyles((theme) => ({
  rut: {
      backgroundColor: theme.palette.background.paper,
      color: '#fff',
  },
  pay: {
    backgroundColor: '#8b0000',
    color: '#fff',
    height: '2rem',
//    width: 'auto',
},
  root: {
      maxWidth: 250,
      backgroundColor: '#222',
  },
  avatar: {
    marginTop: 20,
    marginBottom: 20,
    position: "static",
//    background: "rgba(255,255,255,0.85)",
    width: "100px",
    height: "50px",
    //boxShadow: "0px 0px 12px rgba(131,153,167,0.99)"
  },
  small: {
  width: theme.spacing(3),
  height: theme.spacing(3),
  },
  medium: {
  width: theme.spacing(7),
  height: theme.spacing(7),
  },
  large: {
  width: theme.spacing(10),
  height: theme.spacing(10),
  },
  typograph: {
      color: "#fff"
  },
  typographee: {
    color: "#8b0000"
},
  typography: {
//    color: "#fff",
    height: 30,
},
  paper: {
    //backgroundColor: '#222',
    backgroundColor: '#8b0000',
  },
  cover: {
      objectFit: 'fill'
  }
}));

const API_KEY = "oczkk67p7pyykt9xlk1eytde";

const fetchUrl = `/home_content_for_android?API-KEY=${API_KEY}`;

/* const exchange = 'http://api.kallo.ng:5000';

const CryptoJS = require('crypto-js');

const encryptWithAES = (text) => {
  const passphrase = 'kallo123';
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

const decryptWithAES = (ciphertext) => {
  const passphrase = 'kallo123';
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}; */

/* const config = {
  reference: (new Date()).getTime().toString(),
  //{this.state.user_data ? this.state.user_data.email : 'default@kallo.ng'}
  //email: "waleyinka55@gmail.com",
  email: (localStorage.getItem("email") ? localStorage.getItem("email") : "default@kallo.ng"),
  publicKey: 'pk_live_2fe53c528c7dc640a7b847ad8ad723280f3faf0c',
  metadata: {{
    custom_fields: [

        {
            userId: "User ID",
            variable_name: "userId",
            value: localStorage.getItem("user_id")
        },
        {
            planId: "Plan Id",
            variable_name: "planId",
            value: plan_id
        }
    ]
}},
  //amount: 20000,
  //publicKey: 'pk_test_3295da775f8bc819ad35293b0b7bacc628e6d2bb',

}; */

//const useStyles = makeStyles(register);

function Subscribe() {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [subPackage, setSubPackage] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [packageId, setPackageId] = useState('');
  const [globalPayLink, setGlobalPayLink] = useState("");
  const [packageAmount, setPackageAmount] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('');
  const [hidePassword, setHidePassword] = useState(true);
  const [error, setError] = useState(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElBank, setAnchorElBank] = React.useState(null);
  const [zenithTransferAccount, setZenithTransferAccount] = useState({});
  const [openZenithTransfer, setOpenZenithTransfer] = useState(false)
  const [accessTransferAccount, setAccessTransferAccount] = useState({});
  const [openAccessTransfer, setOpenAccessTransfer] = useState(false)

//pAYSTACK
  const config = {
    reference: (new Date()).getTime().toString(),
    //{this.state.user_data ? this.state.user_data.email : 'default@kallo.ng'}
    //email: "waleyinka55@gmail.com",
    email: (localStorage.getItem("email") ? localStorage.getItem("email") : "default@kallo.ng"),
    phone: (localStorage.getItem("phone") ? localStorage.getItem("phone") : "08012345678"),
    firstname: (localStorage.getItem("user_id") + "-" + packageId ),
    lastname: "",
    publicKey: 'pk_live_2fe53c528c7dc640a7b847ad8ad723280f3faf0c',
    metadata: {
      custom_fields: [
  
          {
              userId: "User ID",
              variable_name: "userId",
              value: (localStorage.getItem("user_id"))
          },
          {
              planId: "Plan Id",
              variable_name: "planId",
              value: packageId
          }
      ]
  },
    //amount: 20000,
    //publicKey: 'pk_test_3295da775f8bc819ad35293b0b7bacc628e6d2bb',
  
  };

  const configFW = {
    public_key: "FLWPUBK_TEST-b2644d6325a1f52d9bb37a884944c3c3-X",
    tx_ref: Date.now(),
    amount: (packageAmount/100),
    currency: 'NGN',
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: localStorage.getItem("email"),
      phonenumber: '08102909304',
      name: decryptWithAES(localStorage.getItem("name")),
    },

    customizations: {
      title: 'Kallo.ng Subscription',
      description: 'Payment for movie subscription',
      //logo: 'https://assets.piedpiper.com/logo.png',
      logo: Kalogo,
    },
  };
  
  //const handleFlutterPayment = useFlutterwave(configFW);

  const fwConfig = {
    ...configFW,
    text: 'Pay with Flutterwave',
    className: classes.pay,
    callback: (response) => {
      console.log(response);
      alert(response);
      if(response.status === "successful") {
        setPaymentMethod("Flutterwave - CC");
        //then call Kallo API tocredit acount
        handleSubscription();
      }
      //
      closePaymentModal()
    },
    onClose: () => {
      console.log("You close me ooo");
      handlePopClose()
    },
    
  };


  const handlePopClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
    setAnchorElBank(null);
    setOpenZenithTransfer(false);
    setOpenAccessTransfer(false);
  };

  const openPop = Boolean(anchorEl);
  const id = openPop ? 'simple-popover' : undefined;

  const openPopBank = Boolean(anchorElBank);
  const idBank = openPopBank ? 'simple-popover' : undefined;

  const handlePaystackSuccessAction = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    alert(reference);
    const ev = JSON.parse(reference);
    if (ev.event === 'successful') {
      setPaymentMethod("Paystack - CC");
      //then call Kallo API tocredit acount
      handleSubscription();
    }
    console.log(reference);
  };

  // you can call this function anything ==> close
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    alert('Terminating Transaction');
    handlePopClose();
    console.log('closed')
  }

  const componentProps = {
      ...config,
      className: classes.pay,
      amount: packageAmount,
      text: 'Pay with Paystack',
      onSuccess: (reference) => handlePaystackSuccessAction(reference),
      onClose: handlePaystackCloseAction,
  };

  const errorClose = e => {
    setErrorOpen(false);
  };



  const showPassword = () => {
    setHidePassword(!hidePassword)
  };

  const isValid = () => {
    if (email === "") {
      return false;
    }
    return true;
  };

  function handleSubscription () {
    if (packageId !== '') {
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Access-Control-Allow-Credentials", 'true');
    myHeaders.append("Access-Control-Allow-Methods", "*");

var raw = JSON.stringify({
  plan_id: packageId,
  payment_method: paymentMethod
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${exchange}/api/v1/users/create_subscription`, requestOptions)
  .then(response => response.json())
  .then(result => {
    alert(`${result.payment_method} was Successful || cikin nasara an kammala`)
})
  .catch(error => console.log('error', error));

  }
};

  useEffect(() => {
    //[] run once when the row loads and don't run again
    //[variable] run once when the row loads and everytime the variable changes 
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Access-Control-Allow-Credentials", 'true');
    myHeaders.append("Access-Control-Allow-Methods", "*");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    fetch(`${exchange}/api/v1/users/allPackage/`, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.message === 'Unauthorized')
      {
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.clear();
        history.push('/sign-in');
      }
        else {setPackageList(data.reverse())}
    })
      .catch(error => console.log('error', error));
  }, [])

  const GlobalPaymentAction = () => {
    
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
    myHeaders.append("Access-Control-Allow-Origin", "*");
    myHeaders.append("Access-Control-Allow-Credentials", 'true');
    myHeaders.append("Access-Control-Allow-Methods", "*");

var raw = JSON.stringify({
  amount: `${packageAmount/100}`,
  plan_id: packageId,
});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${exchange}/api/v1/users/payment_credit_card`, requestOptions)
  .then(response => response.json())
  .then(result => {
    console.log(result);
    setGlobalPayLink(result.redirectURL)
})
  .catch(error => console.log('error', error));

};

const ZenithBankTransferAction = () => {
    
  setOpenZenithTransfer(true);
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
  myHeaders.append("Access-Control-Allow-Origin", "*");
  myHeaders.append("Access-Control-Allow-Credentials", 'true');
  myHeaders.append("Access-Control-Allow-Methods", "*");

var raw = JSON.stringify({
amount: `${packageAmount/100}`,
plan_id: packageId,
});

var requestOptions = {
method: 'POST',
headers: myHeaders,
body: raw,
redirect: 'follow'
};

fetch(`${exchange}/api/v1/users/generateAccount`, requestOptions)
.then(response => response.json())
.then(result => {
  console.log(result);
  setZenithTransferAccount(result.account)
})
.catch(error => console.log('error', error));

};

const AccessBankTransferAction = () => {
    
  setOpenAccessTransfer(true);
  var myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
  myHeaders.append("Access-Control-Allow-Origin", "*");
  myHeaders.append("Access-Control-Allow-Credentials", 'true');
  myHeaders.append("Access-Control-Allow-Methods", "*");

var raw = JSON.stringify({
  transaction_amount: `${packageAmount/100}`,
plan_id: packageId,
});

var requestOptions = {
method: 'POST',
headers: myHeaders,
body: raw,
redirect: 'follow'
};

fetch(`${exchange}/api/v1/users/GenerateVirtualAccountNumber`, requestOptions)
.then(response => response.json())
.then(result => {
  if (result.response_message.includes("Error")) {
    alert('There was an Error loading this page! Try again later');
    //this.props.navigation.goBack();
    handlePopClose()
}
else 
{  console.log(result.response_data);
  setAccessTransferAccount(result.response_data)
  //setAccessTransferAccount(result.account)
}
})
.catch(error => console.log('error', error));

};

  const submitRegistration = e => {
    e.preventDefault();
    if (password !== '') {
/*       setErrorOpen(true);
      setError("Passwords Entered"); */
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Credentials", 'true');
      myHeaders.append("Access-Control-Allow-Methods", "*");

var raw = JSON.stringify({
  email: email,
  password: password

});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${exchange}/api/v1/users/authenticate`, requestOptions)
  .then(response => response.json())
  .then((data) => {
    localStorage.setItem("token", data.token);
    localStorage.setItem("name", data.user.name);
    localStorage.setItem("email", data.user.email);
    localStorage.setItem("phone", data.user.phone);
    localStorage.setItem("user_id", data.user.user_id);
    localStorage.setItem("join_date", data.user.join_date);
    localStorage.setItem("last_login", data.user.last_login);
//    console.log('name', data.user.name)
  })
  .then(history.push('/home'))
  .catch(error => console.log('error', error));
    }
  };

    return (
      <div className={classes.main}>
        <CssBaseline />

        <Paper className={classes.paper} >
        <Grid item xs align='center' >
{/*           <Link to='/'>
            <img className={classes.avatar}
            src={logo}
            alt="Kallo.ng Logo"
            />
            </Link>
 */}            </Grid>
<Grid item xs align='center' > 
<div className={classes.typography}></div>
        <Typography className={classes.typograph} variant="h4" display="block" gutterBottom paragraph noWrap>
        Select Subscription Package
          </Typography>
          <Typography className={classes.typograph} style={{color: '#fff'}} variant="h4" display="block" gutterBottom paragraph noWrap>
        "Zabi tsarin biyan kudi"
          </Typography>
          </Grid>
            <Grid container align='center' justifyContent='space-evenly'>
            {(packageList).map(val => (
        <Grid item xs={12} sm={4}>
          <Paper className={classes.root} elevation={2} variant='outlined'>
        <Card className={classes.root}>
      <CardActionArea>
{/*         <CardMedia
          className={classes.cover}
          component="img"
//          alt={val?.slug}
          height="5"
//          image={logo}
          title={val?.slug}
        /> */}
        <CardContent style={{backgroundColor: '#111'}}>
          <Typography className={classes.typograph} gutterBottom variant="h2" component="h2">
            {val?.name}
          </Typography>
          <Typography className={classes.typograph} gutterBottom variant="h5" component="p">
          {`₦${val?.price}/${val?.day}days`}
          </Typography>
        </CardContent>
      </CardActionArea >
      <CardActions style={{backgroundColor: '#111'}}>
        <Button size="small" style={{color: "#fff", backgroundColor: "#8b0000"}}
        onClick={(event) => {setAnchorEl(event.currentTarget);
        setPackageAmount(val?.price * 100);
        setPackageId(JSON.stringify(val?.plan_id))}}>
          Credit Card
        </Button>
        <Button size="small" style={{color: "#fff", backgroundColor: "#8b0000"}}
        onClick={(event) => {setAnchorElBank(event.currentTarget);
        setPackageAmount(val?.price * 100);
        setPackageId(JSON.stringify(val?.plan_id))}}>
          Bank Transfer
        </Button>
{/*         <Button size="small" style={{color: "#fff", backgroundColor: "#8b0000"}}
        onClick={() => setPackageId(JSON.stringify(val?.plan_id))}>
          Subscribe
        </Button> */}

      </CardActions>
    </Card>                 
    </Paper>
    <div className={classes.typography}></div>   
            </Grid>
            ))
            }
            <Grid item xs={12} sm={12} > 
{/*             <Typography gutterBottom variant="h5" component="h2" style={{color: '#fff'}}>
            {subPackage.name}
          </Typography>
 */}
            </Grid> 
            </Grid> 

          {error ? (
            <Snackbar
              variant="error"
              key={error}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
              }}
              open={errorOpen}
              onClose={errorClose}
              autoHideDuration={3000}
            >
              <SnackbarContent
                className={classes.error}
                message={
                  <div>
                    <span style={{ marginRight: "8px" }}>
                      <ErrorIcon fontSize="large" color="error" />
                    </span>
                    <span> {error} </span>
                  </div>
                }
                action={[
                  <IconButton
                    key="close"
                    aria-label="close"
                    onClick={errorClose}
                  >
                    <CloseIcon color="error" />
                  </IconButton>
                ]}
              />
            </Snackbar>
          ) : null}

        </Paper>

        <Popover
        id={id}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handlePopClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
       <div className="Demo__container">

       <div className="Demo__some-network">
            <PaystackButton {...componentProps} />
        </div>

       <div className="Demo__some-network">
       <img
              //className={classes.title}
              src={Zenith}
              alt="Pay with Zenith Global Pay"
              style={{maxWidth: 50, height: 48, marginLeft: 5, objectFit: 'contain', backgroundColor: '#fff', borderRadius: '7px'}}
              onClick={GlobalPaymentAction}
               />
</div>

{/* <div className="Demo__some-network">
      <button 
        className={classes.pay} 
        style={{
          backgroundColor: '#fff', 
          color: '#8b0000', 
          textDecorationThickness: '15'}}
          onClick={GlobalPaymentAction}
          //onClick={()=> setGlobalPayLink("https://www.youtube.com/embed/fQfkOnZplxk")}
          > Global Pay </button>
          </div> */}

{/*           <div className="Demo__some-network">
       <img
              //className={classes.title}
              src={MTNMomo}
              alt="Pay with Mtn Momo Agent"
              style={{maxWidth: 50, height: 50, marginLeft: 5, objectFit: 'contain'}}
              //onClick={GlobalPaymentAction}
               />
</div> */}

{/*         <div className="Demo__some-network">
      <button className={classes.pay} style={{backgroundColor: '#ffcc00', color: '#000080', textDecorationThickness: '5', backgroundImage: 'url("../components/Assets/mtnmomo.svg")'}}> MTN MOMO Agent </button>
          </div> */}

{/*         <div className="Demo__some-network">
        <FlutterWaveButton {...fwConfig} />
            </div> */}

            </div>

      </Popover>
{//--> bANK tRANSFER oPTIONS : Zenith, MTN and Access
}
      <Popover
        id={idBank}
        open={openPopBank}
        anchorEl={anchorElBank}
        onClose={handlePopClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
       <div className="Demo__container">

       <div className="Demo__some-network">
       <img
              //className={classes.title}
              src={Zenith}
              alt="Pay with Zenith Global Pay"
              style={{maxWidth: 50, height: 48, marginLeft: 5, objectFit: 'contain', backgroundColor: '#fff', borderRadius: '7px'}}
              onClick={ZenithBankTransferAction}
               />
</div>

          <div className="Demo__some-network">
       <img
              //className={classes.title}
              src={MTNMomo}
              alt="Pay with Mtn Momo Agent"
              style={{maxWidth: 50, height: 50, marginLeft: 5, objectFit: 'contain'}}
              //onClick={GlobalPaymentAction}
               />
</div>

        <div className="Demo__some-network">
              <img
                      //className={classes.title}
                      src={Access}
                      alt="Pay with Access"
                      style={{maxWidth: 50, height: 50, marginLeft: 5, objectFit: 'contain', borderRadius: 10}}
                      onClick={AccessBankTransferAction}
                      />
        </div>


{/*         <div className="Demo__some-network">
        <FlutterWaveButton {...fwConfig} />
            </div>
 */}
{/*             <div className="Demo__some-network">
            <PaystackButton {...componentProps} />
        </div> */}
            </div>

      </Popover>
{
  //Global Pay view
}
      <Dialog
      open={globalPayLink===''? false: true}
      fullWidth
      maxWidth='md'
      onClose={()=> {
        setGlobalPayLink('');
        handlePopClose()}}
      aria-labelledby="max-width-dialog-title"
      style={{borderRadius: "20px"}}
      PaperComponent={Paper}>
      {  globalPayLink && 
        <iframe 
          src={globalPayLink}
          title='Global Pay'
          style={{
            width:'100%',
            height:'700px'
          }}/>
      }
</Dialog>

{
  //Display Zenith's virtual account once available
}
<Dialog
      open={openZenithTransfer}
      fullWidth
      maxWidth='md'
      onClose={()=> {
        setZenithTransferAccount({});
        handlePopClose()}}
      aria-labelledby="max-width-dialog-title"
      style={{borderRadius: "20px"}}
      PaperComponent={Paper}>
      {  zenithTransferAccount && 
<div style={{borderRadius: "20px", backgroundColor: "#8b0000"}}> 
<Grid item xs align='center' > 
<div className={classes.typography}></div>
<Typography className={classes.typograph} variant="h4" display="block" gutterBottom paragraph noWrap>
        Bank Transfer 
          </Typography>
<img
              //className={classes.title}
              src={Zenith}
              alt="Pay with Zenith Global Pay"
              style={{maxWidth: 75, height: 72, marginLeft: 5, objectFit: 'contain', backgroundColor: '#fff', borderRadius: '7px'}}
               />
        <Typography className={classes.typograph} variant="h4" display="block" gutterBottom paragraph noWrap>
        Account Number: {zenithTransferAccount.accountNumber ? zenithTransferAccount.accountNumber : 'Loading...'}
          </Typography>
          <Typography className={classes.typograph} style={{color: '#fff'}} variant="h4" display="block" gutterBottom paragraph noWrap>
        Account Name: {zenithTransferAccount.accountName}
          </Typography>
          <Typography className={classes.typograph} style={{color: '#fff'}} variant="h4" display="block" gutterBottom paragraph noWrap>
        Account Status: {zenithTransferAccount.status}
          </Typography>
          <Grid item xs>
          <Button size="small" style={{color: "#8b0000", backgroundColor: "#fff"}}
        onClick={(event) => {
          setZenithTransferAccount({});
          handlePopClose()
          //check transaction if successful then go to homepage
          history.push("/")}}>
          I've completed the Transaction
        </Button>
        </Grid>
        <Grid item xs align='center' > 
          <Button size="small" style={{color: "#fff", backgroundColor: "#8b0000"}}
        onClick={(event) => {
          setZenithTransferAccount({});
          handlePopClose()}}>
          Cancel
        </Button>
        </Grid>
          </Grid>
</div>
      }
</Dialog>

{
  //Display Access' virtual account once available
}
<Dialog
      open={openAccessTransfer}
      fullWidth
      maxWidth='md'
      onClose={()=> {
        setAccessTransferAccount({});
        handlePopClose()}}
      aria-labelledby="max-width-dialog-title"
      style={{borderRadius: "20px"}}
      PaperComponent={Paper}>
      {  accessTransferAccount && 
<div style={{borderRadius: "20px", backgroundColor: "#8b0000"}}> 
<Grid item xs align='center' > 
<div className={classes.typography}></div>
<Typography className={classes.typograph} variant="h4" display="block" gutterBottom paragraph noWrap>
        Bank Transfer 
          </Typography>
<img
              //className={classes.title}
              src={Access}
              alt="Pay with Zenith Global Pay"
              style={{maxWidth: 75, height: 72, marginLeft: 5, objectFit: 'contain', backgroundColor: '#fff', borderRadius: '7px'}}
               />
        <Typography className={classes.typograph} variant="h4" display="block" gutterBottom paragraph noWrap>
        Account Number: {accessTransferAccount.virtual_acct_no ? accessTransferAccount.virtual_acct_no : 'Loading...'}
          </Typography>
          <Typography className={classes.typograph} style={{color: '#fff'}} variant="h4" display="block" gutterBottom paragraph noWrap>
        Account Name: {accessTransferAccount.virtual_acct_name}
          </Typography>
          <Typography className={classes.typograph} style={{color: '#fff'}} variant="h4" display="block" gutterBottom paragraph noWrap>
        Account Status: {accessTransferAccount.expiry_datetime}
          </Typography>
{/*           <Grid container spacing={2}> */}
          <Grid item xs align='center' >
          <Button size="small" style={{color: "#8b0000", backgroundColor: "#fff"}}
        onClick={(event) => {
          setAccessTransferAccount({});
          handlePopClose()
          //check transaction if successful then go to homepage
          history.push("/")}}>
          I've completed the Transaction
        </Button>
        </Grid>
{/*         <Grid item xs align='center' > 
        </Grid> */}
        <Grid item xs align='center' > 
          <Button size="small" style={{color: "#fff", backgroundColor: "#8b0000"}}
        onClick={(event) => {
          setAccessTransferAccount({});
          handlePopClose()}}>
          Cancel
        </Button>
        {/* </Grid> */}
        </Grid>
          </Grid>
</div>
      }
</Dialog>
      </div>
    );
}

export default withTheme(Subscribe);
