import React, { Component } from "react";
import {
  Widget,
  addResponseMessage,
  addLinkSnippet,
  addUserMessage,
  setQuickButtons,
  renderCustomComponent
} from "react-chat-widget";
import { withTheme } from '../Theme/Theme';

import "react-chat-widget/lib/styles.css";
import "./styles.css";

/* const buttons = [
  { label: "first", value: "1" },
  { label: "second", value: "2" }
]; */

class Image extends Component {
  render() {
    return <img alt="placeholder" src={this.props.src}></img>;
  }
}

export class Chat extends Component {
  componentDidMount() {
    addResponseMessage("How can we help with Kallo Nigeria?");
    //setQuickButtons(buttons);

    //Here I'm adding the component
    // renderCustomComponent(Image, { src: imageSrc });
  }

  handleNewUserMessage = (newMessage) => {
    console.log(`New message incoming! ${newMessage}`);
    // Now send the message throught the backend API

    let imageSrc =
      "https://kinsta.com/es/wp-content/uploads/sites/8/2017/10/wordpress-plugin-de-chat-en-vivo-1024x512.png"; //Place here your image location

//    renderCustomComponent(Image, { src: imageSrc });
    addResponseMessage("hola");
  };

  /*   handleQuickButtonClicked = (data) => {
    console.log(data);
    setQuickButtons(buttons.filter((button) => button.value !== data));
  }; */

  getCustomLauncher = (handleToggle) => {
    <button onClick={handleToggle}>This is my launcher component!</button>;
  };
  render() {
    return (
      <div className="App">
        <Widget
          handleNewUserMessage={this.handleNewUserMessage}
          //handleQuickButtonClicked={this.handleQuickButtonClicked}
          //profileAvatar={'text'}
          title="Kallo Chat"
          subtitle="Questions? Chat With Us!"
          color="primary"
          style={{ color: "#8b0000" }}
          // launcher={(handleToggle) => this.getCustomLauncher(handleToggle)}
        />
      </div>
    );
  }
}

export default withTheme(Chat);