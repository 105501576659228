import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { 
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    Dialog,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Popover,
    Typography,
    } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import HdRoundedIcon from '@material-ui/icons/HdRounded';
import HdTwoToneIcon from '@material-ui/icons/HdTwoTone';
import IndeterminateCheckBoxRoundedIcon from '@material-ui/icons/IndeterminateCheckBoxRounded';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import {
    EmailShareButton,
    FacebookShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    EmailIcon,
    FacebookIcon,
    TelegramIcon,
    TwitterIcon,
    WhatsappIcon,
  } from "react-share";
import Subscribe from '../../Sessions/Subscribe';
import axios from '../../axios';
import { 
  exchange,
  decryptWithAES,
  encryptWithAES,
  home
} from '../../requests';
import './Row.css';

const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    title: {
      fontFamily: 'Jost, sans-serif',
      fontSize: '16px',
      lineHeight: '28px',
      fontWeight: 700,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      "&:hover": {
        color: '#8b0000',
      }
    },
    root: {
      position: 'static',
      bottom: theme.spacing(4),
      right: theme.spacing(4),
      zIndex: 2,
      color: '#fff',
      "&:hover": {
        color: '#fff',
        backgroundColor: '#8b0000'
  //      transition: "transform cubic-bezier(0.85,.36,.8,.42) .5s"
      }
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      margin: 'auto',
      width: 'fit-content',
    },
    formControl: {
      marginTop: theme.spacing(2),
      minWidth: 120,
    },
    formControlLabel: {
      marginTop: theme.spacing(1),
    },
    typography: {
      padding: theme.spacing(2),
    },
  }));

/*   const exchange = 'https://corsanywhere.herokuapp.com/http://api.kallo.ng:5000';

const home ='http://kallo-ng.herokuapp.com/search'; */

function RowPopular({ title, fetchUrl, isLargeRow, section, genre }) {
    const classes = useStyles();
    const history = useHistory();
    const [movies, setMovies] = useState([]);
    const [movieType, setMovieType] = useState([]);
    const [trailerUrl, setTrailerUrl] = useState("");
    const [favoriteId, setFavoriteId] = React.useState(null);
    const [trailerId, setTrailerId] = useState(null);
    const [validSubscription, setValidSubscription] = useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState('');
    const [open, setOpen] = React.useState(false);
    const [verified, setVerified] = React.useState(false);
    const [shareUrl, setShareUrl] = useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
  };

  const shareTitle = 'Hey, Check this out on Kallo.ng'
  const openPop = Boolean(anchorEl);
  const id = openPop ? 'simple-popover' : undefined;



/*     const CryptoJS = require('crypto-js');

const encryptWithAES = (text) => {
  const passphrase = 'kallo123';
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

const decryptWithAES = (ciphertext) => {
  const passphrase = 'kallo123';
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}; */

    const handleClose = () => {
      setOpen(false);
    };

//DISPLAY MOVIES IN ROW
    useEffect(() => {
        //[] run once when the row loads and don't run again
        //[variable] run once when the row loads and everytime the variable changes 
        let axiosConfig = {
          headers: {
          'Content-Type': 'application/json',
          //"Authorization": `Bearer ${localStorage.getItem("token")}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": 'true',
          'Access-Control-Allow-Methods': '*'
          }
      };
        async function fetchData() {
            const request = await axios.get(fetchUrl, axiosConfig);
            //console.log(request);
            setMovies(request.data.features_genre_and_movie);
            setMovieType((request.data[section].find(x => x.name === genre)).videos);
            return request;
        } 
        fetchData();
        
    }, [fetchUrl, section, genre]);

        //once page loads, check for Active Subscription
        useEffect(() => {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
            
            var requestOptions = {
              method: 'GET',
              headers: myHeaders,
              redirect: 'follow'
            };
            
            fetch(`${exchange}/api/v1/users/checkUserSubscriptionStatus`, requestOptions)
              .then(response => response.json())
              .then(result => {
                  console.log(result.message);
                if (result.message === 'No Susbcription Found!' || result.message === 'Unauthorized') {
                    setSubscriptionStatus(result.message);
                    setValidSubscription(false);
                }
                else {
                    setValidSubscription(true);}
                })
              .catch(error => {
                  console.log('error', error);
                  setValidSubscription(false);});
          }, [])
    
        //once movie is SELECTED, get RELEVANT DATA FOR STREAMING
        useEffect(() => {
          var myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
    
          var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
          };
    
          if (validSubscription) {
          fetch(`${exchange}/api/v1/users/movieSingleDetails/${trailerId}/movie`, requestOptions)
            .then(response => response.json())
            .then(result => {
              //get movie url
              {result.videos.map((val) => {
    
                setTrailerUrl(val.file_url);
                localStorage.setItem("lupe", encryptWithAES(val.file_url))
    //            alert(localStorage.getItem('lupe'))
              })}          
    //          setTrailer(result.videos);
            //get related movies as json
              localStorage.setItem("related_movies", JSON.stringify(result.related_movies));
              localStorage.setItem("movie_details", JSON.stringify(result));
              localStorage.setItem("movie_title", (result.movie.title))
              console.log(result)
            })
    /*         .then(
              alert(`Url: ${trailerUrl}`)
              ) */
            .then(()=>
             { if (trailerUrl !== '') {
                //history.push('/now-playing')
                history.push('/pages/contact-us')
              }}
            )
            .catch(error => console.log('error', error));
          }
          else if (subscriptionStatus === 'Unauthorized')
          {
              history.push('/sign-in');
          }
          else if (subscriptionStatus === 'No Susbcription Found!')
          {
              setOpen(true);
          }
        }, [trailerId, trailerUrl]);

//        console.log(movies);
//    console.log(movieType);
    
// Add SELECTED movie/series to favorite
      useEffect(() => {
        
        if (verifyFavs()) {
        
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        fetch(`${exchange}/api/v1/users/add_favorite/${favoriteId}`, requestOptions)
          .then(response => response.json())
          .then(result => 
            {
              alert(result.message)
            })
          .catch(error => console.log('error', error));
      }
    }, [favoriteId, verified])

      function verifyFavs() {
        if (favoriteId !== null) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

fetch(`${exchange}/api/v1/users/verify_favorite_list/${favoriteId}`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if (result.message === 'Already in your wish list') {
      alert(result.message);
      setVerified(false);
    }
    else if (result.message !== 'Already in your wish list') {
//      alert(result.message);
      setVerified(true);
    }
  })
  .catch(error => console.log('error', error));


  return verified;
      }
    }

    function truncate(str, n) {
      return str?.length > n ? str.substr(0, n - 1) + "..." : str;
  }

  return (
    <div className='row'>
        <h2 style={{fontSize: '35px'}}>{title}</h2>

        <div className='row__posters'>
            {/* several row__posters */}

            {  movieType.map((movie) => (
                <>
                <figure>
                <img
                    onClick={() => {
                        setTrailerId(movie.videos_id)
                    }}
                    className={`row__poster ${isLargeRow && "row__posterLarge"}`}
                    src={ isLargeRow ? movie.poster_url : movie.thumbnail_url}
                    alt={movie.title}
                    />
                    <figcaption>
                    <h2 className={classes.title} 
                    onClick={() => {
                    setTrailerId(movie.videos_id)
                          }}
                  > {truncate(movie.title, 22)} </h2>
                    <Grid container alignItems="center">
                     <Grid item xs={8}>
                     <IconButton className={classes.root}
                     onClick={()=>{
                       //alert(movie.videos_id)
                       //handleFavs(movie.videos_id)
                       setFavoriteId(movie.videos_id)
                       }}>
                      <FavoriteBorderRoundedIcon />
                    </IconButton>
                    <IconButton className={classes.root}
                    onClick={
                      (event) =>  {
                        setShareUrl(movie?.title)
                        setAnchorEl(event.currentTarget);
                        //handlePopClick();
                      //navigator.clipboard.writeText(`${home}/${movie?.title}`);
                      //alert(` ${movie?.title} Link Copied`)
                    }}>
                      <ShareRoundedIcon />
                    </IconButton>
                    </Grid>
                    <Grid item xs={4}>
                     {(movie?.video_quality === 'HD') ? (
                     <IconButton disabled style={{backgroundColor: '#fff', borderRadius: 0, height: '20px', width: '20px'}}>
                      <HdTwoToneIcon fontSize='large' color='primary' />
                    </IconButton>) : (
                      <IconButton className={classes.root}>
                      <IndeterminateCheckBoxRoundedIcon />
                    </IconButton>
                    )}
                    </Grid>
                    </Grid>
                   </figcaption>
                   </figure>
                   </>            
            ))}
        </div>
        <div style={{backgroundColor: '#222'}} >
<Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        style={{borderRadius: "20px"}}
        PaperComponent={Paper}
//        style={{color: '#222'}}
      >

        <Subscribe />
      </Dialog>
      </div>

      
      <Popover
        id={id}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handlePopClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
       <div className="Demo__container">
        <div className="Demo__some-network">
         <FacebookShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            quote={shareTitle}
            className="Demo__some-network__share-button"
            >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          </div>

        <div className="Demo__some-network">
          <TwitterShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            title={shareTitle}
            className="Demo__some-network__share-button"
            >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          </div>

        <div className="Demo__some-network">
          <TelegramShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            title={shareTitle}
            className="Demo__some-network__share-button"
            >
            <TelegramIcon size={32} round />
          </TelegramShareButton>
          </div>

        <div className="Demo__some-network">
          <WhatsappShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            title={shareTitle}
            separator=":: "
            className="Demo__some-network__share-button"
            >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton> 
            </div>

            <div className="Demo__some-network">
          <EmailShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            subject={shareTitle}
            className="Demo__some-network__share-button"
            body={'Check out this Movie on Kallo.ng  '}
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
        </div>

        <div className="Demo__some-network">
        <IconButton
          size='small'
          style={{backgroundColor: 'darkred'}} 
          onClick={()=> {
            navigator.clipboard.writeText(`${home}/${encodeURI(shareUrl)}`);
            alert(` ${shareUrl} Link Copied`)
          }}
        >
          <FileCopyRoundedIcon fontSize='medium' round />
        </IconButton>

        </div>
            </div>

      </Popover>
      </div>
  )
}

export default RowPopular;
