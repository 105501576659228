import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import Navbar from '../Navigation/Navbar';
import Footer from '../Navigation/Footer';
import Subscribe from '../../Sessions/Subscribe';
import AccountCircle from '@material-ui/icons/AccountCircle';
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import HdRoundedIcon from '@material-ui/icons/HdRounded';
import HdTwoToneIcon from '@material-ui/icons/HdTwoTone';
import IndeterminateCheckBoxRoundedIcon from '@material-ui/icons/IndeterminateCheckBoxRounded';
import ShareRoundedIcon from '@material-ui/icons/ShareRounded';
import Popover from '@material-ui/core/Popover';
import {
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { 
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    Dialog,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    IconButton,
    Paper,
    Typography,
    } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ImageIcon from '@material-ui/icons/Image';
import moment from 'moment';
import '../../App.css';
import { 
  exchange,
  decryptWithAES,
  encryptWithAES,
  home
} from '../../requests';
import { withTheme } from "../Theme/Theme";

const useStyles = makeStyles((theme) => ({
    rut: {
        backgroundColor: '#111',
        color: '#fff',
    },
    root: {
        maxWidth: 450,
        backgroundColor: '#222',
    },
    small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    },
    medium: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    },
    large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    },
    typograph: {
        color: "#fff"
    },
    cover: {
        objectFit: 'fill'
    },
    heading: {
      fontSize: '36px',
      fontStyle: 'normal',
      lineHeight: '44px',
      fontFamily: 'Jost, sans-serif',
      color: '#ffffff',
    },
}));

/* 
const CryptoJS = require('crypto-js');

const encryptWithAES = (text) => {
  const passphrase = 'kallo123';
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

const decryptWithAES = (ciphertext) => {
  const passphrase = 'kallo123';
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

const exchange = 'http://api.kallo.ng:5000'; */
//const exchange = 'http://localhost:5000';

//const home ='http://kallo-ng.herokuapp.com/search';
const shareTitle = 'Hey, Check this out on Kallo.ng'

function Music() {
    const classes = useStyles();
    const history = useHistory();
    const [musicList, setMusicList]= React.useState([]);
    const [genreId, setGenreId] = React.useState(63);
    const [genreName, setGenreName] = React.useState('Comedy');
    const [trailerUrl, setTrailerUrl] = useState("");
    const [trailerId, setTrailerId] = useState(null);
    const [validSubscription, setValidSubscription] = useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState('');
    const [open, setOpen] = React.useState(false);
    const [openDescription, setOpenDescription] = useState(false);
    const [description, setDescription] = useState('');
    const [shareUrl, setShareUrl] = useState("");
    const [favoriteId, setFavoriteId] = React.useState(null);
    const [verified, setVerified] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handlePopClose = () => {
      setAnchorEl(null);
    };
  
    const openPop = Boolean(anchorEl);
    const id = openPop ? 'simple-popover' : undefined;


    const handleClose = () => {
      setOpen(false);
      setOpenDescription(false);
    };
    const getGenreValue = (genredata) => {
      setGenreId(genredata.genre_id);
      setGenreName(genredata.name);
    }

    // first time page loads
    React.useEffect(()=> {
      localStorage.removeItem("lupe");
      localStorage.removeItem("related_movies");
      localStorage.removeItem("series_details");
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Access-Control-Allow-Credentials", 'true');
        myHeaders.append("Access-Control-Allow-Methods", "*");
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        fetch(`${exchange}/api/v1/users/music/40/latest`, requestOptions)
          .then(response => response.json())
          .then((data) => {
            if (data.message === 'Unauthorized') {
              history.push('/#')
            }
              setMusicList(data);
              console.log(data)
          })
          .catch(error => console.log('error', error));
    }, [genreId])
    
            //once music is SELECTED, get RELEVANT DATA FOR STREAMING
            useEffect(() => {
            if (trailerId !== null) {
              var myHeaders = new Headers();
              myHeaders.append('Content-Type', 'application/json');
              myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
              myHeaders.append("Access-Control-Allow-Origin", "*");
              myHeaders.append("Access-Control-Allow-Credentials", 'true');
              myHeaders.append("Access-Control-Allow-Methods", "*");
        
              var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
              };
        
              if (validSubscription) {
              fetch(`${exchange}/api/v1/users/movieSingleDetails/${trailerId}/tvseries`, requestOptions)
                .then(response => response.json())
                .then(result => {
              if (result.message !== "Cannot read properties of undefined (reading 'star_id')") {
                //get  as json
                localStorage.setItem("series_details", JSON.stringify(result));            
                                  
              //console.log(result.season)
                  }
                  else {
                    alert('Not available');
                    history.push('/music');
                  }
                  })
                  .then(()=>
                  { if (trailerId !== '') {
                      history.push('/now-playing-series')
                    }}
                  )
                .catch(error => console.log('error', error));
              }
              else if (subscriptionStatus === 'Unauthorized')
              {
                  history.push('/sign-in');
              }
              else if (subscriptionStatus === 'No Susbcription Found!')
              {
                  setOpen(true);
              }
            }}, [trailerId, trailerUrl]);
    
            //once page loads, check for Active Subscription
            useEffect(() => {
              var myHeaders = new Headers();
              myHeaders.append('Content-Type', 'application/json');
              myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
              myHeaders.append("Access-Control-Allow-Origin", "*");
              myHeaders.append("Access-Control-Allow-Credentials", 'true');
              myHeaders.append("Access-Control-Allow-Methods", "*");
              
              var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
              };
              
              fetch(`${exchange}/api/v1/users/checkUserSubscriptionStatus`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result.message);
                  if (result.message === 'No Susbcription Found!' || result.message === 'Unauthorized') {
                      setSubscriptionStatus(result.message);
                      setValidSubscription(false);
                  }
                  else {
                      setValidSubscription(true);}
                  })
                .catch(error => {
                    console.log('error', error);
                    setValidSubscription(false);});
            }, [])


            // Add SELECTED movie/series to favorite
      useEffect(() => {
        
        if (verifyFavs()) {
        
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
        myHeaders.append("Access-Control-Allow-Origin", "*");
        myHeaders.append("Access-Control-Allow-Credentials", 'true');
        myHeaders.append("Access-Control-Allow-Methods", "*");
        
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        
        fetch(`${exchange}/api/v1/users/add_favorite/${favoriteId}`, requestOptions)
          .then(response => response.json())
          .then(result => 
            {
              alert(result.message)
            })
          .catch(error => console.log('error', error));
      }
    }, [favoriteId, verified])

      function verifyFavs() {
        if (favoriteId !== null) {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

fetch(`${exchange}/api/v1/users/verify_favorite_list/${favoriteId}`, requestOptions)
  .then(response => response.json())
  .then(result => {
    if (result.message === 'Already in your wish list') {
      alert(result.message);
      setVerified(false);
    }
    else if (result.message !== 'Already in your wish list') {
//      alert(result.message);
      setVerified(true);
    }
  })
  .catch(error => console.log('error', error));


  return verified;
      }
    }

    function truncate(str, n) {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    }

  return (
    <div className='app'>
    <div className={classes.rut}>

<>        <Navbar 
            getGenreValue={getGenreValue}/>
        <Container 
        //maxWidth='md' 
        >
        <Box my={2}>
        <div>
            <h2 className={classes.heading}>
            Music
                </h2>
            </div>
<div>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={6} sm={3}>

        </Grid>
        <Grid item xs={6} sm={3}>

        </Grid>
        </Grid>
        </div>
         <Grid container spacing={2} justifyContent="center"
  alignItems="center">
    { (musicList.length !== 0) ?
            (musicList.map(val => (
        <Grid item xs={12} sm={4}>
        <Card className={classes.root}>
      <CardActionArea
        onClick={()=> {
          setOpenDescription(true);
          setDescription(val?.description)}}
          >
        <CardMedia
          className={classes.cover}
          component="img"
          alt={val?.slug}
          height="300"
          image={val?.thumbnail_url}
          title={val?.slug}
        />
        <CardContent>
          <Typography className={classes.typograph} gutterBottom variant="h5" component="h2">
            {val?.title}
          </Typography>
          <Typography className={classes.typograph} gutterBottom variant="caption" component="p">
            {moment(val?.release).format('MMM Do YYYY')}
          </Typography>
          <Typography className={classes.typograph} variant="body2" color="textSecondary" component="p">
          {truncate(val?.description, 200)}  
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" style={{color: "#fff", backgroundColor: "#8b0000"}}
        onClick={() => {setTrailerId(val.videos_id);}}>
          Play
        </Button>
        <Grid container alignItems="center">
                     <Grid item xs={8}>
                     <IconButton className={classes.root}
                     onClick={()=>{
                       //alert(movie.videos_id)
                       //handleFavs(movie.videos_id)
                       setFavoriteId(val.videos_id)
                       }}>
                      <FavoriteBorderRoundedIcon />
                    </IconButton>
                    <IconButton className={classes.root}
                    onClick={
                      (event) =>  {
                      setShareUrl(val?.title)
                      setAnchorEl(event.currentTarget);
                      //handlePopClick();
//                      navigator.clipboard.writeText(`${home}/${movie?.title}`);
//                      alert(` ${movie?.title} Link Copied`)
                      }}>
                      <ShareRoundedIcon />
                    </IconButton>
                    <IconButton className={classes.root}
                     onClick={()=>{
                       setTrailerId(val.videos_id);
                       }}>
                      <PlayArrowIcon />
                    </IconButton>
                    </Grid>
                    <Grid item xs={4}>
                     {(val?.video_quality === 'HD') ? (
                     <IconButton disabled style={{backgroundColor: '#fff', borderRadius: 0, height: '20px', width: '20px'}}>
                      <HdTwoToneIcon fontSize='large' color='primary' />
                    </IconButton>) : (
                      <IconButton className={classes.root}>
                      <IndeterminateCheckBoxRoundedIcon />
                    </IconButton>
                    )}
                    </Grid>
                    </Grid>
      </CardActions>
    </Card>                    
            </Grid>
            ))) : (
              <Grid item xs={12} sm={4}>
              <div>
            <h2 className={classes.heading}>
            No Music found
                </h2>
            </div>
            </Grid>
            )}
            
        </Grid> 

        <div>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={6} sm={3}>

        </Grid>
        <Grid item xs={6} sm={3}>

        </Grid>
        </Grid>
        </div>

        </Box>
        </Container>
        <footer>
        <Footer />
        </footer>
        </>

    </div>
     
    <div style={{backgroundColor: '#222'}} >
<Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        style={{borderRadius: "20px"}}
        PaperComponent={Paper}
//        style={{color: '#222'}}
      >

        <Subscribe />
      </Dialog>

   {//Music  Description Dialog
      }
      <Dialog
fullWidth
maxWidth='md'
open={openDescription}
onClose={handleClose}
aria-labelledby="max-width-dialog-title"
style={{borderRadius: "20px"}}
PaperComponent={Paper}
//        style={{color: '#222'}}
>

<h2 style={{fontSize: '18px', color: '#fff'}}> {description} </h2>
</Dialog>

      <Popover
        id={id}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handlePopClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
       <div className="Demo__container">
        <div className="Demo__some-network">
         <FacebookShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            quote={shareTitle}
            className="Demo__some-network__share-button"
            >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          </div>

        <div className="Demo__some-network">
          <TwitterShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            title={(shareTitle)}
            className="Demo__some-network__share-button"
            >
            <TwitterIcon size={32} round />
          </TwitterShareButton>
          </div>

        <div className="Demo__some-network">
          <TelegramShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            title={shareTitle}
            className="Demo__some-network__share-button"
            >
            <TelegramIcon size={32} round />
          </TelegramShareButton>
          </div>

        <div className="Demo__some-network">
          <WhatsappShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            title={shareTitle}
            separator=":: "
            className="Demo__some-network__share-button"
            >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton> 
            </div>

            <div className="Demo__some-network">
          <EmailShareButton
            url={`${home}/${encodeURI(shareUrl)}`}
            subject={shareTitle}
            className="Demo__some-network__share-button"
            body={'Check out this Movie on Kallo.ng  '}
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
        </div>
            </div>

      </Popover>
      </div>

    </div>
  )
}

export default withTheme(Music);