import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router";
import Navbar from '../Navigation/Navbar';
import Footer from '../Navigation/Footer';
import Subscribe from '../../Sessions/Subscribe';
import { 
    Avatar,
    Box,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    Dialog,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Typography,
    } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ImageIcon from '@material-ui/icons/Image';
import moment from 'moment';
import '../../App.css';
import { 
  exchange,
  decryptWithAES,
  encryptWithAES,
  home
} from '../../requests';
import PlayerComponent from '../PlayerComponent';
import { withTheme } from "../Theme/Theme";

const useStyles = makeStyles((theme) => ({
    rut: {
        backgroundColor: '#111',
        color: '#fff',
    },
    root: {
        maxWidth: 450,
        backgroundColor: '#222',
    },
    small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    },
    medium: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    },
    large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    },
    typograph: {
        color: "#fff"
    },
    cover: {
        objectFit: 'fill'
    },
    heading: {
      fontSize: '36px',
      fontStyle: 'normal',
      lineHeight: '44px',
      fontFamily: 'Jost, sans-serif',
      color: '#ffffff',
    },
}));

/* const CryptoJS = require('crypto-js');

const encryptWithAES = (text) => {
  const passphrase = 'kallo123';
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

const decryptWithAES = (ciphertext) => {
  const passphrase = 'kallo123';
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};


const exchange = 'http://api.kallo.ng:5000'; */

function Documentary() {
    const classes = useStyles();
    const history = useHistory();
    const [movieList, setMovieList]= React.useState([]);
    const [genreId, setGenreId] = React.useState(65);
    const [genreName, setGenreName] = React.useState('Comedy');
    const [trailerUrl, setTrailerUrl] = useState("");
    const [trailerId, setTrailerId] = useState(null);
    const [validSubscription, setValidSubscription] = useState(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState('');
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
      setOpen(false);
    };


/*     const getGenreValue = (genredata) => {
      setGenreId(genredata.genre_id);
      setGenreName(genredata.name);
    } */

    React.useEffect(()=> {

    }, [])
    
                //once movie is SELECTED, get RELEVANT DATA FOR STREAMING
{/* 
                useEffect(() => {
                  var myHeaders = new Headers();
                  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
            
                  var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                  };
            
                  if (validSubscription) {
                  fetch(`${exchange}/api/v1/users/movieSingleDetails/${trailerId}/movie`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                      //get movie url
                      {result.videos.map((val) => {
            
                        setTrailerUrl(val.file_url);
                        localStorage.setItem("lupe", encryptWithAES(val.file_url))
            //            alert(localStorage.getItem('lupe'))
                      })}          
            //          setTrailer(result.videos);
                    //get related movies as json
                      localStorage.setItem("related_movies", JSON.stringify(result.related_movies));
            
                      console.log(result)
                    })

                    .then(()=>
                     { if (trailerUrl !== '') {
                        history.push('/now-playing')
                      }
                    }
                    )
                    .catch(error => console.log('error', error));
                  }
                  else if (subscriptionStatus === 'Unauthorized')
                  {
                      history.push('/sign-in');
                  }
                  else if (subscriptionStatus === 'No Susbcription Found!')
                  {
                      setOpen(true);
                  }
                }, [trailerId, trailerUrl]);

              */}        
                //once page loads, check for Active Subscription

                {/* 
                useEffect(() => {
                  var myHeaders = new Headers();
                  myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
                  
                  var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                  };
                  
                  fetch(`${exchange}/api/v1/users/checkUserSubscriptionStatus`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        console.log(result.message);
                      if (result.message === 'No Susbcription Found!' || result.message === 'Unauthorized') {
                          setSubscriptionStatus(result.message);
                          setValidSubscription(false);
                      }
                      else {
                          setValidSubscription(true);}
                      })
                    .catch(error => {
                        console.log('error', error);
                        setValidSubscription(false);});
                }, [])
              */ 
             }

    function truncate(str, n) {
        return str?.length > n ? str.substr(0, n - 1) + "..." : str;
    }

  return (
    <div >
    <div className={classes.rut}>
        {("name" in localStorage) ? (
<>        <Navbar 
            //getGenreValue={getGenreValue}
            />
        <Container maxWidth='md' 
        >
        <Box 
        //my={2}
        >
        <div>
            <h2 className={classes.heading}>
            Live TV
                </h2>
            </div>
<div>
  <PlayerComponent />
        </div>

        <div>
        <Grid container spacing={3} alignItems="center">
        <Grid item xs={6} sm={3}>

        </Grid>
        <Grid item xs={6} sm={3}>

        </Grid>
        </Grid>
        </div>

        </Box>
        </Container>
        <footer>
        <Footer />
        </footer>
        </>
        ) : (() =>
            {history.push("/")}
            )
}

    </div>
     
    <div style={{backgroundColor: '#222'}} >
{/* <Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        style={{borderRadius: "20px"}}
        PaperComponent={Paper}
//        style={{color: '#222'}}
      >

        <Subscribe />
      </Dialog> */}
      </div>

    </div>
  )
}

export default withTheme(Documentary);