import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { register } from "./RegistrationStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

import CssBaseline from "@material-ui/core/CssBaseline";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { 
  Button,
  ClickAwayListener,
  Dialog,
  FormControl, 
  Grid, 
  Input, 
  InputLabel, 
  MenuItem,
  Select,
  Typography,
 } from "@material-ui/core";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import IconButton from "@material-ui/core/IconButton";
import ErrorIcon from "@material-ui/icons/Error";
import VisibilityTwoToneIcon from "@material-ui/icons/VisibilityTwoTone";
import VisibilityOffTwoToneIcon from "@material-ui/icons/VisibilityOffTwoTone";
import CloseIcon from "@material-ui/icons/Close";
import logo from "../components/Assets/tv_logo_white.png";
import axios from '../axios';
import { 
  exchange,
  decryptWithAES,
  encryptWithAES,
  home
} from '../requests';
//import GoogleLogin from 'react-google-login';
import { useHistory } from "react-router";
import { withTheme } from "../components/Theme/Theme";
import {
  CircularProgress
} from "@material-ui/core";

const API_KEY = "oczkk67p7pyykt9xlk1eytde";

const fetchUrl = `/home_content_for_android?API-KEY=${API_KEY}`;

//const exchange = 'http://api.kallo.ng:5000';
//const exchange = 'http://localhost:5000';

const useStyles = makeStyles(register);

function SignIn() {
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [hidePassword, setHidePassword] = useState(true);
  const [error, setError] = useState(null);
  const [errorOpen, setErrorOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState(null);
  const [userID, setUserID] = useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  // handle Login failure with Google
  const handleFailure = (result) => {
    console.log(result);
  };

  // handle Login with Google
  const handleLogin = async googleData => {
     const res = await fetch(`${exchange}/api/v1/users/googleAuthenticate`, {
        method: "POST",
        body: JSON.stringify({
        //token: googleData.tokenId,
        email: googleData.user.email,
        name: googleData.user.name,
        id: googleData.user.id
      }),
      headers: {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": 'true',
        'Access-Control-Allow-Methods': '*'
      }
    })
 
  setUser(googleData);
  console.log(googleData);
    const data = await res.json()
    // store returned user in a context?
  }

  function redirect() {
    if ("token" in localStorage) {
    history.push('/home')
    }
  };

/* const CryptoJS = require('crypto-js');

const encryptWithAES = (text) => {
  const passphrase = 'kallo123';
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

const decryptWithAES = (ciphertext) => {
  const passphrase = 'kallo123';
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}; */


  const errorClose = e => {
    setErrorOpen(false);
  };



  const showPassword = () => {
    setHidePassword(!hidePassword)
  };

  const isValid = () => {
    if (email === "") {
      return false;
    }
    return true;
  };

  // sign up with forms handler
  const submitRegistration = e => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
    if (password !== '') {
/*       setErrorOpen(true);
      setError("Passwords Entered"); */
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Credentials", 'true');
      myHeaders.append("Access-Control-Allow-Methods", "*");

var raw = JSON.stringify({
  email: email,
  password: password

});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${exchange}/api/v1/users/authenticate`, requestOptions)
  .then(response => response.json())
  .then((data) => {
    if (data.message !== 'email or password is incorrect') {
    localStorage.setItem("token", data.token);
    localStorage.setItem("name", encryptWithAES(data.user.name));
    localStorage.setItem("email", data.user.email);
    localStorage.setItem("phone", data.user.phone);
    localStorage.setItem("user_id", data.user.user_id);
    localStorage.setItem("join_date", data.user.join_date);
    localStorage.setItem("last_login", data.user.last_login);
           setErrorOpen(true);
      setError("SUCCESSFULL!!!"); 
    history.push('/home');
    //history.goBack();
//    console.log('name', data.user.name)
    }
    else {
      alert(data.message);
      history.push('/sign-in');
    }
  })
  .then(redirect())
  .catch(error => console.log('error', error));
    }
  }
  };


  // forgot password handler
  const ForgotPassword = e => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
    if (password !== '') {
/*       setErrorOpen(true);
      setError("Passwords Entered"); */
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append("Authorization", `Bearer ${localStorage.getItem("token")}`);
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append("Access-Control-Allow-Credentials", 'true');
      myHeaders.append("Access-Control-Allow-Methods", "*");

var raw = JSON.stringify({
  email: emailAddress,

});

var requestOptions = {
  method: 'POST',
  headers: myHeaders,
  body: raw,
  redirect: 'follow'
};

fetch(`${exchange}/api/v1/users/forgetPassword`, requestOptions)
  .then(response => response.json())
  .then((data) => {
    if (data.message === "Password updated Successfully!") {
      handleClose();
      alert(Object.values(data));
      history.push('/sign-in');
//    console.log('name', data.user.name)
    }
    else {
      alert(data.message);
      history.push('/sign-in');
    }
  })
  .then(redirect())
  .catch(error => console.log('error', error));
    }
  }
  };

    return (
      <div className={classes.main}>
        <CssBaseline />

        <Paper className={classes.paper}>
        <Link to='/'>
            <img className={classes.avatar}
            src={logo}
            alt="Kallo.ng Logo"
            />
            </Link>
<Grid container >
<Grid item xs > 
       <Button
              disabled
              disableRipple
              fullWidth
//              variant="contained"
//              className={classes.button}
              type="submit"
              onClick={submitRegistration}
              style={{color: '#fff'}}
            >
        <Typography variant="h6" display="block" gutterBottom noWrap>
            Sign In | Shiga
          </Typography>
              </Button>
          </Grid>
<Grid item xs align='center'> 
          <Button 
          fullWidth
          className={classes.button}
          style={{color: '#fff', backgroundColor: '#8b0000'}}
          href="/register"
          >Register | Rijista</Button>
          </Grid>
</Grid>
<Grid container> 
<Grid item xs>
  {''}
</Grid>
</Grid>
          <form
            className={classes.form}
            onSubmit={() => submitRegistration}
          >
            <Grid container align='center'>
            <Grid item xs={12} sm={12} > 
                        <FormControl required fullWidth margin="normal">
              <InputLabel htmlFor="email" className={classes.labels}>
                Email
              </InputLabel>
              <Input
                name="email"
                type="email"
                autoComplete="email"
                className={classes.inputs}
                disableUnderline={true}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} > 
            <FormControl required fullWidth margin="normal">
              <InputLabel htmlFor="password" className={classes.labels}>
                Password
              </InputLabel>
              <Input
                name="password"
                autoComplete="password"
                className={classes.inputs}
                disableUnderline={true}
                onChange={(e) => setPassword(e.target.value)}
                type={hidePassword ? "password" : "input"}
                endAdornment={
                  hidePassword ? (
                    <InputAdornment position="end">
                      <VisibilityOffTwoToneIcon
                        fontSize="default"
                        className={classes.passwordEye}
                        onClick={showPassword}
                      />
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <VisibilityTwoToneIcon
                        fontSize="default"
                        className={classes.passwordEye}
                        onClick={showPassword}
                      />
                    </InputAdornment>
                  )
                }
              />
            </FormControl>
            </Grid> 
            <Grid item xs={12} sm={6}>
            <Button
              //disabled={!isValid()}
              disabled={loading}
              disableRipple
//              fullWidth
              variant="outlined"
              className={classes.button}
              type="submit"
              onClick={submitRegistration}
              style={{color: '#fff'}}
            >
              Sign In | Shiga
            </Button>
            {loading && 
     <CircularProgress
      size={24}
      className={classes.buttonProgress}
    />}
            
            </Grid> 
            <Grid item xs={12} sm={6}> 
            <Button
//              fullWidth
              variant="outlined"
              className={classes.button_two}
              onClick={()=> history.push('/sign-in-with-phone')}
//              style={{color: '#222'}}
            >
              Sign In with Phone
            </Button>
{/*             <GoogleLogin
    //clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
    clientId="403441755996-dkdsp0u5k6k6cr8m7hip4o36jppij2fu.apps.googleusercontent.com"
    buttonText="Sign in with Google || Shiga da Google"
    onSuccess={handleLogin}
    onFailure={handleFailure}
    cookiePolicy={'single_host_origin'}
/> */}
<Button
  style={{textDecoration: 'underline', textDecorationColor: '#8b0000', marginTop: '2px',}}
  onClick={() => setOpen(true)}>
  Forgot Password?
</Button>
            </Grid>
            </Grid> 
          </form>

          {error ? (
            <Snackbar
              variant="success"
              key={error}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center"
              }}
              open={errorOpen}
              onClose={errorClose}
              autoHideDuration={3000}
            >
              <SnackbarContent
                className={classes.success}
                message={
                  <div>
                    <span style={{ marginRight: "8px" }}>
                      <ErrorIcon fontSize="large" color="success" />
                    </span>
                    <span> {error} </span>
                  </div>
                }
                action={[
                  <IconButton
                    key="close"
                    aria-label="close"
                    onClick={errorClose}
                  >
                    <CloseIcon color="error" />
                  </IconButton>
                ]}
              />
            </Snackbar>
          ) : null}

        </Paper>

        <div style={{backgroundColor: '#222'}} >
<Dialog
        fullWidth
        maxWidth='md'
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        style={{borderRadius: "20px"}}
        PaperComponent={Paper}
//        style={{color: '#222'}}
      >
<ClickAwayListener onClickAway={handleClose}>
          <Grid container align='center'>
           <Grid item xs={12} sm={12} > 
            <FormControl required fullWidth margin="normal">
              <InputLabel htmlFor="email" className={classes.labels}>
                Email
              </InputLabel>
              <Input
                name="email"
                type="email"
                autoComplete="email"
                className={classes.inputs}
                disableUnderline={true}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </FormControl>
           </Grid>
           <Grid item xs={12} sm={6}>
            <Button
//              fullWidth
              variant="outlined"
              className={classes.button}
              onClick={ForgotPassword}
              style={{color: '#fff'}}
            >
              Forgot Password | Rijista Password
            </Button>
            </Grid> 
          </Grid>
        </ClickAwayListener>
      </Dialog>
      </div>
      </div>
    );
}

export default withTheme(SignIn);